import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { blankCarImg } from 'oat-common-ui';
import { FC, useMemo } from 'react';
import ToggleButton from '../../../../../../components/ToggleButton';
import { FEATURE_OR_4154, Status } from '../../../../../../constants/global';
import { EnhTfsCostShare, VehicleInput } from '../../../../../../gql/generated';
import useUrlParams from '../../../../../../hooks/useUrlParams';
import useStores from '../../../../../../stores/useStores';
import { getEfcCarJelly, getEfcImageFullPath } from '../../../../../../utils/efc3dImageUtils';
import getDisabledVehicles from '../../../../../../utils/getDisabledVehicles';
import { getIncludedExcludedVehicles } from '../../../../../../utils/getIncludedExcludedVehicles';
import useUserInfo from '../../../../../../utils/useUserInfo';
import AprCardModel from '../../../../models/AprCardModel';
import BlockText from '../../../BlockText';
import ContestNumber from '../../../ContestNumber';
import EnhCostShareAvailabilities from '../../../EnhCostShareAvailabilities';
import { returnStatesList } from '../../../LeaseCard/components/VehicleInfo/utils';
import OfferCompatibleOffers from '../../../OfferCompatibleOffers';
import OfferExclusions from '../../../OfferExclusions';
import SpecialEdition from '../../../SpecialEdition';
import OfferDates from './components/OfferDates';
import PaymentDetails from './components/PaymentDetails';
import TFSSubvention from './components/TFSSubvention';
import styles from './styles.module.scss';

interface Props {
  card: AprCardModel;
  compatibileAccessories?: string[];
  onChange: (skipDelay?: boolean) => void;
  onSaveCompatibility: () => void;
}

const OfferDetails: FC<Props> = ({ card, compatibileAccessories, onChange, onSaveCompatibility }) => {
  const { region, profile } = useUrlParams();

  const {
    fields: { accessoryCodes, compatibileOffers, compatibility, specialEditionPackage, vehicles, contestNumber },
    hasError,
    includedStates,
    invalidTiers,
    isDuplicate,
    isSpecialEdition,
    isStandalone,
    selectedTier,
    selectedTierModel,
    switchTier,
    tiersList,
    toggleCompatibileOffer,
    toggleIsSpecialEdition,
    uid,
    updateField,
    nationalOffer,
  } = card;

  const {
    programDetailsStore: {
      seriesProfile,
      offering: { status },
    },
    userInfoStore: { isLexus, isGst },
    efcStore: { seriesImages },
    regionalCostShareStore: { costShareDatesMap },
  } = useStores();
  const { isSETUser } = useUserInfo();

  const disabledVehicles = getDisabledVehicles(nationalOffer?.vehicles || seriesProfile.vehicles);
  const { includedVehicles, excludedVehicles } = useMemo(() => getIncludedExcludedVehicles(vehicles), [vehicles]);

  const handleExclusions = (excludedList: VehicleInput[], includedList: VehicleInput[]) => {
    updateField('vehicles', [...excludedList, ...includedList]);
    onChange(true);
  };

  const handleOnUpdate = (contestNumberId: string, contestNumber: string, isUpdate = false) => {
    updateField('contestNumber', contestNumber);
    updateField('contestNumberOfferId', contestNumberId);
    if (isUpdate && !card.isForCreate) {
      onChange();
    }
  };

  const handleSaveCompatibility = (value: string[]) => {
    updateField('compatibileOffers', value);
    onSaveCompatibility();
  };

  const carJelly = useMemo(() => {
    let carJelly = '';
    if (seriesProfile.series.length) {
      carJelly = getEfcImageFullPath(getEfcCarJelly(seriesImages, seriesProfile.series[0].seriesYear, seriesProfile.series[0].series) || '');
    }
    return carJelly || blankCarImg;
  }, [seriesProfile, seriesImages]);

  const isDisabled = status === Status.MEETS_GUIDELINES || card.isDisabled;
  const aprCostShareDates = costShareDatesMap.get(profile)?.aprCostShareDates;
  const hasAprCostShareDates = FEATURE_OR_4154 && isStandalone && aprCostShareDates && aprCostShareDates.length > 0;

  return (
    <>
      <div className={clsx(FEATURE_OR_4154 && styles.topDetails)}>
        <div className={clsx(FEATURE_OR_4154 && styles.vehicleInfoLeft)}>
          <img src={carJelly} alt={`${seriesProfile.series[0].series}-${seriesProfile.series[0].seriesYear}-img`} />
        </div>
        <div className={clsx(FEATURE_OR_4154 && styles.vehicleInfoRight, hasAprCostShareDates ? styles.enhancedWidth : styles.defaultWidth)}>
          {isSETUser() && (
            <ContestNumber optionTypeName={AdminConstants.OfferTypes.APR} contestNumber={contestNumber} id={card.uid} handleUpdate={handleOnUpdate} forCreate={card.isForCreate} />
          )}
          <OfferExclusions
            className={styles.exclusions}
            disabledVehicles={disabledVehicles}
            excludedModels={excludedVehicles}
            id={card.uid}
            includedModels={includedVehicles}
            isEditEnabled={isStandalone || isDuplicate}
            disabled={isDisabled}
            onSubmit={handleExclusions}
          />
          <BlockText topText="Compatible States" bottomText={returnStatesList(region, includedStates)} />
          <OfferCompatibleOffers
            compatibileOffers={compatibileOffers}
            compatibility={compatibility}
            isEditable={isStandalone || isDuplicate}
            disabled={isDisabled}
            onSubmit={value => handleSaveCompatibility(value)}
            optionType={AdminConstants.OPTION_TYPE_NAMES.APR}
            toggleCompatibileOffer={toggleCompatibileOffer}
            id={uid}
          />
          {hasAprCostShareDates && <EnhCostShareAvailabilities id={card.id} costShareDates={costShareDatesMap.get(profile)?.aprCostShareDates as EnhTfsCostShare[]} />}
          {(isLexus() || isGst()) && (
            <SpecialEdition
              id={uid}
              accessoryCodes={accessoryCodes}
              className={styles.specialEditionWrapper}
              compatibileAccessories={compatibileAccessories}
              inputLabel="Package"
              isChecked={isSpecialEdition}
              isLexus={isLexus()}
              label="Special edition"
              onChange={value => {
                updateField('specialEditionPackage', value);
                onChange();
              }}
              onSubmit={value => {
                updateField('accessoryCodes', value.join());
                onChange(true);
              }}
              onSelect={toggleIsSpecialEdition}
              value={specialEditionPackage}
              vehicles={vehicles}
              forCreate={card.isForCreate || card.isMultiSeries}
              disabled={isDisabled}
            />
          )}
        </div>
      </div>

      {FEATURE_OR_4154 && <OfferDates aprCard={card} onChange={onChange} isDisabled={isDisabled} />}

      <div className={styles.bottomDetails}>
        <PaymentDetails rate={selectedTierModel?.highestAdvertisedTerm?.fields.rate} term={selectedTierModel?.highestAdvertisedTerm.fields.term} />
        <TFSSubvention tfsCashValue={Number(selectedTierModel?.highestAdvertisedTerm.fields.subventionCash) || 0} />
        {tiersList.map((tier, i) => (
          <ToggleButton
            key={`${tier}-${i}`}
            className={styles.tierButton}
            toggled={selectedTier === tier}
            onClick={() => switchTier(tier)}
            error={hasError && invalidTiers.get(tier)?.hasError}
          >
            Tier {tier}
          </ToggleButton>
        ))}
      </div>
    </>
  );
};

export default observer(OfferDetails);
