import { AdminConstants } from 'oat-admin-common';
import { TfsContract } from '../gql/generated';

const { CASH_TYPES } = AdminConstants;

export const HELP_URL = 'https://buyatoyota.atlassian.net/wiki/spaces/OATT';

export enum Brand {
  TOYOTA = 'Toyota',
  LEXUS = 'Lexus',
}

export enum Language {
  ENGLISH = 'english',
  SPANISH = 'spanish',
}

export enum Port {
  PT = 'PT',
  SJ = 'SJ',
  NY = 'NY',
  LA = 'LA',
  JX = 'JX',
  GT = 'GT',
  CM = 'CM',
}

export enum Labels {
  ESTIMATED = 'Est. Balance',
  ACTUAL = 'Actual Balance',
}

export enum Status {
  ATC_DRAFT = 'ATC - Draft',
  ATC_NEEDS_REVISIONS = 'ATC - Needs Revisions',
  ATC_REVISED = 'ATC - Revised',
  ATC_SUBMITTED = 'ATC - Submitted',
  ALL_GUIDELINES_MET = 'All Guidelines Met',
  DRAFT = 'Draft',
  MEETS_GUIDELINES = 'Meets Guidelines',
  NEEDS_REVISIONS = 'Needs Revisions',
  NEW = 'New',
  NO_RESPONSE = 'No Response',
  PUBLISH_IN_PROGRESS = 'Publish in Progress',
  REVISED = 'Revised',
  SUBMITTED = 'Submitted',
}

export const AprCostShare: Record<string, number> = {
  24: 15,
  36: 20,
  48: 25,
  60: 30,
  72: 35,
};

export const AprTerms: number[] = [24, 36, 48, 60, 72];

export const DEFAULT_TIERS = ['1+', '1', '2', '3', '4', '5', '6', '7'];

export const NATIONAL_RYO_TYPE = 'National RYO';
export const ADDITIONAL_RYO_TYPE = 'Additional RYO';
export const LUMPSUM_RYO_TYPE = 'Lumpsum RYO';
export const MILEAGES = [
  { value: '5000', label: '5,000' },
  { value: '7500', label: '7,500' },
  { value: '10000', label: '10,000' },
  { value: '12000', label: '12,000' },
  { value: '15000', label: '15,000' },
];

export const ADDITIONAL_CASH = [{ value: 'None', label: 'None' }];
export const LEASE_TERMS = ['24', '27', '30', '33', '36', '39', '42', '45', '48', '51', '54', '57', '60'];
export const DEFAULT_MILEAGE = '12000';
export const SCROLL_OFFSET_SUMMARY = 190;
export const SCROLL_OFFSET_PROGRAM_DETAILS = 178;
export const SCROLL_OFFSET_RECONCILE = 260;

export enum ReportTypes {
  // National Planning
  EXECTUIVE_OFFER_SUMMARY_REPORT = 'Executive Offer Summary Report',
  OFFER_SETUP_REPORT = 'Offer Setup Report',
  NATIONAL_GUIDANCE_REPORT = 'National Guidance Report',
  NATIONAL_OFFER_DETAILS_REPORT = 'National Offer Details Report',
  HISTORICAL_PROGRAM_TRACKER_REPORT = 'Historical Program Tracker Report',
  OFFER_SUMMARY = 'Offer Summary',

  // National
  PROGRAM_MATRIX = 'Program Matrix',
  ALL_IN_PNVS_VS_REGIONAL = 'All-In PNVS vs Regional',
  IAG_EXTRACT = 'IAG Extract',
  REGIONAL_ENHANCEMENTS = 'Regional Enhancements',
  RA_SELECTION = 'RA Selection',

  // Incentive Periods
  DEALER_HOTSHEET = 'Dealer Hotsheet',
  RECONCILE_SUMMARY = 'Reconcile Summary',
  TFS_COST_SHARE = 'TFS Cost Share',
  LFS_COST_SHARE = 'LFS Cost Share',
  REGIONAL_PROPOSED_INCENTIVES = 'Regional Proposed Incentives',
  LEASE_SUMMARY = 'Lease Summary',
  GM_APPROVAL_REPORT = 'GM Approval Report',
  FY_RECONCILE = 'FY Reconcile',
}

// These enums is for UI to display
export const NationalPlanningReports = [
  ReportTypes.EXECTUIVE_OFFER_SUMMARY_REPORT,
  ReportTypes.OFFER_SETUP_REPORT,
  ReportTypes.NATIONAL_GUIDANCE_REPORT,
  ReportTypes.NATIONAL_OFFER_DETAILS_REPORT,
  ReportTypes.HISTORICAL_PROGRAM_TRACKER_REPORT,
  ReportTypes.OFFER_SUMMARY,
];

export const IpNationalReports = [ReportTypes.PROGRAM_MATRIX, ReportTypes.IAG_EXTRACT, ReportTypes.REGIONAL_ENHANCEMENTS, ReportTypes.RA_SELECTION];

export const IpRegionalReports = [
  ReportTypes.DEALER_HOTSHEET,
  ReportTypes.RECONCILE_SUMMARY,
  ReportTypes.TFS_COST_SHARE,
  ReportTypes.REGIONAL_PROPOSED_INCENTIVES,
  ReportTypes.LEASE_SUMMARY,
  ReportTypes.GM_APPROVAL_REPORT,
];

// These are unique file names generated by the server. Most ReportTypes will share the filenames.
export const REPORT_NAME_BY_TYPE: { [k: string]: string } = {
  [ReportTypes.IAG_EXTRACT]: 'IAG Offers Extract',
  [ReportTypes.DEALER_HOTSHEET]: 'Dealer Hot Sheet',
};

export const ENR_ERROR_MESSAGE = 'Please enhance the offer, along with refining date range and/or model codes';
export const LEASE_VIN_AUTOSELECTED_ERROR = 'A new VIN has been automatically selected because the previous VIN is unavailable.';
export const LEASE_BAD_VIN_ERROR = 'The VIN previously used in your lease offer(s) is unavailable. Please review your lease(s) before confirming this series.';
export const LEASE_VIN_STOP_SALE_ERROR = 'The selected VIN has a stop sale order. Select another VIN or make non-advertised before confirming series.';

export enum PaymentToOptions {
  CUSTOMER_OR_DEALER = 'Customer or Dealer',
  CUSTOMER_ONLY = 'Customer Only',
  DEALER_ONLY = 'Dealer Only',
  TFS_ONLY = 'TFS Only',
}

export enum PaymentMethod {
  ACH_CHECK = 'ACH/Check',
  BILLING = 'Billing',
}

export enum SystemToPay {
  TFS = 'TFS',
  IPLUS = 'iPlus',
  ICAT = 'ICAT',
  NISS = 'NISS',
  FISS = 'FISS',
  MANUAL = 'Manual',
  NA = 'NA',
}

export enum ReturningBrands {
  TMS = 'TMS',
  LEXUS = 'Lexus',
  SCION = 'Scion',
}

export enum SalesCodes {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  ALL = 'All',
}

export enum GiftDelivery {
  DELIVERED = 'Delivered to dealership at time of purchase/lease',
  PURCHASER = 'Purchaser/Lessee has to submit form for redemption',
}

export const defaultTfsContracts: TfsContract[] = [
  {
    contractType: 'Lease',
    isSubvented: true,
    tierTerms: '',
  },
  {
    contractType: 'Lease',
    isSubvented: false,
    tierTerms: '',
  },
  {
    contractType: 'APR',
    isSubvented: true,
    tierTerms: '',
  },
  {
    contractType: 'APR',
    isSubvented: false,
    tierTerms: '',
  },
];

export const miscRequiredFields = [
  { type: [CASH_TYPES.DOWN_PAYMENT_ASSISTANCE], fields: ['maxDownPayment'] },
  {
    type: [
      CASH_TYPES.SALES_PERSON_FLAT_CASH,
      CASH_TYPES.SALES_MANAGER_FLAT_CASH,
      CASH_TYPES.DEALER_POINTS,
      CASH_TYPES.DEALER_PERCENT_STAIR_STEP,
      CASH_TYPES.DEALER_HIT_AND_WIN,
      CASH_TYPES.DEALER_POT_OF_GOLD,
      CASH_TYPES.DEALER_TRIP_INCENTIVE,
      CASH_TYPES.DEALERSHIP_PERSONNEL_VARIABLE_PAYMENT,
      CASH_TYPES.DEALERSHIP_PERSONNEL_POINTS,
      CASH_TYPES.DEALERSHIP_PERSONNEL_NON_VIN,
      CASH_TYPES.DEALERSHIP_PERSONNEL_CASH_BASH,
      CASH_TYPES.DEALERSHIP_PERSONNEL_HIT_AND_WIN,
      CASH_TYPES.DEALERSHIP_PERSONNEL_POT_OF_GOLD,
      CASH_TYPES.DEALERSHIP_PERSONNEL_TRIP,
      CASH_TYPES.DEALER_WHOLESALE_PROGRAM,
      CASH_TYPES.DEALER_UNIT_STAIR_STEP,
      CASH_TYPES.DEALER_SALES_TRACKING,
      CASH_TYPES.OTHER_DEALERSHIP_PERSONNEL_FLAT_CASH,
      CASH_TYPES.DEALERSHIP_PERSONNEL_STAIR_STEP,
      CASH_TYPES.DEALERSHIP_PRESONNEL_SALES_TRACKING,
      CASH_TYPES.DEALERSHIP_PERSONNEL_OVERRIDE,
      CASH_TYPES.DEALERSHIP_PERSONNEL_TFS_CONTRACT_REWARD,
      CASH_TYPES.DEALERSHIP_PERSONNEL_PERCENT_STAIR_STEP_PERSONNEL_SALES,
      CASH_TYPES.DEALERSHIP_PERSONNEL_PERCENT_STAIR_STEP_SALES,
      CASH_TYPES.RETENTION_PROGRAM,
      CASH_TYPES.OTHER,
    ],
    fields: ['vendorDescription'],
  },
  {
    type: [
      CASH_TYPES.BONUS,
      CASH_TYPES.EVENT,
      CASH_TYPES.VSPP,
      CASH_TYPES.DEALER_TFS_SUBVENTION_CASH,
      CASH_TYPES.DEALER_TFS_CASH,
      CASH_TYPES.DEALER_TFS_APR_SUBVENTION_CASH,
      CASH_TYPES.DEALER_TFS_LEASE_SUBVENTION_CASH,
      CASH_TYPES.TFS_APR_CASH,
      CASH_TYPES.TFS_LEASE_CASH,
      CASH_TYPES.DEALER_CASH,
      CASH_TYPES.LOYALTY,
      CASH_TYPES.LEASE_LOYALTY,
      CASH_TYPES.TARGETED_CERTIFICATE,
    ],
    fields: ['tfsShare'],
  },
  {
    type: [process.env.REACT_APP_FEATURE_OR_5454_PHASE_2 === 'true' ? CASH_TYPES.COLLEGE : CASH_TYPES.COLLEGE_GRADUATE, CASH_TYPES.MILITARY],
    fields: ['targetedAudience'],
  },
  { type: [CASH_TYPES.CLOSING_CASH], fields: ['maxCertificates'] },
  { type: [CASH_TYPES.TRADE_IN_ASSISTANCE, CASH_TYPES.CONQUEST], fields: ['competitorDetails'] },
  {
    type: [CASH_TYPES.PAYMENT_WAIVER],
    fields: ['noOfMonths', 'maxAmountPerMonth', 'returningSeries', ...(process.env.REACT_APP_FEATURE_OR_2477 === 'true' ? ['tfsCostShareCap'] : [])],
  },
  { type: [CASH_TYPES.COMPLIMENTARY_FIRST_PAYMENT], fields: ['noOfMonths', 'maxAmountPerMonth'] },
  { type: [CASH_TYPES.CAMPAIGN], fields: ['impactedBrands', 'impactedSeries'] },
  { type: [CASH_TYPES.NON_CASH_CERTIFICATE], fields: ['vendorDescription', 'giftDeliveryOption'] },
  { type: [CASH_TYPES.VENDOR_PURCHASE_PROGRAM], fields: ['vendorDescription', 'vendorCodes'] },
  { type: [CASH_TYPES.DEFERRED_PAYMENT], fields: ['combinedPerUnitCost', 'maxDaysNo'] },
];

export const tierOrder: Record<string, number> = {
  '1+': 1,
  '1': 2,
  '2': 3,
  '3': 4,
};

export const ranges = ['0-110.99', '111-120.99', '121-130.99', '131-999'];

// FEATURE FLAGS
export const FEATURE_OR_2477 = process.env.REACT_APP_FEATURE_OR_2477 === 'true';
export const FEATURE_OR_3695 = process.env.REACT_APP_FEATURE_OR_3695 === 'true';
export const FEATURE_OR_4619 = process.env.REACT_APP_FEATURE_OR_4619 === 'true';
export const FEATURE_OR_5454 = process.env.REACT_APP_FEATURE_OR_5454 === 'true';
export const FEATURE_OR_5646 = process.env.REACT_APP_FEATURE_OR_5646 === 'true';
export const FEATURE_OR_5454_PHASE_2 = process.env.REACT_APP_FEATURE_OR_5454_PHASE_2 === 'true';
export const FEATURE_OR_4154 = process.env.REACT_APP_FEATURE_OR_4154 === 'true';
export const FEATURE_OR_4756 = process.env.REACT_APP_FEATURE_OR_4756 === 'true';
export const FEATURE_OR_4641 = process.env.REACT_APP_FEATURE_OR_4641 === 'true';

export const INVALID_DATES = 'Dates selected are outside of National Program date range';
export const INVALID_MODELCODES = 'Models selected are not included in National Program';
