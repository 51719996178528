import clsx from 'clsx';
import { formatDollars, formatDollarsCents, OATIcon } from 'oat-common-ui';
import { ReactNode, useEffect, useRef, useState } from 'react';
import InfoTooltip from '../InfoTooltip';
import NumberInput from '../NumberInput';
import SlideToggle from '../SlideToggle';
import styles from './styles.module.scss';

interface Props {
  id?: string;
  value: string | number;
  className?: string;
  containerClass?: string;
  useColors?: boolean;
  lightText?: boolean;
  tooltip?: ReactNode;
  showTooltip?: boolean;
  onToggle?: () => void;
  toggleSelected?: boolean;
  showToggle?: boolean;
  label?: string;
  showMinus?: boolean;
  showEquals?: boolean;
  valueStyles?: string;
  isEditable?: boolean;
  rightBorder?: boolean;
  wholeNumber?: boolean;
  units?: boolean;
  onUpdate?: () => void;
  onChange?: (val: number) => void;
  onCustomEditClick?: () => void;
}

const DollarDisplay = ({
  id,
  value,
  label,
  tooltip,
  useColors,
  className,
  containerClass,
  lightText,
  showMinus,
  showEquals,
  valueStyles,
  showTooltip,
  onToggle,
  toggleSelected = false,
  showToggle,
  isEditable,
  rightBorder,
  wholeNumber = false,
  units = false,
  onUpdate,
  onChange,
  onCustomEditClick,
}: Props) => {
  const [editMode, setEditMode] = useState(false);
  const ref = useRef<any>();
  const stringValue = value.toString();

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (onUpdate) {
        onUpdate();
      }
      setEditMode(false);
    }
  };

  const handleEnter = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (onUpdate) {
        onUpdate();
      }
      setEditMode(false);
    }
  };

  const handleEditClick = () => {
    if (onCustomEditClick) {
      onCustomEditClick();
    } else {
      setEditMode(true);
    }
  };

  useEffect(() => {
    if (isEditable) {
      document.addEventListener('mouseup', handleClickOutside, true);
      document.addEventListener('keydown', handleEnter, true);
      return () => {
        document.removeEventListener('mouseup', handleClickOutside, true);
        document.removeEventListener('keydown', handleEnter, true);
      };
    }
  });

  const isNegative = stringValue.includes('-');
  const absValue = stringValue.replace('-', '');
  const getColorStyle = () => {
    if (!useColors || absValue === '0') {
      return lightText ? styles.lightText : styles.defaultColor;
    }

    return isNegative ? styles.negative : styles.positive;
  };

  return (
    <>
      <div className={clsx(styles.container, containerClass, rightBorder && styles.rightBorder, showMinus && styles.minus, showEquals && styles.equals)}>
        <div className={styles.content}>
          <div className={styles.tooltipDollarTooltipContainer}>
            {/* Left-aligned tooltip */}
            {!label && tooltip && <InfoTooltip id={`dollar-display-${id}`} message={tooltip} iconRightPadding />}

            {/* Number value */}
            {editMode ? (
              // Input value field
              <div ref={ref}>
                <NumberInput
                  value={value}
                  units
                  className={styles.valueInput}
                  placeholder="0"
                  wholeNumber
                  onValueChange={value => {
                    if (onChange) {
                      onChange(Number(value.value));
                    }
                  }}
                />
              </div>
            ) : (
              // Display value
              <div className={clsx(styles.dollarDisplay, getColorStyle(), className)}>
                {isNegative && <span>{'( '}</span>}
                {!units && <sup className={styles.dollarSign}>$</sup>}
                <span className={clsx(styles.value, valueStyles)}>
                  {wholeNumber || units ? formatDollars(stringValue.replace('-', '')) : formatDollarsCents(stringValue.replace('-', ''))}
                </span>
                {isNegative && <span>{' )'}</span>}
              </div>
            )}
          </div>

          {/* Label section */}
          {label && (
            <div className={styles.label}>
              {showToggle && (
                <SlideToggle
                  containerClass={styles.slideToggle}
                  selected={toggleSelected}
                  toggleSelected={() => {
                    if (onToggle) {
                      onToggle();
                    }
                  }}
                />
              )}
              {showTooltip && <InfoTooltip id={`dollar-display-${id}`} message={tooltip} iconSize={12} iconRightPadding />}
              {label}
              {isEditable && <OATIcon className={styles.pencilIcon} icon="edit" size={12} colorTheme="blue" onClick={handleEditClick} pointer />}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DollarDisplay;
