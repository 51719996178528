import { AdminConstants } from 'oat-admin-common';
import { DropdownItem } from 'oat-common-ui';
import { FEATURE_OR_5454_PHASE_2, GiftDelivery, PaymentMethod, PaymentToOptions, ReturningBrands, SalesCodes, SystemToPay } from '../constants/global';

type DropdownNameType =
  | typeof PaymentToOptions
  | typeof PaymentMethod
  | typeof SystemToPay
  | typeof ReturningBrands
  | typeof SalesCodes
  | typeof GiftDelivery
  | typeof AdminConstants.CASH_TYPES
  | typeof AdminConstants.STANDARD_ODD_TERMS;

const processName = (name: DropdownNameType) => {
  return (
    FEATURE_OR_5454_PHASE_2
      ? Object.entries(name).filter(entry => entry[1] !== AdminConstants.CASH_TYPES.COLLEGE_GRADUATE)
      : Object.entries(name).filter(entry => entry[1] !== AdminConstants.CASH_TYPES.COLLEGE)
  ).reduce((acc, curr) => {
    return { ...acc, [curr[0]]: curr[1] };
  }, {}) as DropdownNameType;
};

export const getDropdownItemFromEnum = (name: DropdownNameType) => {
  const newItems: DropdownItem[] = [];
  Object.values(processName(name)).forEach(value => newItems.push({ label: value.toString(), value: value.toString() }));

  return newItems;
};
