import { observer } from 'mobx-react-lite';
import Accordion from './Accordion';
import AccordionItem from './AccordionItem';
import AccordionModel from './models/AccordionModel';

interface Props {
  accordion: AccordionModel;
}

const AccordionComponent = ({ accordion }: Props) => {
  return (
    <Accordion>
      {accordion.items.map(item => (
        <AccordionItem key={item.uid} item={item} toggleItem={accordion.toggleItem} toggleExpand={accordion.toggleExpand} />
      ))}
    </Accordion>
  );
};

export default observer(AccordionComponent);
