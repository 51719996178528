import { CreateLeaseModels } from 'oat-common-ui';
import { ReactNode, useEffect, useState } from 'react';
import IpBreadCrumbs from '../../../../components/IpComponents/IpBreadCrumbs';
import { FEATURE_OR_4641 } from '../../../../constants/global';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import LoadingPage from '../../../LoadingPage';
import getTrimListFromEFC from '../CreateAdLease/utils/getTrimListFromEFC';
import { formatTrimList } from './util';

interface Props {
  children: ReactNode;
}

const EFCSeriesData = ({ children }: Props) => {
  const {
    efcStore: { getEfcSeriesApi },
    userInfoStore: {
      userInfo: { brand },
      isLexus,
      isSETUser,
    },
    createLeaseStore: {
      seriesProfile,
      advertisedData: { setTrimList, setSortField, setHideTiersTerms },
      term,
      isChangeVin,
      vehicles: leaseStoreVehicles,
    },
    rcfsResidualsStore: { residuals },
    efcStore: { getInventoryApi },
    dd365Store: { getDD35Inventory },
  } = useStores();

  const { region, offerId } = useUrlParams();
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (!fetching && loading) {
          setFetching(true);
          const efcData = await getEfcSeriesApi(brand, region);

          if (seriesProfile.series?.length && efcData && efcData.series) {
            const { series, seriesYear } = seriesProfile.series[0];
            const vehicles = seriesProfile.vehicles;
            const efcTrimList = getTrimListFromEFC(efcData, series, seriesYear, term, isLexus(), residuals);
            const formattedTrimList = formatTrimList(vehicles, efcTrimList, leaseStoreVehicles, isChangeVin);
            const promises: Promise<CreateLeaseModels.TrimItem>[] = [];

            const addVinCount = async (trimItem: CreateLeaseModels.TrimItem) => {
              let vinCount = 0;
              if (FEATURE_OR_4641) {
                try {
                  if (isSETUser()) {
                    const vinData = await getDD35Inventory(region, seriesYear, trimItem.code);
                    if (vinData.items) {
                      vinCount = vinData.items.length;
                    }
                  } else {
                    const vinData = await getInventoryApi(region, trimItem.code, seriesYear, series, brand);
                    if (vinData.body.response.docs.length) {
                      vinData.body.response.docs.forEach((vinItem: any) => (vinCount += Number(vinItem.vincount)));
                    }
                  }
                } catch (e) {
                  console.log(e);
                }
                trimItem.count = vinCount;
              }

              return trimItem;
            };

            await(async () => {
              formattedTrimList.forEach(trimItem => {
                promises.push(addVinCount(trimItem))
              })
            })();

            const res = await Promise.all(promises);
            setTrimList(res, series, seriesYear);
            setHideTiersTerms(!!offerId);
          }

          setLoading(false);
          setFetching(false);
        }
      } catch (e) {
        setFetching(false);
        setLoading(false);
        setError(true);
      }
    })();
  }, [
    fetching,
    getEfcSeriesApi,
    region,
    brand,
    seriesProfile,
    setTrimList,
    setSortField,
    loading,
    residuals,
    term,
    isLexus,
    offerId,
    setHideTiersTerms,
    isChangeVin,
    leaseStoreVehicles,
    isSETUser,
    getInventoryApi,
    getDD35Inventory,
  ]);

  if (loading || error) {
    return <LoadingPage breadCrumbs={IpBreadCrumbs(false)} error={!!error} />;
  }

  return <>{children}</>;
};

export default EFCSeriesData;
