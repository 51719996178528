import clsx from 'clsx';
import { formatDollars, OATIcon } from 'oat-common-ui';
import { ReactNode } from 'react';
import PenRateModel from '../../../../../models/PenRateModel';
import styles from '../styles.module.scss';

interface Props {
  cashModel: PenRateModel;
  showDates: (model: PenRateModel) => ReactNode | undefined;
}

const CashTableValues = ({ cashModel, showDates }: Props) => {
  const { isAdvertised, cash, isEnhanced } = cashModel;

  return (
    <td className={styles.rate}>
      {isAdvertised && (
        <span className={clsx(styles.icon, styles.marginRight)}>
          <OATIcon icon="active_offer" colorTheme="orange" />
        </span>
      )}
      <span className={clsx(!isAdvertised && styles.paddingLeft, styles.cashName)}>Customer Cash</span>
      <span className={clsx(styles.marginLeft, isEnhanced && styles.addBold)}>${formatDollars(cash)}</span>
      {isEnhanced && (
        <span className={clsx(styles.rate, styles.icon, styles.marginLeft)}>
          <OATIcon icon="enhancement" colorTheme="orange" />
        </span>
      )}
      {showDates(cashModel)}
    </td>
  );
};

export default CashTableValues;
