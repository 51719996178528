import { makeAutoObservable } from 'mobx';
import { AdminConstants, APRCalcFns, getDefaultTfsShare, SubCashCalcFns } from 'oat-admin-common';
import { dateStringToDate, uuidv4 } from 'oat-common-ui';
import { AprDetails, FundingSourceSplits, Offering } from '../../../gql/generated';
import { stores } from '../../../stores/useStores';
import { assignBooleanValue, assignDollarCents, assignNumberValue, assignStringValue } from '../../../utils/assignValue';
import dateValidator from '../../../utils/dateValidator';
import validator from '../../../utils/validator';
import { FundingSplitItem } from '../components/FundingSplitsModal/FundingSplitTerm';
import { calculateBlendedShare } from '../components/FundingSplitsModal/utils';

const { OPTION_TYPE_NAMES } = AdminConstants;

class AprTermFields {
  buyRate = 0;
  defaultEndDate: Date | undefined;
  endDate: Date | undefined;
  fundingSourceSplits: FundingSourceSplits[] = [];
  isIncluded = true;
  isEnhanced = false;
  isValidEnR = true;
  lowTerm = 0;
  nationalEndDate: Date | undefined;
  nationalPayment = '';
  nationalStartDate: Date | undefined;
  nationalSubventionCash = 0;
  nationalTfsShare = '';
  rate = 0;
  defaultStartDate: Date | undefined;
  startDate: Date | undefined;
  subCashTfsCostShareCap = 0;
  subCashTfsShare = 0;
  subventionCash = 0;
  targetPayment = '';
  term = 0;
  termAverageAmount = '';
  termPen = '0';
  tfsShare = '';
  estimatedCost = 0;
  subCashEstCost = 0;
  subCashTfsEstCost = 0;
  subCashEnhTfsEstCost = 0;

  tfsCostShare: number | undefined;
  // used for label values beneath each tfs cost share input

  constructor() {
    makeAutoObservable(this);
  }
}

class AprTermModel {
  uid = uuidv4();
  fields = new AprTermFields();

  disableTFSInput = true;
  isAdvertised = false;
  isStandAlone = true;

  forecastedSales = 0;

  constructor(data?: { regionalDetails?: AprDetails | null; nationalDetails?: AprDetails | null }, region?: string, offering?: Offering) {
    makeAutoObservable(this);

    // process regional
    if (data?.regionalDetails) {
      const {
        startDate,
        endDate,
        tfsShare,
        highTerm,
        lowTerm,
        distribution,
        isEnhanced,
        isValidEnR,
        buyRate,
        isAdvertised,
        avgAmntFinanced,
        forecastedSales,
        fundingSourceSplits,
        subventionRate,
        subventionCash,
        subCashTfsCostShareCap,
        subCashTfsShare,
      } = data.regionalDetails;

      this.fields.startDate = dateStringToDate(startDate || '');
      this.fields.endDate = dateStringToDate(endDate || '');
      this.fields.tfsShare = assignNumberValue(tfsShare).toString();
      this.fields.termAverageAmount = avgAmntFinanced.toString();
      this.fields.isValidEnR = assignBooleanValue(isValidEnR, true);

      this.fields.term = highTerm;
      this.fields.lowTerm = lowTerm;
      this.fields.rate = assignDollarCents(subventionRate != null ? subventionRate : assignNumberValue(buyRate));
      this.fields.buyRate = assignDollarCents(buyRate);
      this.fields.termPen = assignNumberValue(distribution).toString();
      this.fields.isEnhanced = isEnhanced;
      this.fields.subventionCash = assignNumberValue(subventionCash);
      this.isAdvertised = isAdvertised || false;
      this.fields.fundingSourceSplits = fundingSourceSplits || [];

      if (fundingSourceSplits && Boolean(fundingSourceSplits?.length)) {
        const fundingSplitItems = fundingSourceSplits.map(split => {
          return new FundingSplitItem(split);
        });

        this.fields.tfsShare = calculateBlendedShare(fundingSplitItems, false).toString();
      }

      this.fields.subCashTfsCostShareCap = assignNumberValue(subCashTfsCostShareCap);
      this.fields.subCashTfsShare = assignNumberValue(subCashTfsShare);
      this.fields.subCashEstCost = assignNumberValue(data?.regionalDetails.subCashEstCost);
      this.fields.subCashTfsEstCost = assignNumberValue(data?.regionalDetails.subCashTfsEstCost);

      // Seting national data from regional for standalone. For National offers this will be overriden with data.nationalDetails
      this.fields.nationalEndDate = dateStringToDate(endDate);
      const newLocal = this;
      // Default nationalRate is set within AprCard component useEffect -> aprCard.setDefaultRatesToTerms
      newLocal.fields.nationalStartDate = dateStringToDate(startDate);
      this.fields.nationalTfsShare = assignNumberValue(
        getDefaultTfsShare(OPTION_TYPE_NAMES.APR, assignStringValue(region), data?.regionalDetails.highTerm, !!offering?.useOldCostShareForLexus),
      ).toString();
      this.forecastedSales = Number(forecastedSales);
    }
    // process national
    if (data?.nationalDetails) {
      const { startDate, endDate, subventionCash } = data.nationalDetails;
      this.fields.nationalEndDate = dateStringToDate(endDate);
      this.fields.nationalSubventionCash = assignNumberValue(subventionCash);
      this.fields.nationalStartDate = dateStringToDate(startDate);
      this.fields.nationalTfsShare = getDefaultTfsShare(OPTION_TYPE_NAMES.APR, assignStringValue(region), this.fields.term, !!offering?.useOldCostShareForLexus).toString();

      this.isStandAlone = false;
    }
  }

  updateField = <T extends keyof AprTermFields, V extends AprTermFields[T]>(field: T, value: V) => {
    this.fields[field] = value;

    // Test if offer is enhanced
    if (`${field}` === 'rate') {
      // If not enhanced, make sure start/end dates and tfsShare are back to national
      if (!this.isEnhanced && !this.isStandAlone) {
        this.fields.startDate = this.fields.nationalStartDate;
        this.fields.endDate = this.fields.nationalEndDate;
        this.fields.tfsShare = this.fields.nationalTfsShare;
      }
    }
  };

  updateFundingSourceSplits = (fundingSourceSplits: FundingSourceSplits[]) => {
    this.fields.fundingSourceSplits = fundingSourceSplits;
  };

  get hasDateError() {
    return dateValidator(this.fields.startDate, this.fields.endDate) && this.fields.isIncluded;
  }

  get isPenRateInputValid() {
    return (this.fields.termPen.length && Number(this.fields.termPen) <= 100) || !this.fields.isIncluded;
  }

  get hasRateError() {
    return this.fields.buyRate < this.fields.rate && this.fields.isIncluded;
  }

  get isRateInputValid() {
    if (stores.userInfoStore.isSETUser()) {
      return true;
    }
    return !validator(this.fields.rate, { required: this.fields.isIncluded, min: 0 });
  }

  get hasSubCashError() {
    return this.fields.subventionCash < this.fields.nationalSubventionCash;
  }

  get isTfsShareInputValid() {
    return (this.fields.tfsShare.length && Number(this.fields.tfsShare) <= 100) || !this.fields.isIncluded;
  }

  get isEnhanced() {
    return this.fields.subventionCash > this.fields.nationalSubventionCash || this.fields.isEnhanced || this.fields.buyRate > this.fields.rate;
  }

  get projectedSales() {
    return Math.round(this.forecastedSales * (Number(this.fields.termPen) / 100));
  }

  targetPayment = (averageAmount: string) => {
    // for the SET user we are automaticaly calculating target payment for 24 months
    const avgAmount = stores.userInfoStore.isSETUser() && this.fields.term === 24 ? '20000' : averageAmount;
    return this.fields.isIncluded ? APRCalcFns.calculateEstimatedPayment(Number(assignNumberValue(avgAmount)), this.fields.rate, this.fields.term) : 0;
  };

  nationalTargetPayment = (averageAmount: string) => {
    const avgAmount = stores.userInfoStore.isSETUser() && this.fields.term === 24 ? '20000' : averageAmount;
    return this.isStandAlone
      ? this.targetPayment(averageAmount)
      : APRCalcFns.calculateEstimatedPayment(Number(assignNumberValue(avgAmount)), this.fields.buyRate, this.fields.term) || 0;
  };

  getEstimatedCosts = () => {
    const avgAmount = stores.userInfoStore.isSETUser() && this.fields.term === 24 ? '20000' : this.fields.termAverageAmount;
    const defaultShare = assignNumberValue(this.fields.nationalTfsShare) / 100.0;
    const tfsShare = assignNumberValue(this.fields.tfsShare) / 100.0;
    const distribution = assignNumberValue(this.fields.termPen) / 100.0;

    const results = APRCalcFns.calculateEstimatedCosts(
      this.fields.buyRate / 100.0,
      this.fields.rate / 100.0,
      defaultShare,
      tfsShare,
      this.fields.term,
      distribution,
      Number(avgAmount),
      assignNumberValue(this.fields.subventionCash),
    );

    this.fields.estimatedCost = results.estCost;
    return results;
  };

  getSubCashEstCost() {
    const { subCashEstCost, subCashTfsEstCost, subCashEnhTfsEstCost } = SubCashCalcFns.calculateSubventionCashEstimatedCost(
      this.fields.subventionCash,
      this.fields.nationalSubventionCash,
      0,
      this.fields.subCashTfsShare / 100.0,
      this.fields.subCashTfsCostShareCap,
      0,
      true,
    );

    this.fields.subCashEstCost = subCashEstCost;
    this.fields.subCashTfsEstCost = subCashTfsEstCost;
    this.fields.subCashEnhTfsEstCost = subCashEnhTfsEstCost;

    return { subCashEstCost, subCashTfsEstCost, subCashEnhTfsEstCost };
  }
}

export default AprTermModel;
