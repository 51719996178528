import { makeAutoObservable } from 'mobx';
import { AdminConstants, isFlatCostOfferType } from 'oat-admin-common';
import { uuidv4 } from 'oat-common-ui';

import { ReconcileOfferResponse } from '../../../gql/generated';
import { assignNumberValue, assignStringValue, defaultToUndefined } from '../../../utils/assignValue';
import validator from '../../../utils/validator';
import { InputType } from './ReconcileDataModel';

const { OPTION_TYPE_NAMES } = AdminConstants;
class ReconcileOfferModel {
  uid = uuidv4();
  offerId = '';
  optionTypeName = '';
  tier = '';
  term: InputType = undefined;
  startDate = '';
  aprSubCashTerms: number[] = [];
  endDate = '';
  optionTierType = '';
  isEnhanced = false;
  forecastedSales = 0;
  penetration = 0;
  estimatedCost = 0;
  offerCost = 0;
  combinedPerUnitCost = 0;
  nationalActualUnits: InputType = undefined;
  nationalActualPenetration: InputType = undefined;
  nationalActualCost: InputType = undefined;
  nationalActualOfferCost: InputType = undefined;
  regionalActualUnits: InputType = undefined;
  regionalActualPenetration: InputType = undefined;
  regionalActualCost: InputType = undefined;
  regionalActualOfferCost: InputType = undefined;
  hasExclusions = false;
  isFourthOption = false;
  isMisc = false;
  hasDateSplits = false;

  popoverTitle = '';
  nvsUnits: InputType = undefined;
  nvsOfferCost: InputType = undefined;
  nationalCostVariance = 0;
  regionalCostVariance = 0;
  offerEarnings = 0;
  isFlatCostOfferType = false;
  isCustomerChoice = false;
  hasDateSplit = false;
  calcNatCost = false;
  calcRegCost = false;
  isNational = false;

  constructor(offer: ReconcileOfferResponse, ryoEarnings: number, isNational: boolean) {
    if (isNational) {
      this.calcNatCost = !offer.nationalActualCost;
    } else {
      this.calcRegCost = !offer.regionalActualCost;
    }
    this.offerId = offer.offerId;
    this.optionTypeName = offer.optionTypeName;
    this.tier = assignStringValue(offer.tier);
    this.term = defaultToUndefined(offer.term);
    this.startDate = offer.startDate;
    this.aprSubCashTerms = offer.aprSubCashTerms as number[];
    this.endDate = offer.endDate;
    this.isEnhanced = offer.isEnhanced;
    this.forecastedSales = offer.forecastedSales;
    this.penetration = offer.penetration;
    this.estimatedCost = offer.estimatedCost;
    this.offerCost = offer.offerCost;
    this.combinedPerUnitCost = assignNumberValue(offer.combinedPerUnitCost);
    this.isNational = isNational;
    this.isFlatCostOfferType = isFlatCostOfferType(offer.optionTypeName);
    this.optionTierType = assignStringValue(offer.optionTierType);
    // National
    this.nationalActualUnits = defaultToUndefined(offer.nationalActualUnits);
    this.nationalActualPenetration = defaultToUndefined(offer.nationalActualPenetration);
    if (offer.optionTypeName === OPTION_TYPE_NAMES.APR || offer.optionTypeName === OPTION_TYPE_NAMES.LEASE) {
      this.nationalActualCost = defaultToUndefined(offer.nationalActualCost);
      this.regionalActualCost = defaultToUndefined(offer.regionalActualCost);
    } else {
      this.nationalActualCost = defaultToUndefined(!offer.nationalActualCost && !this.isFlatCostOfferType ? offer.estimatedCost : offer.nationalActualCost);
      this.regionalActualCost = defaultToUndefined(!offer.regionalActualCost && !this.isFlatCostOfferType ? offer.estimatedCost : offer.regionalActualCost);
    }
    this.nationalActualOfferCost = defaultToUndefined(offer.nationalActualOfferCost);
    // Regional
    this.regionalActualUnits = defaultToUndefined(offer.regionalActualUnits);
    this.regionalActualPenetration = defaultToUndefined(offer.regionalActualPenetration);
    this.regionalActualOfferCost = defaultToUndefined(offer.regionalActualOfferCost);
    this.hasExclusions = offer.hasExclusions;
    this.isFourthOption = offer.isFourthOption;
    this.isMisc = offer.isMisc || false;
    this.hasDateSplits = offer.hasDateSplits;

    this.popoverTitle = this.getPopoverDisplayTitle(offer);
    this.offerEarnings = offer.forecastedSales * ryoEarnings;
    this.isCustomerChoice =
      offer.optionTypeName === OPTION_TYPE_NAMES.CUSTOMER_CASH || offer.optionTypeName === OPTION_TYPE_NAMES.APR || offer.optionTypeName === OPTION_TYPE_NAMES.LEASE;

    makeAutoObservable(this);
  }

  getPopoverDisplayTitle = (offer: ReconcileOfferResponse) => {
    switch (offer.optionTypeName) {
      case OPTION_TYPE_NAMES.APR: {
        return `${offer.optionTypeName} Tier ${offer.tier}`;
      }

      case OPTION_TYPE_NAMES.TFS_APR_SUBVENTION_CASH: {
        return `${offer.optionTypeName.replace('Subvention', 'Sub')} Tier ${offer.tier} ${offer.aprSubCashTerms} mo.`;
      }

      case OPTION_TYPE_NAMES.LEASE: {
        return `${offer.optionTypeName} ${offer.term} mo.`;
      }

      case OPTION_TYPE_NAMES.TFS_LEASE_SUBVENTION_CASH: {
        return `${offer.optionTypeName.replace('Subvention', 'Sub')} ${offer.term} mo.`;
      }
      default: {
        return offer.optionTypeName;
      }
    }
  };

  get hasActualCostError() {
    return validator(this.isNational ? this.nationalActualCost : this.regionalActualCost, { required: true }) !== undefined;
  }

  get hasActualUnitsError() {
    return validator(this.isNational ? this.nationalActualUnits : this.regionalActualUnits, { required: true }) !== undefined;
  }
}

export default ReconcileOfferModel;
