import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { OfferTypes, dateFormatISOString, useInputDelay, useToast } from 'oat-common-ui';
import { useEffect, useMemo, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import EnhCostShareNotification from '../../../../components/EnhCostNotification/EnhCosShareNotification';

import EnhTfsCostShareNotification from '../../../../components/EnhCostNotification/EnhTfsCostShareNotification';
import { FEATURE_OR_2477, FEATURE_OR_4154, Status } from '../../../../constants/global';
import { Offer, OfferingCosts, SaveAprOfferInput, useSaveAprOfferMutation, useSaveCompatibilityMutation } from '../../../../gql/generated';
import useAprEnhCostShareUtils from '../../../../hooks/useAprEnhCostShareUtils';
import useAprEnhTfsCostShareUtils from '../../../../hooks/useAprEnhTfsCostShareUtils';
import useOfferEligibility from '../../../../hooks/useOfferEligibility';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import { assignNumberValue } from '../../../../utils/assignValue';
import { getOfferHeaderSource } from '../../../../utils/getOfferHeaderSource';
import { handleEnhancedDates } from '../../../../utils/handleEnhancedDates';
import useGetTfsLabel from '../../../../utils/useGetTfsLabel';
import { buildIsEnhCostShareAcceptedParam, buildIsEnhTfsCostShareAcceptedParam } from '../../../createOffer/CreateApr/enhCostShareUtils';
import { transformAprCardData } from '../../../createOffer/CreateApr/utils';
import AprCardModel from '../../models/AprCardModel';
import DualEnhCostShareModal from '../DualEnhCostShareModal';
import EnhCostShareModal from '../EnhCostShareModal';
import EnhCostShareTooltip from '../EnhCostShareTooltip';
import FetchTfsCostShareAvailabilities from '../FetchTfsCostShareAvailabilities';
import OfferBody, { OfferBodyLeft, OfferBodyRight } from '../OfferBody';
import OfferHeader from '../OfferHeader';
import OfferHeaderCosts from '../OfferHeader/OfferHeaderCosts';
import ProgramDetailsWrapper from '../ProgramDetailsWrapper';
import { OfferCostTooltip, OfferEarningsTooltip } from '../offerCostTooltips';
import AprContextMenu from './components/AprContextMenu';
import AprTier from './components/AprTier';
import AprTierHeader from './components/AprTierHeader';
import OfferDetails from './components/OfferDetails';
import styles from './styles.module.scss';
import { buildSaveAprInput, updateAprEnhFields } from './utils';

type Props = {
  aprCard: AprCardModel;
  compatibileAccessories?: string[];
};

export type UserInfo = {
  isGst: boolean;
  isSet: boolean;
  isLexus: boolean;
};

const { OPTION_TYPE_NAMES } = AdminConstants;

const AprCard = ({ aprCard, compatibileAccessories }: Props) => {
  const {
    fields: { name, compatibileOffers, enhancedStartDate, enhancedEndDate },
    isForCreate,
    selectedTier,
    selectedTierModel,
    isStandalone,
    updateField,
    updateRev,
    rev,
    id,
    nationalOffer,
    setDefaultRatesToTerms,
    hasError,
  } = aprCard;

  const {
    userInfoStore: { isGst, isSETUser, isLexus },
    programDetailsStore: {
      seriesProfile: { ncsRate },
      ryoEarnings,
      rgnlAlt: { isSeriesConfirmed },
      offering,
      getEnhancedCostShareById,
      enhancedCostShares,
    },
    rcfsResidualsStore: { stdAprRates: standardRates },
    regionalCostShareStore: { costShareDatesMap, getEnhancedTfsCostShareById },
    offeringCostsStore,
  } = useStores();

  const [saveAprOffer] = useSaveAprOfferMutation();
  const [saveCompatibility] = useSaveCompatibilityMutation();

  const { setDelay } = useInputDelay();
  const { error } = useToast();
  const { profile } = useUrlParams();
  const aprId = `apr-${aprCard.id}`;
  const userInfo: UserInfo = { isGst: isGst(), isSet: isSETUser(), isLexus: isLexus() };

  const enhancedDates = useMemo(
    () => enhancedStartDate && enhancedEndDate && handleEnhancedDates(enhancedStartDate, enhancedEndDate, offering.startDate, offering.endDate, false, isForCreate),
    [enhancedStartDate, enhancedEndDate, offering.startDate, offering.endDate, isForCreate],
  );

  useEffect(() => {
    if (isForCreate && standardRates) {
      setDefaultRatesToTerms(standardRates, ncsRate, isSETUser());
    }
  }, [standardRates, setDefaultRatesToTerms, isForCreate, ncsRate, isSETUser]);

  useEffect(() => {
    selectedTierModel?.setHighestAdvertisedTerm();
  }, [selectedTierModel]);

  const tfsLabel = useGetTfsLabel();
  const { handleEnhCostShare, handleEnhTfsCostShare } = useOfferEligibility();

  const [showEligibleDualModal, setShowEligibleDualModal] = useState(false);
  const [showRemovalDualModal, setShowRemovalDualModal] = useState(false);

  // for enhanced cost share
  const {
    handleCheckForEnhCostShares,
    removeEnhReason,
    showEnhCostShareModal,
    setShowEnhCostShareModal,
    showRemoveCostShareModal,
    setShowRemoveCostShareModal,
    setNewAprEnhFields,
    replaceExisting,
    buildEnhCostSharePayloadOnUpdate,
    handleUpdateAttributesOnUpdate,
    handleResetAfterSave,
    handleResetOnCancel,
    handleCancelOnAddEnhCostShare,
  } = useAprEnhCostShareUtils(aprCard, handleEnhCostShare, offering);

  // for enhanced tfs cost share
  const {
    handleCheckForEnhTfsCostShares,
    showEnhTfsCostShareModal,
    setShowEnhTfsCostShareModal,
    showRemoveTfsCostShareModal,
    setShowRemoveTfsCostShareModal,
    removeEnhTfsReason,
    replaceExistingTfs,
    handleCancelOnAddEnhTfsCostShare,
    handleUpdateTfsAttributesOnUpdate,
    handleResetDates,
    handleResetAfterTfsSave,
    handleResetRemoveTfsParams,
    buildEnhTfsCostSharePayloadOnUpdate,
    setNewAprEnhTfsFields,
  } = useAprEnhTfsCostShareUtils(aprCard, handleEnhTfsCostShare, offering);

  const handleInputChange = (skipDelay = false, skipRecalc = false, updateTfs = false) => {
    if (aprCard.hasError || aprCard.isForCreate) {
      return;
    }

    if (FEATURE_OR_2477 && aprCard.enhCostShareOfferId && !aprCard.enhancedCostShare) {
      aprCard.setEnhancedCostShare(getEnhancedCostShareById(aprCard.enhCostShareOfferId));
    }

    if (FEATURE_OR_4154 && aprCard.enhTfsCostShareId && !aprCard.enhTfsCostShare) {
      aprCard.setEnhTfsCostShare(getEnhancedTfsCostShareById(profile, OPTION_TYPE_NAMES.APR, aprCard.enhTfsCostShareId));
    }

    setDelay(
      async () => {
        await saveApr(
          !!FEATURE_OR_2477 && aprCard.isEnhCostShareAccepted !== false,
          false,
          skipRecalc,
          !!FEATURE_OR_4154 && aprCard.isEnhTfsCostShareAccepted !== false && aprCard.isStandalone,
          false,
          updateTfs,
        );
      },
      skipDelay ? 0 : undefined,
    );
  };

  const saveApr = async (
    checkForEnhCostShares = false,
    isEnhCostShareAccepted = false,
    skipRecalc = false,
    checkForEnhTfsCostShares = false,
    isEnhTfsCostShareAccepted = false,
    updateTfs = false,
  ) => {
    if (aprCard.hasError || !aprCard.isAvgAmountValid) {
      return;
    }

    const aprData = transformAprCardData(aprCard, userInfo);

    // For ticket 5826 **
    if (checkForEnhCostShares) {
      const { showModal, isRemoving, isReplacing } = handleCheckForEnhCostShares();

      if (checkForEnhTfsCostShares && !isSETUser()) {
        const { showModal: showTfsModal, isRemoving: isTfsRemoving, isReplacing: isTfsReplacing } = handleCheckForEnhTfsCostShares();

        if (
          (showModal && aprCard.isEnhCostShareAccepted === undefined) ||
          (showTfsModal && aprCard.isEnhTfsCostShareAccepted === undefined) ||
          isRemoving ||
          isTfsRemoving ||
          isReplacing ||
          isTfsReplacing
        ) {
          if (showModal && showTfsModal) {
            setShowEligibleDualModal(true);
          }

          if (isRemoving && isTfsRemoving) {
            setShowRemovalDualModal(true);
          }

          return;
        }
      } else {
        if ((showModal && aprCard.isEnhCostShareAccepted === undefined) || isRemoving || isReplacing) {
          return;
        }
      }
    }

    // for cost share, handling recalculations, & reverting dates or model codes if user clicks cancel on modal
    const isEnhCostShareAcceptedParam = buildIsEnhCostShareAcceptedParam(isEnhCostShareAccepted, removeEnhReason, replaceExisting, aprCard);
    handleUpdateAttributesOnUpdate(isEnhCostShareAcceptedParam, skipRecalc, updateTfs);

    // for buy rate recalculations, & reverting dates if user selects cancel on modal
    const isEnhTfsCostShareAcceptedParam = buildIsEnhTfsCostShareAcceptedParam(isEnhTfsCostShareAccepted, removeEnhTfsReason, replaceExistingTfs, aprCard);
    handleUpdateTfsAttributesOnUpdate(isEnhTfsCostShareAcceptedParam, skipRecalc);

    const enhCostSharePayload = buildEnhCostSharePayloadOnUpdate(isEnhCostShareAcceptedParam);
    const enhTfsCostSharePayload = buildEnhTfsCostSharePayloadOnUpdate(enhCostSharePayload, isEnhTfsCostShareAcceptedParam);

    const saveAprPayload: SaveAprOfferInput = {
      id,
      rev: aprCard.rev,
      ...aprData,
    };

    try {
      const res = await trackPromise(
        saveAprOffer({
          variables: {
            input: buildSaveAprInput(saveAprPayload, enhCostSharePayload, enhTfsCostSharePayload),
          },
        }),
      );

      updateAprEnhFields(res.data?.saveAprOffer.offer as Offer, setNewAprEnhFields, setNewAprEnhTfsFields);
      aprCard.updateRev(res.data?.saveAprOffer?.offer?.rev);

      // Updating enr validation on input changes
      if (aprCard.isDuplicate) {
        aprCard.updateEnRValidation(res.data?.saveAprOffer?.offer.aprDetails);
      }

      offeringCostsStore.setData(res.data?.saveAprOffer.offeringCosts as OfferingCosts);
    } catch (e) {
      error((e as Error).message);
    }
  };

  // For dual modal if user selects Yes to both, applying both enh cost share & enh tfs cost share
  const handleSaveOnDualModal = async (isEnhTfsCostShareAccepted = false, skipRecalc = false) => {
    const aprData = transformAprCardData(aprCard, userInfo);
    handleUpdateAttributesOnUpdate(true, skipRecalc);
    handleUpdateTfsAttributesOnUpdate(true, skipRecalc);

    const input = {
      ...aprData,
      id: aprCard.id,
      rev: aprCard.rev,
      isEligibleForEnhCostShare: aprCard.isEligibleForEnhCostShare,
      isEnhCostShareAccepted: aprCard.isEnhCostShareAccepted,
      enhCostShareOfferId: aprCard.enhCostShareOfferId,
      isEnhCostShareUpdated: aprCard.isEnhCostShareUpdated,
      isEligibleForEnhTfsCostShare: aprCard.isEligibleForEnhTfsCostShare,
      isEnhTfsCostShareAccepted: isEnhTfsCostShareAccepted,
      enhTfsCostShareId: aprCard.enhTfsCostShareId,
    };

    try {
      const res = await trackPromise(
        saveAprOffer({
          variables: {
            input,
          },
        }),
      );

      updateAprEnhFields(res.data?.saveAprOffer.offer as Offer, setNewAprEnhFields, setNewAprEnhTfsFields);
      aprCard.updateRev(res.data?.saveAprOffer?.offer?.rev);
      // Updating enr validation on input changes
      if (aprCard.isDuplicate) {
        aprCard.updateEnRValidation(res.data?.saveAprOffer?.offer.aprDetails);
      }

      offeringCostsStore.setData(res.data?.saveAprOffer.offeringCosts as OfferingCosts);
    } catch (e) {
      error((e as Error).message);
    }
  };

  // For removal dual modal, user chooses Ok for both modals
  const handleRemoveAllCostShares = async () => {
    const aprData = transformAprCardData(aprCard, userInfo);
    aprCard.setEnhancedCostShare(undefined);
    aprCard.setEnhTfsCostShare(undefined);
    aprCard.setIsDefaultSet(false);

    handleUpdateAttributesOnUpdate(true, false);
    handleUpdateTfsAttributesOnUpdate(true, false);

    const input = {
      ...aprData,
      id: aprCard.id,
      rev: aprCard.rev,
      isEligibleForEnhCostShare: false,
      enhCostShareOfferId: '',
      isEnhCostShareAccepted: undefined,
      isEligibleForEnhTfsCostShare: false,
      enhTfsCostShareId: '',
      isEnhTfsCostShareAccepted: undefined,
    };

    try {
      const res = await trackPromise(
        saveAprOffer({
          variables: {
            input,
          },
        }),
      );

      updateAprEnhFields(res.data?.saveAprOffer.offer as Offer, setNewAprEnhFields, setNewAprEnhTfsFields);
      aprCard.updateRev(res.data?.saveAprOffer?.offer?.rev);

      // Updating enr validation on input changes
      if (aprCard.isDuplicate) {
        aprCard.updateEnRValidation(res.data?.saveAprOffer?.offer.aprDetails);
      }

      offeringCostsStore.setData(res.data?.saveAprOffer.offeringCosts as OfferingCosts);
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleOnEnhCostShareModalSubmit = (isEnhCostShareAccepted = false) => {
    aprCard.setWasEnhCostShareAccepted(aprCard.isEnhCostShareAccepted);
    aprCard.setWasEnhTfsCostShareAccepted(aprCard.isEnhTfsCostShareAccepted);

    aprCard.setIsEnhCostShareAccepted(isEnhCostShareAccepted);
  };

  // for removed notification modal
  const handleRemoveEnhCostShareNotification = async (isCancel = false) => {
    const aprData = transformAprCardData(aprCard, userInfo);

    let enhCostSharePayload = {
      isEnhCostShareAccepted: aprCard.enhCostShareOfferId ? true : false,
      isEnhCostShareUpdated: aprCard.isEnhCostShareUpdated,
      isEnhCostShareRemoved: false,
    };

    if (isCancel) {
      enhCostSharePayload = {
        isEnhCostShareAccepted: false,
        isEnhCostShareUpdated: false,
        isEnhCostShareRemoved: false,
      };
    }

    try {
      const res = await trackPromise(
        saveAprOffer({
          variables: {
            input: {
              ...aprData,
              id,
              rev,
              isEligibleForEnhCostShare: aprCard.isEligibleForEnhCostShare,
              enhCostShareOfferId: aprCard.enhCostShareOfferId,
              ...enhCostSharePayload,
            },
          },
        }),
      );

      setNewAprEnhFields(res.data?.saveAprOffer.offer as Offer);
      aprCard.updateRev(res.data?.saveAprOffer?.offer?.rev);
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleSaveCompatibility = async () => {
    if (hasError || (!id && !rev)) {
      return;
    }

    try {
      const res = await trackPromise(saveCompatibility({ variables: { input: { id, rev, compatibilityList: compatibileOffers } } }));
      updateRev(res.data?.saveCompatibility.offer.rev);
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleSaveOnEnhCostShareModal = (checkForEnhCostShares = false, isEnhCostShareAccepted = false, onSecondModal = false) => {
    // to prevent save being called if remove is cancelled on modal
    if (removeEnhReason && !isEnhCostShareAccepted) {
      handleResetOnCancel(false);
      handleResetAfterSave();
      return;
    }

    if (removeEnhReason && isEnhCostShareAccepted) {
      aprCard.setEnhancedCostShare(undefined);
    }

    if (aprCard.isEligibleForEnhCostShare && !isEnhCostShareAccepted) {
      handleCancelOnAddEnhCostShare(replaceExisting);
    }

    if (!onSecondModal) {
      saveApr(checkForEnhCostShares, isEnhCostShareAccepted);
    }

    handleResetAfterSave();
  };

  const handleSaveOnEnhTfsCostShareModal = (checkForEnhTfsCostShares = false, isEnhTfsCostShareAccepted = false, onSecondModal = false) => {
    if (removeEnhTfsReason && !isEnhTfsCostShareAccepted) {
      handleResetDates();
      handleResetAfterTfsSave();
      return;
    }

    if (removeEnhTfsReason && isEnhTfsCostShareAccepted) {
      aprCard.setIsDefaultSet(false);
      aprCard.setEnhTfsCostShare(undefined);
    }

    if (aprCard.isEligibleForEnhTfsCostShare && !isEnhTfsCostShareAccepted) {
      handleResetDates();
      handleCancelOnAddEnhTfsCostShare();
    }

    saveApr(false, onSecondModal ? aprCard.isEnhCostShareAccepted : false, false, checkForEnhTfsCostShares, isEnhTfsCostShareAccepted);
    handleResetAfterTfsSave();
  };

  // to make sure whats on the apr card is what was previously saved when user is eligible for both enh cost share and enh tfs
  // but user selects cancel
  const handleResetOfEnhancedCardData = () => {
    const resetStartDate = dateFormatISOString(aprCard.selectedTierModel?.terms[0].fields.startDate);
    const resetEndDate = dateFormatISOString(aprCard.selectedTierModel?.terms[0].fields.endDate);

    const foundEnhCostShare = enhancedCostShares.find(e => e.startDate === resetStartDate && e.endDate === resetEndDate);
    if (foundEnhCostShare && aprCard.wasEnhCostshareAccepted) {
      aprCard.setIsEligibleForEnhCostShare(true);
      aprCard.setEnhCostShareOfferId(foundEnhCostShare?.offerId);
      aprCard.setIsEnhCostShareAccepted(true);
    } else {
      aprCard.setIsEligibleForEnhCostShare(false);
      aprCard.setEnhCostShareOfferId('');
      aprCard.setIsEnhCostShareAccepted(undefined);
    }

    const foundMap = costShareDatesMap.get(profile);
    const foundCostShareDates = foundMap?.aprCostShareDates.find(c => c.startDate === resetStartDate && c.endDate === resetEndDate);
    if (foundCostShareDates && aprCard.wasEnhTfsCostShareAccepted) {
      aprCard.setIsEligibleForEnhTfsCostShare(true);
      aprCard.setEnhTfsCostShareId(foundCostShareDates.id);
      aprCard.setIsEnhTfsCostShareAccepted(true);
    } else {
      aprCard.setIsEligibleForEnhTfsCostShare(false);
      aprCard.setEnhTfsCostShareId('');
      aprCard.setIsEnhTfsCostShareAccepted(undefined);
    }
  };

  const nationalNote = nationalOffer?.aprDetails?.find(apr => apr.note)?.note;
  const isDisabled = offering.status === Status.MEETS_GUIDELINES || aprCard.isDisabled;
  const showEnhCostShareTooltip = FEATURE_OR_2477 && isForCreate && enhancedCostShares.find(e => e.optionTypeName === OPTION_TYPE_NAMES.APR);

  const displayEnhCostShareModal = FEATURE_OR_2477 && showEnhCostShareModal && !removeEnhReason;
  const displayRemoveEnhCostShareModal = FEATURE_OR_2477 && showRemoveCostShareModal && removeEnhReason;
  const displayEnhTfsCostShareModal = FEATURE_OR_4154 && showEnhTfsCostShareModal && !removeEnhTfsReason;
  const displayRemoveEnhTfsCostShareModal = FEATURE_OR_4154 && showRemoveTfsCostShareModal && removeEnhTfsReason;

  return (
    <section id={aprId}>
      {/* For enhanced cost share */}
      {displayEnhCostShareModal && <EnhCostShareModal id={`${aprId}-enh-cost-share`} onButtonPress={isApplied => handleSaveOnEnhCostShareModal(false, isApplied)} />}
      {displayRemoveEnhCostShareModal && (
        <EnhCostShareModal
          id={`${aprId}-remove-enh-cost-share`}
          onButtonPress={isApplied => handleSaveOnEnhCostShareModal(false, isApplied)}
          text={`${removeEnhReason} National Enhanced Cost Share will be removed. Do you want to continue?`}
          submitBtnText={removeEnhReason && 'OK'}
          title={removeEnhReason && 'Remove Enhanced Cost Share'}
        />
      )}

      {/* For enhanced tfs cost share */}
      {displayEnhTfsCostShareModal && (
        <EnhCostShareModal
          id={`${aprId}-enh-tfs-cost-share`}
          title={`Enhanced ${tfsLabel} Cost Share Available`}
          text={`Offer is eligible for National Enhanced ${tfsLabel} Cost Share. Do you want to apply?`}
          onButtonPress={isApplied => handleSaveOnEnhTfsCostShareModal(false, isApplied)}
        />
      )}
      {displayRemoveEnhTfsCostShareModal && (
        <EnhCostShareModal
          id={`${aprId}-remove-enh-tfs-cost-share`}
          onButtonPress={isApplied => handleSaveOnEnhTfsCostShareModal(false, isApplied)}
          text={`Dates selected are ineligible for National Enhanced ${tfsLabel} Cost Share. National Enhanced ${tfsLabel} Cost Share will be removed. Do you want to continue?`}
          submitBtnText={removeEnhTfsReason && 'OK'}
          title={removeEnhTfsReason && `Enhanced ${tfsLabel} Cost Share Removed`}
        />
      )}

      {/* For enhanced cost share & enhanced tfs cost share - OR-5826 */}
      {showEligibleDualModal && (
        <DualEnhCostShareModal
          idOne={`${aprId}-dual-enh-cost-share`}
          idTwo={`${aprId}-dual-enh-tfs-cost-share`}
          onButtonPressOne={isApplied => handleOnEnhCostShareModalSubmit(isApplied)}
          onButtonPressTwo={isApplied => {
            if (!isApplied || aprCard.isEnhCostShareAccepted === false) {
              aprCard.setIsEnhCostShareAccepted(true);
              handleResetDates();
              handleResetOfEnhancedCardData();
              setShowEligibleDualModal(false);
              return;
            }

            handleSaveOnDualModal(isApplied, false);
            setShowEligibleDualModal(false);
          }}
          showEnhCostShareModal={showEnhCostShareModal}
          showEnhTfsCostShareModal={showEnhTfsCostShareModal}
          setShowEnhCostShareModal={setShowEnhCostShareModal}
          setShowEnhTfsCostShareModal={setShowEnhTfsCostShareModal}
        />
      )}
      {showRemovalDualModal && (
        <DualEnhCostShareModal
          idOne={`${aprId}-dual-remove-enh-cost-share`}
          idTwo={`${aprId}-dual-remove-enh-tfs-cost-share`}
          textOne={`${removeEnhReason} National Enhanced Cost Share will be removed. Do you want to continue?`}
          titleOne={removeEnhReason && 'Remove Enhanced Cost Share'}
          textTwo={`Dates selected are ineligible for National Enhanced ${tfsLabel} Cost Share. National Enhanced ${tfsLabel} Cost Share will be removed. Do you want to continue?`}
          titleTwo={removeEnhTfsReason && `Enhanced ${tfsLabel} Cost Share Removed`}
          onButtonPressOne={isApplied => {
            if (!isApplied) {
              aprCard.setIsEnhCostShareAccepted(false);
            }

            setShowRemoveCostShareModal(false);
          }}
          onButtonPressTwo={isApplied => {
            if (!isApplied || aprCard.isEnhCostShareAccepted === false) {
              aprCard.setIsEnhCostShareAccepted(true);
              handleResetRemoveTfsParams();
              handleResetDates();
              setShowRemovalDualModal(false);
              return;
            }

            if (isApplied && aprCard.isEnhCostShareAccepted === true) {
              handleRemoveAllCostShares();
            }

            handleResetAfterSave();
            handleResetAfterTfsSave();
            setShowRemovalDualModal(false);
          }}
          showEnhCostShareModal={showRemoveCostShareModal}
          showEnhTfsCostShareModal={showRemoveTfsCostShareModal}
          setShowEnhCostShareModal={setShowRemoveCostShareModal}
          setShowEnhTfsCostShareModal={setShowRemoveTfsCostShareModal}
          submitBtnText="OK"
        />
      )}

      <ProgramDetailsWrapper fixed>
        <FetchTfsCostShareAvailabilities>
          <OfferHeader
            isTitleEditable={isStandalone && !isDisabled}
            offerSource={getOfferHeaderSource(isStandalone, aprCard.isForCreate, aprCard.isAdvertised(), aprCard.hasEnhanced)}
            offerType={OfferTypes.APR}
            setTitle={value => {
              updateField('name', value);
              handleInputChange();
            }}
            nationalNote={nationalNote}
            title={name}
            titleOnly={isForCreate}
            titleSufix={`Tier ${selectedTier}`}
            enhancedDates={enhancedDates}
          >
            {!isForCreate && (
              <>
                <OfferHeaderCosts
                  id={aprCard.id}
                  balance={assignNumberValue(selectedTierModel?.fields.offerBalance)}
                  cost={assignNumberValue(selectedTierModel?.fields.totalOfferCost)}
                  costTooltip={
                    <OfferCostTooltip
                      cost={selectedTierModel?.fields.totalOfferCost || 0}
                      estCost={Number(selectedTierModel?.fields.estCost)}
                      forecastedSales={selectedTierModel?.forecastedSales || 0}
                      isAprOffer
                      totalSubventedCost={selectedTierModel?.fields.subCashOfferCost}
                    />
                  }
                  earnings={assignNumberValue(selectedTierModel?.fields.offerEarnings)}
                  earningsTooltip={
                    <OfferEarningsTooltip
                      earnings={selectedTierModel?.fields.offerEarnings || 0}
                      forecastedSales={assignNumberValue(selectedTierModel?.forecastedSales)}
                      isAprOffer
                      ryoEarnings={ryoEarnings}
                    />
                  }
                />
                <AprContextMenu aprCard={aprCard} saveApr={handleInputChange} />
              </>
            )}
          </OfferHeader>

          <OfferBody>
            <OfferBodyLeft small className={styles.offerDetailsWrapper}>
              <OfferDetails card={aprCard} compatibileAccessories={compatibileAccessories} onChange={handleInputChange} onSaveCompatibility={handleSaveCompatibility} />
            </OfferBodyLeft>

            <OfferBodyRight className={styles.tierWrapper}>
              {selectedTierModel && (
                <>
                  <AprTierHeader card={aprCard} selectedTierModel={selectedTierModel} onChange={handleInputChange} />
                  <AprTier card={aprCard} selectedTierModel={selectedTierModel} onChange={handleInputChange} />
                </>
              )}

              {showEnhCostShareTooltip && <EnhCostShareTooltip offerId={aprCard.id} optionTypeName={OPTION_TYPE_NAMES.APR} selectedTier={selectedTier} />}

              {FEATURE_OR_4154 && !isForCreate && !isSeriesConfirmed && aprCard.isStandalone && (
                <EnhTfsCostShareNotification
                  enhTfsCostShareOfferId={aprCard.enhTfsCostShareId}
                  id={aprCard.id}
                  isEnhTfsCostShareUpdated={aprCard.isEnhTfsCostShareUpdated}
                  onConfirm={() => {
                    aprCard.setIsEnhTfsCostShareUpdated(false);
                    saveApr(false, false);
                  }}
                  onApply={() => {
                    aprCard.setEnhTfsCostShare(getEnhancedTfsCostShareById(profile, OPTION_TYPE_NAMES.APR, aprCard.enhTfsCostShareId));
                    aprCard.setIsEnhTfsCostShareUpdated(false);
                    aprCard.setIsEnhTfsCostShareAccepted(true);
                    saveApr(false, false, false, false, true);
                  }}
                  onCancel={() => {
                    aprCard.setIsEnhTfsCostShareUpdated(false);
                    aprCard.setIsEnhTfsCostShareAccepted(false);
                    saveApr();
                  }}
                />
              )}

              {FEATURE_OR_2477 && !isForCreate && (
                <EnhCostShareNotification
                  enhCostShareOfferId={aprCard.enhCostShareOfferId}
                  id={aprCard.id}
                  isEnhCostShareUpdated={aprCard.isEnhCostShareUpdated}
                  isEnhCostShareRemoved={aprCard.isEnhCostShareRemoved}
                  offerType={OPTION_TYPE_NAMES.APR}
                  onApply={() => {
                    aprCard.setEnhancedCostShare(getEnhancedCostShareById(aprCard.enhCostShareOfferId));
                    saveApr(false, true);
                  }}
                  onHide={handleRemoveEnhCostShareNotification}
                  onCancel={() => handleRemoveEnhCostShareNotification(true)}
                />
              )}
            </OfferBodyRight>
          </OfferBody>
        </FetchTfsCostShareAvailabilities>
      </ProgramDetailsWrapper>
    </section>
  );
};

export default observer(AprCard);
