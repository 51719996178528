import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { blankCarImg, CarJellyImage, SeriesCodeToNameMap, Tooltip } from 'oat-common-ui';
import { useMemo, useState } from 'react';
import { FEATURE_OR_4154 } from '../../../../constants/global';
import { EnhTfsCostShare } from '../../../../gql/generated';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import { getEfcCarJelly, getEfcImageFullPath } from '../../../../utils/efc3dImageUtils';
import EnhCostShareAvailabilities from '../../../ProgramDetails/components/EnhCostShareAvailabilities';
import OfferCompatibleOffers from '../../../ProgramDetails/components/OfferCompatibleOffers';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import SubvenedRcfModal from '../../../../components/SetSubvenedLeaseRcfs/SubvenedRcfModal';
import { FEATURE_OR_4756 } from '../../../../constants/global';

const CreateLeaseOfferDetails = () => {
  const { profile } = useUrlParams();
  const {
    createLeaseStore: {
      advertisedData: { series, seriesYear, selectTrimFields, enterVinFields },
      compatibileOffers,
      compatibility,
      tab,
      vehicles,
    },
    efcStore: { seriesImages },
    programDetailsStore: { seriesProfile },
    userInfoStore: { isSETUser, userInfo },
    rcfsResidualsStore: { subvenedLeaseRcfs },
    regionalCostShareStore: { costShareDatesMap },
  } = useStores();

  const isAdvertised = tab === 1;
  const detail = isAdvertised ? selectTrimFields : enterVinFields;

  const showSubRcfTooltip = isSETUser(userInfo) && subvenedLeaseRcfs.subvenedRcfDetails && subvenedLeaseRcfs.subvenedRcfDetails?.length > 0 && FEATURE_OR_4756;
  const [isSubvRcfModalOpen, setIsSubvRcfModalOpen] = useState(false);

  const carJellyString = useMemo(
    () =>
      (seriesProfile?.series?.length &&
        getEfcImageFullPath(getEfcCarJelly(seriesImages, seriesProfile.series[0].seriesYear, seriesProfile.series[0].series, selectTrimFields.trim?.code) || '')) ||
      blankCarImg,
    [seriesProfile, seriesImages, selectTrimFields.trim?.code],
  );

  const getSeriesTitle = () => {
    return detail.trim?.seriesTitle || '';
  };

  const getVinDisplay = () => {
    return detail.vin ? <p className={styles.vin}>VIN: {detail.vin.vin} </p> : null;
  };

  const getTooltipMessage = () => {
    return detail.vin && detail.vin.accessoriesList ? (
      <ul>
        {detail.vin.accessoriesList.map(acc => (
          <li key={acc}>{acc}</li>
        ))}
      </ul>
    ) : null;
  };

  const getAccessoryList = () => {
    return detail.vin && detail.vin.accessoriesList ? (
      <Tooltip id="accessories" message={getTooltipMessage()} place="bottom" grayTheme className={styles.accTooltip}>
        <span className={styles.accLabel}>Accessories</span>
      </Tooltip>
    ) : null;
  };

  const hasLeaseCostShareDates = FEATURE_OR_4154 && costShareDatesMap.get(profile)?.leaseCostShareDates.length;

  return (
    <div className={cx(styles.container, isAdvertised && styles.containerAdvertised)}>
      <h1 className={styles.title}>
        {seriesYear} {SeriesCodeToNameMap[series]}
      </h1>
      {isAdvertised && getSeriesTitle()}
      {isAdvertised && getAccessoryList()}
      {isAdvertised && getVinDisplay()}
      <CarJellyImage source={carJellyString ?? blankCarImg} />
      <p className={styles.disclaimer}>*Not all features shown</p>
      <OfferCompatibleOffers
        isEditable
        className={!isAdvertised ? styles.compatibileOffersWrapper : undefined}
        compatibileOffers={compatibileOffers}
        compatibility={compatibility}
        toggleCompatibileOffer={() => {}}
        optionType={AdminConstants.OPTION_TYPE_NAMES.LEASE}
        onSubmit={() => {}}
      />
      {hasLeaseCostShareDates && (
        <EnhCostShareAvailabilities
          id={`${series}-${seriesYear}`}
          costShareDates={costShareDatesMap.get(profile)?.leaseCostShareDates as EnhTfsCostShare[]}
          isNonAdvertised={!isAdvertised}
        />
      )}
      {showSubRcfTooltip && (
        <Tooltip
          id="subv-rcf-available"
          place="bottom"
          message={<SubvenedRcfModal subvenedRcfs={subvenedLeaseRcfs} vehicles={vehicles} />}
          isOpen={isSubvRcfModalOpen}
          children={
            <div className={styles.subRcfTooltip} onClick={() => setIsSubvRcfModalOpen(!isSubvRcfModalOpen)}>
              <FontAwesomeIcon icon={faFlag} className={styles.icon} />
              <span className={styles.subRcfTooltipTitle}>Subvened RCF Available</span>
            </div>
          }
        />
      )}
    </div>
  );
};

export default observer(CreateLeaseOfferDetails);
