import { makeAutoObservable } from 'mobx';
import { DropdownItem } from 'oat-common-ui';
import AccordionModel from '../../../../components/Accordion/models/AccordionModel';
import { VehicleInput } from '../../../../gql/generated';
import dateValidator from '../../../../utils/dateValidator';
import validator from '../../../../utils/validator';

const exclude10k = (termsList: string[]) => {
  return termsList.includes('24') || termsList.includes('27') || termsList.includes('30');
};

export class CreateNonAdLeaseFields {
  startDate: Date | undefined;
  endDate: Date | undefined;
  rcf = '';
  nationalRcf = '';
  pnv = '0';
  tfsShare = '15';
  isBlended = true;
  exclusions = [];
  note = '';
  tdaNote = '';
  selectedMileage = {
    label: '',
    value: '',
  } as DropdownItem;
  selectedTermsList: string[] = [];
  selectedTier = '1+';
  selectedTiers = ['1+'];
  vehiclesAccordion = new AccordionModel();
  isInclusions = false;

  // Advertised GST fields
  targetPayment = '0';
  dueAtSigning = '0';
  netCapCost = '0';
  totalMsrp = '0';
  subventionCash = '0';
  bonusCash = '0';
  residualValueAmount = '0';
  downPayment = '0';
  grossCapCost = '0';
  vehicleDescription = '';
  modelCode = '';
  modelYear = '';
  series = '';
  models: string[] = [];
  isSpecialEdition = false;

  constructor() {
    makeAutoObservable(this);
  }
}

class CreateNonAdLeaseModel {
  isLexus = false;
  editVehicles = false;
  fields = new CreateNonAdLeaseFields();

  constructor() {
    makeAutoObservable(this);
  }

  updateField = <T extends keyof CreateNonAdLeaseFields, V extends CreateNonAdLeaseFields[T]>(field: T, value: V) => {
    this.fields[field] = value;
  };

  setEditVehicles = (value: boolean) => {
    this.editVehicles = value;
  };

  setSelectedTerms = (termsList: string[]) => {
    this.fields.selectedTermsList = termsList;
    // select new mileage of 24, 27, and 30 is set and current mileage is 10k;
    if (exclude10k(termsList) && this.fields.selectedMileage.value === '10000') {
      this.fields.selectedMileage = { value: '10000', label: '10000' };
    }
  };

  setSelectedTiers = (tiersList: string[], isBlended = true) => {
    if (isBlended) {
      const indexOfNewlySelected = tiersList.indexOf(this.fields.selectedTier) + 1;
      this.fields.selectedTiers = tiersList.slice(0, indexOfNewlySelected);
    } else {
      this.fields.selectedTiers = tiersList;
    }
  };

  setSelectedMileage = (mileage: { label: string; value: string }) => {
    this.fields.selectedMileage = mileage;
  };

  toggleIsInclusions = () => {
    this.fields.isInclusions = !this.fields.isInclusions;
  };

  setVehicleDescription = (inclusions: VehicleInput[]) => {
    const filtered = inclusions.filter(item => item.vehicleDescription === this.fields.vehicleDescription);
    this.fields.vehicleDescription = filtered.length > 0 ? filtered[0].vehicleDescription : '';
  };

  setAdvGstDisabled = () => {
    return (
      this.hasTargetPaymentError ||
      this.hasDueAtSigningError ||
      this.hasNetCapCostError ||
      this.hasTotalMsrpError ||
      this.hasSubventionCashError ||
      this.hasVehicleDescriptionError ||
      this.hasTfcShareError ||
      this.hasDateError ||
      this.hasTermError ||
      this.hasMileageError ||
      this.hasInclusionsError
    );
  };

  get hasRcfError() {
    return validator(this.fields.rcf, { required: true, min: 0, max: Number(this.fields.nationalRcf) || 0.99999 }) !== undefined;
  }

  get hasTfcShareError() {
    return validator(this.fields.tfsShare, { required: true, max: 100 }) !== undefined;
  }

  get hasPnvError() {
    return validator(this.fields.pnv, { required: true }) !== undefined;
  }

  get hasDateError() {
    return dateValidator(this.fields.startDate, this.fields.endDate);
  }

  get hasTermError() {
    return !this.fields.selectedTermsList.length;
  }

  get hasMileageError() {
    return !Object.values(this.fields.selectedMileage.label || this.fields.selectedMileage.value).length;
  }

  // Advertised GST fields error validations
  get hasTargetPaymentError() {
    return validator(this.fields.targetPayment, { required: true, min: 1 }) !== undefined;
  }

  get hasDueAtSigningError() {
    return validator(this.fields.dueAtSigning, { required: true }) !== undefined;
  }

  get hasNetCapCostError() {
    return validator(this.fields.netCapCost, { required: true, min: 1 }) !== undefined;
  }

  get hasTotalMsrpError() {
    return validator(this.fields.totalMsrp, { required: true, min: 1 }) !== undefined;
  }

  get hasSubventionCashError() {
    return validator(this.fields.subventionCash, { required: true }) !== undefined;
  }

  get hasResidualValueAmountError() {
    return validator(this.fields.residualValueAmount, { required: true, min: 1 }) !== undefined;
  }

  get hasDownPaymentError() {
    return validator(this.fields.downPayment, { required: true, min: 1 }) !== undefined;
  }

  get hasGrossCapCostError() {
    return validator(this.fields.grossCapCost, { required: true }) !== undefined;
  }

  get hasVehicleDescriptionError() {
    return validator(this.fields.vehicleDescription, { required: true }) !== undefined;
  }

  get hasInclusionsError() {
    return (this.fields.isInclusions && this.fields.vehiclesAccordion.isAllSelected(false)) || (!this.fields.isInclusions && this.fields.vehiclesAccordion.isAllSelected(true));
  }
}

export default CreateNonAdLeaseModel;
