import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { OATIcon, useInputDelay, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import EnhCostShareNotification from '../../../../components/EnhCostNotification/EnhCosShareNotification';
import Note from '../../../../components/Note';
import { ENR_ERROR_MESSAGE, FEATURE_OR_2477, Status } from '../../../../constants/global';
import { OfferingCosts, TfsContract, useSaveCompatibilityMutation, useSaveMiscOfferMutation, useSaveTfsContractsMutation } from '../../../../gql/generated';
import useMiscEnhCostShareUtils from '../../../../hooks/useMiscEnhCostShareUtils';
import useStores from '../../../../stores/useStores';
import { getOfferHeaderSource } from '../../../../utils/getOfferHeaderSource';
import { handleEnhancedDates } from '../../../../utils/handleEnhancedDates';
import { getMiscOfferInputByOptionTypeName } from '../../../createOffer/CreateMisc/getMiscOfferInputByOptionTypeName';
import { getTfsContractsWithTierTerms } from '../../../createOffer/utils/getTfsContractsWithTierTerms';
import MiscCardModel from '../../models/MiscCardModel';
import EnhCostShareModal from '../EnhCostShareModal';
import EnhCostShareTooltip from '../EnhCostShareTooltip';
import OfferBody, { OfferBodyLeft, OfferBodyRight } from '../OfferBody';
import OfferHeader from '../OfferHeader';
import OfferHeaderCosts from '../OfferHeader/OfferHeaderCosts';
import ProgramDetailsWrapper from '../ProgramDetailsWrapper';
import { OfferCostTooltip, OfferEarningsTooltip } from '../offerCostTooltips';
import MiscContextMenu from './MiscContextMenu';
import AdditionalMiscCardFields from './components/AdditionalMiscCardFields';
import BasicMiscCardFields from './components/BasicMiscCardFields';
import MiscOfferCardLeftContent from './components/MiscOfferCardLeftContent';
import styles from './styles.module.scss';

type Props = {
  card: MiscCardModel;
  forCreate?: boolean;
  compatibileAccessories?: string[];
};

const MiscCard = ({ card, forCreate = false, compatibileAccessories }: Props) => {
  const {
    fields: {
      note,
      tdaNote,
      type,
      isEnhanced,
      forecastedSales,
      offerCost,
      offerEarnings,
      offerBalance,
      estimatedCost,
      startDate,
      endDate,
      defaultStartDate,
      defaultEndDate,
      isValidEnR,
      name,
    },
    nationalOffer,
    updateField,
    isAdvertised,
    isDuplicate,
    isStandAlone,
    hasNoteError,
  } = card;

  const {
    programDetailsStore: { ryoEarnings, offering, seriesProfile, updateAdditionalCashForLease, enhancedCostShares },
    seriesMappingStore: { seriesMapping },
    offeringCostsStore,
  } = useStores();
  const { OfferTypes, CASH_TYPES, OPTION_TYPE_NAMES } = AdminConstants;

  const { setDelay } = useInputDelay();
  const { error } = useToast();

  const [saveMiscOffer] = useSaveMiscOfferMutation();
  const [saveCompatibility] = useSaveCompatibilityMutation();
  const [saveTfsContracts] = useSaveTfsContractsMutation();

  const miscId = `misc-${card.id}`;
  const { handleEnhCostData, handleOfferEligibility, buildPayload } = useMiscEnhCostShareUtils(card);

  const handleSaveTfsContracts = async (tfsContractsParam: TfsContract[]) => {
    try {
      const res = await trackPromise(saveTfsContracts({ variables: { input: { id: card.id, rev: card.rev, tfsContracts: getTfsContractsWithTierTerms(tfsContractsParam) } } }));

      if (res.data?.saveTfsContracts.success) {
        card.updateRev(res.data?.saveTfsContracts.offer.rev);
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleInputChange = (skipDelay = false) => {
    if (!forCreate) {
      setDelay(
        async () => {
          await handleSaveOffer();
        },
        skipDelay ? 0 : undefined,
      );
    }
  };

  const handleSaveOffer = async () => {
    if (!card.hasError && !card.hasPNVError && !card.hasDateError && !card.hasTargetedAudienceError) {
      try {
        if (handleOfferEligibility()) {
          return;
        }

        handleEnhCostData();

        if (card.enhCostShareFields.isModalConfirmed && card.enhCostShareFields.removingReason) {
          handleSaveTfsContracts(card.fields.tfsContracts as TfsContract[]);
        }

        const { miscProps } = getMiscOfferInputByOptionTypeName(card);
        const input = buildPayload(miscProps);

        const res = await trackPromise(
          saveMiscOffer({
            variables: {
              input,
            },
          }),
        );

        card.updateInitialData();

        if (res.data?.saveMiscOffer.offer) {
          card.setCardData(res.data?.saveMiscOffer.offer, seriesMapping, seriesProfile, offering);
        }

        if (res.data?.saveMiscOffer.offers) {
          updateAdditionalCashForLease(res.data.saveMiscOffer.offers);
        }

        card.resetEnhCostShareFields();
        offeringCostsStore.setData(res.data?.saveMiscOffer.offeringCosts as OfferingCosts);
      } catch (e) {
        error((e as Error).message);
      }
    }
  };

  const negEstCost = estimatedCost < 0;

  const handleSaveCompatibility = async () => {
    if (card.hasError) {
      return;
    }

    try {
      const res = await trackPromise(saveCompatibility({ variables: { input: { id: card.id, rev: card.rev, compatibilityList: card.fields.compatibileOffers } } }));
      card.updateRev(res.data?.saveCompatibility.offer.rev);
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleRemoveEnhCostShareNotification = async (isCancel = false) => {
    const { miscProps } = getMiscOfferInputByOptionTypeName(card);
    const input = buildPayload(miscProps, !isCancel, isCancel);

    try {
      const res = await trackPromise(
        saveMiscOffer({
          variables: {
            input,
          },
        }),
      );

      if (res.data?.saveMiscOffer.offer) {
        card.setCardData(res.data?.saveMiscOffer.offer, seriesMapping, seriesProfile, offering);
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const showEnhCostShareTooltip = FEATURE_OR_2477 && forCreate && enhancedCostShares.find(e => e.optionTypeName === type);

  return (
    <ProgramDetailsWrapper>
      <section id={miscId}>
        <OfferHeader
          offerSource={getOfferHeaderSource(isStandAlone, forCreate, isAdvertised, isEnhanced || false)}
          offerType={OfferTypes.MISCELLANEOUS}
          title={isStandAlone && forCreate ? 'Add New Miscellaneous Offer' : name}
          isTitleEditable={isStandAlone && !forCreate && offering.status !== Status.MEETS_GUIDELINES && !card.isDisabled}
          setTitle={value => {
            updateField('name', value);
            handleInputChange();
          }}
          nationalNote={nationalOffer?.cashDetails?.note}
          enhancedDates={handleEnhancedDates(startDate, endDate, defaultStartDate, defaultEndDate, false, forCreate)}
        >
          {!forCreate && (
            <OfferHeaderCosts
              id={miscId}
              earnings={offerEarnings}
              earningsTooltip={
                <OfferEarningsTooltip forecastedSales={forecastedSales} ryoEarnings={ryoEarnings} earnings={offerEarnings} difference={negEstCost ? Math.abs(estimatedCost) : 0} />
              }
              cost={offerCost}
              costTooltip={<OfferCostTooltip forecastedSales={forecastedSales} estCost={negEstCost ? 0 : Math.abs(estimatedCost)} cost={offerCost} offerType={type} />}
              balance={offerBalance}
            />
          )}
          {!forCreate && <MiscContextMenu miscCard={card} forCreate={forCreate} onChange={handleInputChange} />}
        </OfferHeader>
        <OfferBody>
          <OfferBodyLeft>
            <MiscOfferCardLeftContent
              card={card}
              forCreate={forCreate}
              compatibileAccessories={compatibileAccessories}
              handleInputChange={handleInputChange}
              handleSaveCompatibility={handleSaveCompatibility}
            />
          </OfferBodyLeft>
          <OfferBodyRight>
            <BasicMiscCardFields card={card} forCreate={forCreate} onChange={handleInputChange} />
            <AdditionalMiscCardFields card={card} forCreate={forCreate} onChange={handleInputChange} />
            {type !== CASH_TYPES.NON_CASH_CERTIFICATE && (
              <>
                <Note
                  disabled={card.isDisabled}
                  hasError={hasNoteError}
                  labelClass={styles.inputLabel}
                  value={note}
                  onChange={value => {
                    updateField('note', value);
                    handleInputChange();
                  }}
                  label="Note"
                  isRequired={type === OPTION_TYPE_NAMES.FINAL_PAY}
                />
                <Note
                  disabled={card.isDisabled}
                  labelClass={styles.inputLabel}
                  value={tdaNote}
                  onChange={value => {
                    updateField('tdaNote', value);
                    handleInputChange();
                  }}
                  label="TDA Note"
                />
              </>
            )}
            {showEnhCostShareTooltip && <EnhCostShareTooltip offerId={card.id} optionTypeName={type} />}
            {hasNoteError && (
              <p className={styles.errorMsg}>
                <OATIcon className={styles.warningIcon} icon="warning" colorTheme="red" />
                Please fill out the required note field
              </p>
            )}
            {!isValidEnR && isDuplicate && (
              <p className={styles.errorMsg}>
                <OATIcon className={styles.warningIcon} icon="warning" colorTheme="red" />
                {ENR_ERROR_MESSAGE}
              </p>
            )}
            {FEATURE_OR_2477 && !forCreate && (
              <EnhCostShareNotification
                enhCostShareOfferId={card.enhCostShareOfferId}
                id={card.uid}
                isEnhCostShareUpdated={card.isEnhCostShareUpdated}
                isEnhCostShareRemoved={card.isEnhCostShareRemoved}
                offerType={OfferTypes.MISCELLANEOUS}
                onApply={() => {
                  card.setIsEnhCostShareUpdated(false);
                  card.updateEnhCostShareField('isNotificationConfirmed', true);
                  card.updateEnhCostShareField('eligibleOfferId', card.enhCostShareOfferId);
                  handleSaveOffer();
                }}
                onHide={handleRemoveEnhCostShareNotification}
                onCancel={() => handleRemoveEnhCostShareNotification(true)}
              />
            )}
          </OfferBodyRight>
        </OfferBody>
      </section>
      {FEATURE_OR_2477 && card.enhCostShareFields.showEnhCostShareModal && (
        <EnhCostShareModal
          onButtonPress={isConfirmed => {
            card.updateEnhCostShareField('isModalConfirmed', isConfirmed);
            handleInputChange(true);
          }}
        />
      )}

      {FEATURE_OR_2477 && card.enhCostShareFields.removingReason && (
        <EnhCostShareModal
          onButtonPress={isConfirmed => {
            card.updateEnhCostShareField('isModalConfirmed', isConfirmed);
            handleInputChange(true);
          }}
          submitBtnText="OK"
          text={`${card.enhCostShareFields.removingReason} National Enhanced Cost Share will be removed. Do you want to continue?`}
          title="Remove Enhanced Cost Share"
        />
      )}
    </ProgramDetailsWrapper>
  );
};

export default observer(MiscCard);
