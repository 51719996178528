import { makeAutoObservable } from 'mobx';
import { DropdownItem } from 'oat-common-ui';
import { Status } from '../constants/global';
import defaultOffering from '../defaultObjects/defaultOffering';
import { ForecastedSalesResponse, Offering, RgnlAlt, SeriesProfile } from '../gql/generated';
import SETContestNumber, { SETContestNumbersByType } from '../models/SETContestNumber';
import { SeriesProfileForecastedSales } from '../pages/Summary/models/FSVModel';
import ProfileModel from '../pages/Summary/models/ProfileModel';
import { assignNumberValue } from '../utils/assignValue';
import { buildContestNumbers } from '../utils/buildContestNumbers';

const DEFAULT_DROPDOWN_VALUE = 'Show All Offers';

class SummaryStore {
  offering: Offering = defaultOffering;
  profiles = new Array<ProfileModel>();
  allProfiles = new Array<ProfileModel>();
  estCarryOver = 0;
  totalForecastedSales = 0;
  contestNumbersByType: SETContestNumbersByType[] = [];
  parentOfferingId = '';
  showAllOffers = false;

  dropdownValue = { value: DEFAULT_DROPDOWN_VALUE, label: DEFAULT_DROPDOWN_VALUE };
  dropdownOptions = [
    { value: Status.SUBMITTED, label: Status.SUBMITTED },
    { value: Status.MEETS_GUIDELINES, label: Status.MEETS_GUIDELINES },
    { value: Status.NEEDS_REVISIONS, label: Status.NEEDS_REVISIONS },
    { value: Status.REVISED, label: Status.REVISED },
    { value: DEFAULT_DROPDOWN_VALUE, label: DEFAULT_DROPDOWN_VALUE },
  ];

  constructor() {
    makeAutoObservable(this);
  }

  setProfiles = (data: SeriesProfile[]) => {
    this.profiles = data.map(item => {
      return new ProfileModel(item, this.offering);
    });
    this.allProfiles = this.profiles;
  };

  setOffering = (offeringData: Offering, isNational: boolean) => {
    this.offering = offeringData;

    if (isNational && this.offering.status === Status.NEW) {
      this.offering.status = Status.NO_RESPONSE;
    }

    this.estCarryOver = offeringData.estCarryOver ?? 0;
    this.totalForecastedSales = assignNumberValue(offeringData.totalForecastedSales, 0);
  };

  setParentOfferingId = (id: string) => {
    this.parentOfferingId = id;
  };

  setEstCarryOver = (carryOver: number) => {
    this.estCarryOver = carryOver;
  };

  setTotalForecastedSales = (totalFsv: number) => {
    this.totalForecastedSales = totalFsv;
  };

  updateOfferingRev = (offeringId: string, rev: string) => {
    if (offeringId === this.offering.id) {
      this.offering = { ...this.offering, rev };
    }
  };

  updateAllRgnlAltRevs = (seriesProfiles?: ForecastedSalesResponse[]) => {
    if (seriesProfiles) {
      seriesProfiles.forEach(spRes => {
        this.allProfiles.forEach(sp => {
          if (spRes.seriesProfileId === sp.id) {
            spRes.rgnlAlts?.forEach(raRes => {
              const foundRa = sp.rgnlAlts.find(ra => ra.id === raRes?.rgnlAltId);
              if (foundRa && raRes) {
                foundRa.rev = raRes.rgnlAltRev;
              }
            });
          }
        });
      });
    }
  };

  setSETContestNumbersByType = (data: SETContestNumbersByType[]) => {
    this.contestNumbersByType = buildContestNumbers(data, this.offering.id, false, this.profiles);
  };

  addContestNumber = (contestNumberObj: SETContestNumbersByType, offeringId: string) => {
    const copy = this.contestNumbersByType.slice();
    const obj = copy.filter(type => type.optionType === contestNumberObj.optionType)[0];

    if (obj && obj.numbers) {
      const newNumber = new SETContestNumber({
        optionTypeName: obj.numbers[0]?.optionTypeName,
        offeringId,
      });
      obj.numbers = [...obj.numbers, newNumber];

      this.contestNumbersByType = copy;
    }
  };

  deleteContestNumber = (contestNumber: SETContestNumber) => {
    const copy = this.contestNumbersByType.slice();
    const obj = copy.filter(type => type.optionType === contestNumber.optionTypeName)[0];
    const ind = obj.numbers.indexOf(contestNumber);
    obj.numbers.splice(ind, 1);
    this.contestNumbersByType = copy;
  };

  removeSeriesProfile = (id: string) => {
    const spIndex = this.profiles.findIndex(sp => sp.id === id);

    if (spIndex !== -1) {
      this.profiles.splice(spIndex, 1);
    }
  };

  confirmAllSeries = (rgnAlts: RgnlAlt[]) => {
    this.allProfiles.forEach(profile => {
      profile.rgnlAlts.forEach(rgnAlt => {
        rgnAlts?.forEach(confirmedRgnAlt => {
          if (confirmedRgnAlt.id === rgnAlt.id) {
            rgnAlt.isSeriesConfirmed = confirmedRgnAlt.isSeriesConfirmed;
            rgnAlt.updateRev(confirmedRgnAlt.rev);
            profile.isConfirmedSeries = confirmedRgnAlt.isSeriesConfirmed;
          }
        });

        // If user click on the pencil CTA on confirmed view both of these fields are set to false
        if (profile.hasConfirmedRgnAlt()) {
          profile.isConfirmedView = true;
          profile.isConfirmedSeries = true;
        }
      });
    });
  };

  unconfirmChangedFsvProfiles = (seriesProfileForecastedSales: SeriesProfileForecastedSales[]) => {
    this.profiles.forEach(profile => {
      const touchedProfile = seriesProfileForecastedSales.find(fsv => fsv.seriesProfileId === profile.id);
      const selectedRa = profile.getSelectedRgnAlt();
      if (touchedProfile && selectedRa && selectedRa.fsv !== touchedProfile.forecastedSales) {
        selectedRa.fsv = touchedProfile.forecastedSales;
        profile.isConfirmedSeries = false;
        profile.isConfirmedView = false;
      }
    });
  };

  filterProfilesByStatus = () => {
    this.profiles = this.allProfiles;

    if (this.dropdownValue.value === DEFAULT_DROPDOWN_VALUE) {
      this.profiles = this.allProfiles;
      return;
    }

    this.profiles = this.profiles.filter(profile => profile.getSelectedRgnAlt()?.status === this.dropdownValue.value);
  };

  setShowAllOffers = (showAllOffers: boolean) => {
    this.profiles = this.allProfiles;
    this.showAllOffers = showAllOffers;
  };

  selectDropdownOption = (dropdownOption: DropdownItem) => {
    this.dropdownValue = dropdownOption as typeof this.dropdownValue;
    this.filterProfilesByStatus();
  };

  updateSummaryOfferingStatus = (status: string) => {
    this.offering.status = status;
  };
}

export default SummaryStore;
