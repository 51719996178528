import clsx from 'clsx';
import { Button, formatAccounting, OATIcon, Table, TableContainer, TableTitle } from 'oat-common-ui';
import useStores from '../../../../stores/useStores';
import { assignNumberValue } from '../../../../utils/assignValue';
import ProfileModel from '../../models/ProfileModel';
import RgnlAltModel from '../../models/RgnlAltModel';
import headerColumns from '../RgnlAltSection/headerColumns';
import OffersTableRow from './OffersTableRow';
import styles from './styles.module.scss';

interface ColumnProps {
  header: string;
  width?: number;
}

type Props = {
  profile: ProfileModel;
  rgnlAlt: RgnlAltModel;
  selected?: boolean;
  onSelect: (template: RgnlAltModel) => void;
};

const OffersTable = ({ profile, rgnlAlt, selected, onSelect }: Props) => {
  const { offeringCostsStore } = useStores();

  const handleOnSelect = () => {
    onSelect(rgnlAlt);
  };

  const TitleRow = () => {
    const raCosts = offeringCostsStore.raCosts[rgnlAlt.id];
    const balance = assignNumberValue(raCosts?.raEarnings) - assignNumberValue(raCosts?.raCost);

    return (
      <TableTitle>
        <span className={clsx([styles.rgnlName, { [styles.priorSelectedName]: rgnlAlt.isSelectedPrior }])}>
          {rgnlAlt.name} {rgnlAlt.isSelectedPrior && <OATIcon className={styles.icon} icon="checkmark" />}
        </span>

        {!selected && (
          <div className={styles.rightSide}>
            <span className={styles.bold}>Est Series Balance {formatAccounting(balance, true)}</span>{' '}
            <Button onClick={handleOnSelect} variant="primary">
              Select
            </Button>
          </div>
        )}
      </TableTitle>
    );
  };

  const HeaderRow = () => {
    return (
      <thead>
        <tr>
          {headerColumns.map((col: ColumnProps) => {
            const style = col.width ? { width: col.width } : undefined;
            const key = `${rgnlAlt.id}-${col.header}-hdr-btn`;

            return (
              <th key={key} style={style}>
                <>{col.header}</>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  return (
    <div className={styles.offersTable}>
      <TableContainer>
        <TitleRow />
        <Table>
          <HeaderRow />
          <OffersTableRow profile={profile} rgnlAlt={rgnlAlt} />
        </Table>
      </TableContainer>
    </div>
  );
};

export default OffersTable;
