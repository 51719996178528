import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminConstants, AdminModels, getDefaultTfsShare } from 'oat-admin-common';
import { BlockInputLabel, Checkbox, FinalSelectionFlow, InlineInputLabel, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import mapVehicleDataFromAccordion from '../../../../../components/Accordion/utils/mapVehicleDataFromAccordion';
import BoxSelector from '../../../../../components/BoxSelector';
import { FEATURE_OR_2477, FEATURE_OR_4154 } from '../../../../../constants/global';
import { useCreateLeaseOfferMutation } from '../../../../../gql/generated';
import useOfferEligibility from '../../../../../hooks/useOfferEligibility';
import useUrlParams from '../../../../../hooks/useUrlParams';
import useStores from '../../../../../stores/useStores';
import getDisabledVehicles from '../../../../../utils/getDisabledVehicles';
import getInclExclLabel from '../../../../../utils/getInclExclLabel';
import useGetTfsLabel from '../../../../../utils/useGetTfsLabel';
import useUserInfo from '../../../../../utils/useUserInfo';
import EnhCostShareModal from '../../../../ProgramDetails/components/EnhCostShareModal';
import EnhCostShareTooltip from '../../../../ProgramDetails/components/EnhCostShareTooltip';
import ExclusionInclusion from '../../../../ProgramDetails/components/ExclusionInclusion';
import useCreateLeaseSave from '../../useCreateLeaseSave';
import styles from '../styles.module.scss';
import getLeaseOfferFromRgnlAlt from '../utils/getLeaseOfferFromRgnlAlt';
import transformChangeVinPayload from '../utils/transformChangeVinPayload';
import transformCreateLeasePayload from '../utils/transformCreateLeasePayload';

const { OPTION_TYPE_NAMES } = AdminConstants;

const FinalSelection = () => {
  const {
    createLeaseStore: { dealerGross, rgnlAlt, offering, advertisedData, termsList, isChangeVin, tiersList, vehicles, seriesProfile, setInitVehicles },
    createLeaseStore,
    seriesMappingStore,
    rcfsResidualsStore: { residuals },
    programDetailsStore: { enhancedCostShares },
  } = useStores();
  const history = useHistory();
  const { error } = useToast();
  const { region, period, regalt, profile, offerId, offerTerm, example } = useUrlParams();
  const flow = advertisedData.getFieldObj();
  const { saveLease, processLeaseOfferForPayload } = useCreateLeaseSave();
  const [showEnhCostShareModal, setShowEnhCostShareModal] = useState(false);
  const [showEnhTfsCostShareModal, setShowEnhTfsCostShareModal] = useState(false);

  const detail = advertisedData.tab === 1 ? advertisedData.selectTrimFields : advertisedData.enterVinFields;
  const { selectedTermsList, terms, selectedTiers, blended, editVehicles, vehiclesAccordion, isInclusions, isSpecialEdition } = detail;
  const { isLexusUser } = useUserInfo();
  const { handleEnhCostShare, handleEnhTfsCostShare } = useOfferEligibility();
  const [excludedList, includedList] = mapVehicleDataFromAccordion(vehiclesAccordion.items, vehicles, !isInclusions);
  const [createLeaseOffer] = useCreateLeaseOfferMutation();
  const tfsLabel = useGetTfsLabel();
  let isEnhCostShareAccepted: boolean | undefined = undefined;
  let isEnhTfsCostShareAccepted: boolean | undefined = undefined;

  const handleResetAll = () => {
    advertisedData.resetAll();
    setInitVehicles(vehicles, seriesMappingStore.seriesMapping, getDisabledVehicles(vehicles));
  };

  const onResetIncExcl = () => {
    vehiclesAccordion.toggleAll(false);
  };

  // Needs to handle edit offer for changing VIN of existing offer
  const handleCreateOffer = () => {
    (async () => {
      try {
        if (isChangeVin) {
          const { leaseOffer, leaseDetails, nationalLeaseDetails, leaseExample } = getLeaseOfferFromRgnlAlt(rgnlAlt, offerId, offerTerm, decodeURI(example));

          const { showModal, enhancedCostShare } = handleEnhCostShare(
            {
              startDate: leaseDetails.startDate,
              endDate: leaseDetails.endDate,
              vehicles: includedList.filter(vehicle => vehicle.isInclusion),
              optionTypeName: OPTION_TYPE_NAMES.LEASE,
              regionCode: region,
              states: [],
              tiers: selectedTiers,
              terms: selectedTermsList,
            },
            leaseOffer,
          );

          const { showModal: showTfsModal, enhancedTfsCostShare } = handleEnhTfsCostShare(
            {
              startDate: offering.startDate,
              endDate: offering.endDate,
              optionTypeName: OPTION_TYPE_NAMES.LEASE,
              seriesProfileId: seriesProfile.id,
            },
            leaseOffer,
          );

          if (showModal && isEnhCostShareAccepted === undefined) {
            setShowEnhCostShareModal(true);
            return;
          }

          if (showTfsModal && isEnhTfsCostShareAccepted === undefined && leaseOffer.isEnhTfsCostShareAccepted === null) {
            setShowEnhTfsCostShareModal(true);
            return;
          }

          const foundEnhancedCostShare = isEnhCostShareAccepted ? enhancedCostShare : undefined;
          const foundEnhancedTfsCostShare = isEnhTfsCostShareAccepted ? enhancedTfsCostShare : undefined;

          const payload = transformChangeVinPayload(
            createLeaseStore,
            detail,
            residuals,
            leaseDetails,
            nationalLeaseDetails,
            leaseExample,
            getDefaultTfsShare(OPTION_TYPE_NAMES.LEASE, region, leaseDetails.highTerm, !!offering.useOldCostShareForLexus),
            foundEnhancedCostShare as AdminModels.EnhancedCostShare.EnhancedLeaseCostShare,
            foundEnhancedTfsCostShare,
          );
          const leaseOfferPayload = processLeaseOfferForPayload({
            leaseOffer,
            enhancedCostShare: foundEnhancedCostShare,
            enhacedTfsCostShare: foundEnhancedTfsCostShare,
            isEnhCostShareAccepted,
            isEnhTfsCostShareAccepted,
          });
          await saveLease({ ...leaseOfferPayload }, leaseOffer.isSpecialEdition, [payload], excludedList, includedList);
        } else {
          const { showModal, enhancedCostShare } = handleEnhCostShare({
            startDate: offering.startDate,
            endDate: offering.endDate,
            vehicles: includedList.filter(vehicle => vehicle.isInclusion),
            optionTypeName: OPTION_TYPE_NAMES.LEASE,
            regionCode: region,
            states: [],
            tiers: selectedTiers,
            terms: selectedTermsList,
          });

          const { showModal: showTfsModal, enhancedTfsCostShare } = handleEnhTfsCostShare({
            startDate: offering.startDate,
            endDate: offering.endDate,
            optionTypeName: OPTION_TYPE_NAMES.LEASE,
            seriesProfileId: seriesProfile.id,
          });

          if (showModal && isEnhCostShareAccepted === undefined) {
            setShowEnhCostShareModal(true);
            return;
          }

          if (showTfsModal && isEnhTfsCostShareAccepted === undefined) {
            setShowEnhTfsCostShareModal(true);
            return;
          }

          const foundEnhancedCostShare = isEnhCostShareAccepted ? enhancedCostShare : undefined;
          const foundEnhancedTfsCostShare = isEnhTfsCostShareAccepted ? enhancedTfsCostShare : undefined;

          const payload = transformCreateLeasePayload(
            createLeaseStore,
            detail,
            regalt,
            residuals,
            dealerGross,
            foundEnhancedCostShare as AdminModels.EnhancedCostShare.EnhancedLeaseCostShare,
            foundEnhancedTfsCostShare as AdminModels.EnhancedTfsCostShare.EnhancedTfsCostShare,
            isEnhCostShareAccepted,
            isEnhTfsCostShareAccepted,
          );
          const res = await trackPromise(
            createLeaseOffer({
              variables: {
                input: { ...payload, isEligibleForEnhTfsCostShare: false },
              },
            }),
          );
          if (res.data?.createLeaseOffer.success) {
            const anchor = 'lease-' + res.data?.createLeaseOffer.offer?.id;
            setShowEnhCostShareModal(false);
            setShowEnhTfsCostShareModal(false);
            history.push(`/details/region/${region}/period/${period}/profile/${profile}/regalt/${regalt}?scrollTo=${anchor}`);
          }
        }
      } catch (e) {
        setShowEnhCostShareModal(false);
        setShowEnhTfsCostShareModal(false);
        error((e as Error).message);
      }
    })();
  };
  const showEnhCostShareTooltip = FEATURE_OR_2477 && enhancedCostShares.find(e => e.optionTypeName === OPTION_TYPE_NAMES.LEASE);
  return (
    <>
      {advertisedData.showFinalSelectionFlow() && (
        <FinalSelectionFlow
          vin={flow.vin?.vin ?? ''}
          onVinClick={advertisedData.vinSelection}
          onSubmit={handleCreateOffer}
          onResetAll={handleResetAll}
          onResetIncExcl={onResetIncExcl}
          inclExclLabel={getInclExclLabel(isInclusions)}
          showEnhCostShareTooltip={showEnhCostShareTooltip}
          enhanceCostShareToolTip={<EnhCostShareTooltip offerId={offerId} optionTypeName={OPTION_TYPE_NAMES.LEASE} className={styles.leaseTooltip} />}
          isRegionalApp={true}
        >
          {!advertisedData.hideTiersTerms && (
            <>
              {/* Advertised selector */}
              <BlockInputLabel className={clsx(styles.inputLabel, styles.advTermselecctor)} label="Terms">
                <div className={styles.boxWrapper}>
                  <BoxSelector id="advTermsSelector" options={termsList} selected={selectedTermsList} setSelected={item => advertisedData.setSelectedTerms(item)} />
                </div>

                {/* Term selector */}
                <div className={styles.termOptionContainer}>
                  {terms.map(term => (
                    <BlockInputLabel label={`Term ${term.term}`} className={styles.inputLabel} key={term.term}>
                      <input
                        type="checkbox"
                        checked={term.isAdvertised}
                        name={`term-${term.term}`}
                        onChange={e => {
                          term.isAdvertised = e.target.checked;
                        }}
                      />
                      <span className={styles.advLabel}>Advertised</span>
                    </BlockInputLabel>
                  ))}
                </div>
              </BlockInputLabel>

              {/* Tier selector */}
              <BlockInputLabel label="Tiers" className={clsx(styles.tiers, styles.inputLabel)}>
                <BoxSelector
                  id="advTiersSelector"
                  options={tiersList}
                  selected={selectedTiers}
                  setSelected={item => {
                    advertisedData.setSelectedTiers(detail.blended ? tiersList : item, detail.blended);
                  }}
                  setLastSelected={item => advertisedData.selectTier(item)}
                />
              </BlockInputLabel>

              {/* Blended/By Tier options */}
              <div className={styles.optionType}>
                <BlockInputLabel className={styles.inputLabel} label="Option Type">
                  <input type="radio" id="advBlended" checked={blended} onChange={() => advertisedData.setOption(true)} />
                  <label htmlFor="blended">Blended</label>
                  <input type="radio" id="advByTier" checked={!blended} onChange={() => advertisedData.setOption(false)} />
                  <label htmlFor="byTier">By Tier</label>
                </BlockInputLabel>
              </div>

              {/* Special Edition */}
              {isLexusUser() && (
                <div className={styles.specialEditionWrapper}>
                  <InlineInputLabel label="Special Edition" className={styles.label}>
                    <Checkbox id="special-edition-checkbox" onChange={e => advertisedData.updateIsSpecialEdition(e.target.checked)} isChecked={isSpecialEdition} />
                  </InlineInputLabel>
                </div>
              )}
            </>
          )}

          {/* Vehicles Accordion */}
          <ExclusionInclusion
            isInclusions={isInclusions}
            editVehicles={editVehicles}
            toggleIsInclusions={advertisedData.toggleIsInclusions}
            vehiclesAccordion={vehiclesAccordion}
            setEditVehicles={advertisedData.setEditVehicles}
          />
        </FinalSelectionFlow>
      )}

      {FEATURE_OR_2477 && showEnhCostShareModal && (
        <EnhCostShareModal
          onButtonPress={isApplied => {
            isEnhCostShareAccepted = isApplied;
            handleCreateOffer();
          }}
        />
      )}
      {FEATURE_OR_4154 && showEnhTfsCostShareModal && (
        <EnhCostShareModal
          title={`Enhanced ${tfsLabel} Cost Share Available`}
          text={`Offer is eligible for National Enhanced ${tfsLabel} Cost Share. Do you want to apply? test`}
          onButtonPress={isApplied => {
            isEnhTfsCostShareAccepted = isApplied;
            handleCreateOffer();
          }}
        />
      )}
    </>
  );
};

export default observer(FinalSelection);
