import { uuidv4 } from 'oat-common-ui';

class AccordionItemModel {
  uid = uuidv4();
  label = '';
  value = '';
  description = '';
  expand = false;
  checked = false;
  disabled = false;
  isPartial = false;
  level = 1;
  items: AccordionItemModel[] = [];

  constructor(label = '', value = '') {
    this.label = label;
    this.value = value;
  }

  toggleExpand = () => {
    this.expand = !this.expand;
  };

  setChecked = (checked: boolean) => {
    this.checked = checked;
  };
}

export default AccordionItemModel;
