import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminConstants, AdminModels } from 'oat-admin-common';
import {
  BlockInputLabel,
  Button,
  Checkbox,
  Dropdown,
  DropdownItem,
  InlineInputLabel,
  Input,
  SeriesCodeToNameMap,
  dateFormatISOString,
  dateStringToDate,
  useToast,
} from 'oat-common-ui';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import mapVehicleDataFromAccordion from '../../../../components/Accordion/utils/mapVehicleDataFromAccordion';
import BoxSelector from '../../../../components/BoxSelector';
import NumberInput from '../../../../components/NumberInput';
import StartEndDate from '../../../../components/StartEndDate';
import { FEATURE_OR_2477, FEATURE_OR_4154 } from '../../../../constants/global';
import { useCreateLeaseOfferMutation } from '../../../../gql/generated';
import useOfferEligibility from '../../../../hooks/useOfferEligibility';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import useGetTfsLabel from '../../../../utils/useGetTfsLabel';
import EnhCostShareModal from '../../../ProgramDetails/components/EnhCostShareModal';
import EnhCostShareTooltip from '../../../ProgramDetails/components/EnhCostShareTooltip';
import ExclusionInclusion from '../../../ProgramDetails/components/ExclusionInclusion';
import OfferBody, { OfferBodyLeft, OfferBodyRight } from '../../../ProgramDetails/components/OfferBody';
import CreateOfferCta from '../../CreateOfferCta';
import getLeaseOfferFromRgnlAlt from '../CreateAdLease/utils/getLeaseOfferFromRgnlAlt';
import CreateLeaseOfferDetails from '../CreateLeaseOfferDetails';
import commonStyles from '../common.module.scss';
import useCreateLeaseSave from '../useCreateLeaseSave';
import styles from './styles.module.scss';
import transformCreateGSTLeasePayload from './utils/transformCreateGSTLeasePayload';
import transformUpdateGSTLeasePayload from './utils/transformUpdateGSTLeasePayload';
import getAnnualMileagesList from '../../../../utils/getAnnualMileagesList';

const { OPTION_TYPE_NAMES } = AdminConstants;

const CreateAdGstLease = () => {
  const {
    createLeaseStore: { offering, rgnlAlt, termsList, tiersList, compatibileOffers, nationalRcf, vehicles, nonAdvertisedData, isChangeVin, setTab, seriesProfile },
    programDetailsStore: { enhancedCostShares },
    userInfoStore: { userInfo },
  } = useStores();
  const { region, period, profile, regalt, offerId, offerTerm } = useUrlParams();
  const { error } = useToast();
  const history = useHistory();
  const { saveLease, processLeaseOfferForPayload } = useCreateLeaseSave();
  const [createLeaseOffer] = useCreateLeaseOfferMutation();
  const [excludedList, includedList] = mapVehicleDataFromAccordion(nonAdvertisedData.fields.vehiclesAccordion.items, vehicles, !nonAdvertisedData.fields.isInclusions);

  const [showEnhCostShareModal, setShowEnhCostShareModal] = useState(false);
  const [showEnhTfsCostShareModal, setShowEnhTfsCostShareModal] = useState(false);
  const [isEnhCostShareAccepted, setIsEnhCostShareAccepted] = useState<boolean | undefined>();
  const [isEnhTfsCostShareAccepted, setIsEnhTfsCostShareAccepted] = useState<boolean | undefined>(undefined);
  const containTerm30OrLower = nonAdvertisedData.fields.selectedTermsList.some(term => Number(term) <= 30);
  const mileages = getAnnualMileagesList(
    userInfo.brand,
    region,
    !containTerm30OrLower,
    nonAdvertisedData.fields.series.toLowerCase().includes(SeriesCodeToNameMap.supra.toLowerCase()),
  );

  const tfsLabel = useGetTfsLabel();

  const { handleEnhCostShare, handleEnhTfsCostShare } = useOfferEligibility();

  const modelOptions = includedList.map(item => {
    nonAdvertisedData.setVehicleDescription(includedList);
    return { label: item.vehicleDescription, value: item.modelCode };
  });

  const handleModelOnChange = (item: DropdownItem) => {
    nonAdvertisedData.updateField('vehicleDescription', item.label as string);
    nonAdvertisedData.updateField('modelCode', item.value);
  };

  const handleToNonAdvertised = () => {
    return () => {
      setTab(2);
    };
  };

  const handleSaveOffer = async (isEnhCostShareAccepted?: boolean, isEnhTfsCostShareAccepted?: boolean) => {
    try {
      if (isChangeVin) {
        const { leaseOffer, leaseDetails } = getLeaseOfferFromRgnlAlt(rgnlAlt, offerId, offerTerm);

        const { showModal, enhancedCostShare } = handleEnhCostShare(
          {
            vehicles: includedList.filter(vehicle => vehicle.isInclusion),
            startDate: offering.startDate,
            endDate: offering.endDate,
            optionTypeName: AdminConstants.OPTION_TYPE_NAMES.LEASE,
            regionCode: region,
            states: [],
            tiers: nonAdvertisedData.fields.selectedTiers,
            terms: nonAdvertisedData.fields.selectedTermsList,
          },
          leaseOffer,
        );

        const { showModal: showTfsModal, enhancedTfsCostShare } = handleEnhTfsCostShare(
          {
            startDate: offering.startDate,
            endDate: offering.endDate,
            optionTypeName: AdminConstants.OPTION_TYPE_NAMES.LEASE,
            seriesProfileId: seriesProfile.id,
          },
          leaseOffer,
        );

        if (showModal && isEnhCostShareAccepted === undefined) {
          setShowEnhCostShareModal(true);
          return;
        }

        if (showTfsModal && isEnhTfsCostShareAccepted === undefined && leaseOffer.isStandalone && leaseOffer.isEnhTfsCostShareAccepted === null) {
          setShowEnhTfsCostShareModal(true);
          return;
        }

        // If we are changing offer with enhancedTfsCost already set do we need to getEnhancedCostShareById?
        const foundEnhancedCostShare = isEnhCostShareAccepted ? enhancedCostShare : undefined;
        const foundEnhancedTfsCostShare = isEnhCostShareAccepted && leaseOffer.isStandalone ? enhancedTfsCostShare : undefined;

        const payload = transformUpdateGSTLeasePayload(
          nonAdvertisedData,
          leaseDetails,
          foundEnhancedCostShare as AdminModels.EnhancedCostShare.EnhancedLeaseCostShare,
          foundEnhancedTfsCostShare,
        );

        await saveLease(
          {
            ...processLeaseOfferForPayload({ leaseOffer }),
          },
          nonAdvertisedData.fields.isSpecialEdition,
          [payload],
          excludedList,
          includedList,
        );
      } else {
        const { showModal, enhancedCostShare } = handleEnhCostShare({
          vehicles: includedList.filter(vehicle => vehicle.isInclusion),
          startDate: nonAdvertisedData.fields.startDate,
          endDate: nonAdvertisedData.fields.endDate,
          optionTypeName: AdminConstants.OPTION_TYPE_NAMES.LEASE,
          regionCode: region,
          states: [],
          tiers: nonAdvertisedData.fields.selectedTiers,
          terms: nonAdvertisedData.fields.selectedTermsList,
        });

        const { showModal: showTfsModal, enhancedTfsCostShare } = handleEnhTfsCostShare({
          startDate: dateFormatISOString(nonAdvertisedData.fields.startDate) ?? offering.startDate,
          endDate: dateFormatISOString(nonAdvertisedData.fields.endDate) ?? offering.endDate,
          optionTypeName: AdminConstants.OPTION_TYPE_NAMES.LEASE,
          seriesProfileId: seriesProfile.id,
        });

        if (showModal && isEnhCostShareAccepted === undefined) {
          setShowEnhCostShareModal(true);
          return;
        }

        if (showTfsModal && isEnhTfsCostShareAccepted === undefined) {
          setShowEnhTfsCostShareModal(true);
          return;
        }

        const foundEnhancedCostShare = isEnhCostShareAccepted ? enhancedCostShare : undefined;
        const foundEnhancedTfsCostShare = isEnhTfsCostShareAccepted ? enhancedTfsCostShare : undefined;

        const gstPayload = transformCreateGSTLeasePayload(
          nonAdvertisedData,
          regalt,
          excludedList,
          includedList,
          compatibileOffers,
          foundEnhancedCostShare as AdminModels.EnhancedCostShare.EnhancedLeaseCostShare,
          foundEnhancedTfsCostShare,
          showEnhCostShareModal ? isEnhCostShareAccepted : null,
          showEnhTfsCostShareModal ? isEnhTfsCostShareAccepted : null,
        );

        const res = await trackPromise(
          createLeaseOffer({
            variables: {
              input: {
                ...gstPayload,
              },
            },
          }),
        );
        if (res.data?.createLeaseOffer.success) {
          const anchor = 'lease-' + res.data?.createLeaseOffer.offer?.id;
          history.push(`/details/region/${region}/period/${period}/profile/${profile}/regalt/${regalt}?scrollTo=${anchor}`);
        }
      }
    } catch (e) {
      setShowEnhCostShareModal(false);
      setShowEnhTfsCostShareModal(false);
      error((e as Error).message);
    }
  };

  const showEnhCostShareTooltip = FEATURE_OR_2477 && enhancedCostShares.find(e => e.optionTypeName === OPTION_TYPE_NAMES.LEASE);

  return (
    <>
      <OfferBody>
        <OfferBodyLeft className={styles.leftSection}>
          <CreateLeaseOfferDetails />
        </OfferBodyLeft>
        <OfferBodyRight className={styles.rightSection}>
          <div className={styles.offerHeader}>
            <span className={styles.gstAdvHeader}>
              <Button className={commonStyles.offerHeaderLink} onClick={handleToNonAdvertised()} variant="text">
                Make Non-Advertised
              </Button>
            </span>
          </div>
          <div className={styles.nonAdLeaseContent}>
            <BlockInputLabel className={styles.inputLabel} label="Terms">
              <BoxSelector
                id="getTermsSelector"
                options={termsList}
                disabled={isChangeVin}
                selected={nonAdvertisedData.fields.selectedTermsList}
                setSelected={item => nonAdvertisedData.setSelectedTerms(item)}
              />
            </BlockInputLabel>
            <BlockInputLabel label="Tiers" className={cx(styles.tiers, styles.inputLabel)}>
              <BoxSelector
                id="gstTiersSelector"
                options={tiersList}
                disabled={isChangeVin}
                selected={nonAdvertisedData.fields.selectedTiers}
                setSelected={() => nonAdvertisedData.setSelectedTiers(tiersList)}
                setLastSelected={item => nonAdvertisedData.updateField('selectedTier', item)}
              />
            </BlockInputLabel>
            <div className={styles.dateMileage}>
              <StartEndDate
                labelClass={styles.inputLabel}
                dateWrapperClass={styles.startEndDate}
                minDate={dateStringToDate(offering.startDate)}
                maxDate={dateStringToDate(offering.endDate)}
                startDate={nonAdvertisedData.fields.startDate}
                endDate={nonAdvertisedData.fields.endDate}
                setStartDate={value => nonAdvertisedData.updateField('startDate', value as Date)}
                setEndDate={value => nonAdvertisedData.updateField('endDate', value as Date)}
              />
              <BlockInputLabel label="Mileage" className={cx(styles.mileage, styles.inputLabel)}>
                <Dropdown
                  id="gstMileage"
                  value={nonAdvertisedData.fields.selectedMileage}
                  options={mileages.mileages}
                  onChange={item => nonAdvertisedData.updateField('selectedMileage', item)}
                />
              </BlockInputLabel>
            </div>
            <div className={styles.costsWrapper}>
              <BlockInputLabel className={styles.inputLabel} label="Regional Subv. RCF">
                <div className={styles.percentInputWrapper}>
                  <NumberInput
                    rcf
                    error={nonAdvertisedData.hasRcfError}
                    percentageSign
                    value={nonAdvertisedData.fields.rcf}
                    onValueChange={value => nonAdvertisedData.updateField('rcf', value.value)}
                  />
                </div>
                <p className={styles.defaultValueLabel}>National RCF {nationalRcf}</p>
              </BlockInputLabel>
              <BlockInputLabel className={styles.inputLabel} label="PNV">
                <NumberInput
                  value={nonAdvertisedData.fields.pnv}
                  dollarSign
                  dollar
                  allowNegative={false}
                  error={nonAdvertisedData.hasPnvError}
                  className={cx(styles.pnvInput, nonAdvertisedData.hasPnvError && styles.inputError)}
                  onValueChange={value => nonAdvertisedData.updateField('pnv', value.value)}
                  units
                />
              </BlockInputLabel>
            </div>
            <div className={styles.costsWrapper}>
              <BlockInputLabel className={styles.inputLabel} label="Target Payment">
                <NumberInput
                  wholeNumber
                  dollarSign
                  error={nonAdvertisedData.hasTargetPaymentError}
                  value={nonAdvertisedData.fields.targetPayment}
                  onValueChange={value => nonAdvertisedData.updateField('targetPayment', value.value)}
                  units
                />
              </BlockInputLabel>
              <BlockInputLabel className={styles.inputLabel} label="Due at Signing">
                <NumberInput
                  value={nonAdvertisedData.fields.dueAtSigning}
                  wholeNumber
                  dollarSign
                  error={nonAdvertisedData.hasDueAtSigningError}
                  onValueChange={value => nonAdvertisedData.updateField('dueAtSigning', value.value)}
                  units
                />
              </BlockInputLabel>
              <BlockInputLabel className={styles.inputLabel} label="Net Capitalized Cost">
                <NumberInput
                  wholeNumber
                  dollarSign
                  error={nonAdvertisedData.hasNetCapCostError}
                  value={nonAdvertisedData.fields.netCapCost}
                  onValueChange={value => nonAdvertisedData.updateField('netCapCost', value.value)}
                  units
                />
              </BlockInputLabel>
            </div>
            <div className={styles.costsWrapper}>
              <BlockInputLabel className={styles.inputLabel} label="Total MSRP">
                <NumberInput
                  wholeNumber
                  dollarSign
                  error={nonAdvertisedData.hasTotalMsrpError}
                  value={nonAdvertisedData.fields.totalMsrp}
                  onValueChange={value => nonAdvertisedData.updateField('totalMsrp', value.value)}
                  units
                />
              </BlockInputLabel>
              <BlockInputLabel className={styles.inputLabel} label="Subvention Cash">
                <NumberInput
                  value={nonAdvertisedData.fields.subventionCash}
                  wholeNumber
                  dollarSign
                  error={nonAdvertisedData.hasSubventionCashError}
                  onValueChange={value => nonAdvertisedData.updateField('subventionCash', value.value)}
                  units
                />
              </BlockInputLabel>
              <BlockInputLabel className={styles.inputLabel} label="Bonus Cash">
                <NumberInput
                  value={nonAdvertisedData.fields.bonusCash}
                  wholeNumber
                  dollarSign
                  onValueChange={value => nonAdvertisedData.updateField('bonusCash', value.value)}
                  units
                />
              </BlockInputLabel>
            </div>
            <div className={styles.costsWrapper}>
              <BlockInputLabel className={styles.inputLabel} label="Residual Value Amount">
                <NumberInput
                  wholeNumber
                  dollarSign
                  error={nonAdvertisedData.hasResidualValueAmountError}
                  value={nonAdvertisedData.fields.residualValueAmount}
                  onValueChange={value => nonAdvertisedData.updateField('residualValueAmount', value.value)}
                  units
                />
              </BlockInputLabel>
              <BlockInputLabel className={styles.inputLabel} label="Down Payment">
                <NumberInput
                  value={nonAdvertisedData.fields.downPayment}
                  wholeNumber
                  dollarSign
                  error={nonAdvertisedData.hasDownPaymentError}
                  onValueChange={value => nonAdvertisedData.updateField('downPayment', value.value)}
                  units
                />
              </BlockInputLabel>
              <BlockInputLabel className={styles.inputLabel} label="Gross Cap Cost">
                <NumberInput
                  wholeNumber
                  dollarSign
                  error={nonAdvertisedData.hasGrossCapCostError}
                  value={nonAdvertisedData.fields.grossCapCost}
                  onValueChange={value => nonAdvertisedData.updateField('grossCapCost', value.value)}
                  units
                />
              </BlockInputLabel>
            </div>

            {/* Blended/By Tier options */}
            <div className={styles.optionType}>
              <BlockInputLabel className={styles.inputLabel} label="Option Type">
                <input type="radio" id="gstBlended" checked={nonAdvertisedData.fields.isBlended} onChange={() => nonAdvertisedData.updateField('isBlended', true)} />
                <label htmlFor="blended">Blended</label>
                <input type="radio" id="gstByTier" checked={!nonAdvertisedData.fields.isBlended} onChange={() => nonAdvertisedData.updateField('isBlended', false)} />
                <label htmlFor="byTier">By Tier</label>
              </BlockInputLabel>
            </div>

            {/* Model selection */}
            <BlockInputLabel label="Model" className={cx(styles.inputLabel, styles.models)}>
              <Dropdown
                id="gstModel"
                value={nonAdvertisedData.fields.vehicleDescription}
                options={modelOptions}
                onChange={item => handleModelOnChange(item)}
                error={!nonAdvertisedData.fields.vehicleDescription}
              />
            </BlockInputLabel>

            {/* Special Edition */}
            <div className={styles.specialEdition}>
              <InlineInputLabel label="Special Edition" className={styles.label}>
                <Checkbox
                  id="special-edition-checkbox"
                  onChange={e => nonAdvertisedData.updateField('isSpecialEdition', e.target.checked)}
                  isChecked={nonAdvertisedData.fields.isSpecialEdition}
                  className={styles.checkbox}
                />
              </InlineInputLabel>
            </div>

            {/* Vehicles Accordion */}
            <ExclusionInclusion
              isInclusions={nonAdvertisedData.fields.isInclusions}
              editVehicles={nonAdvertisedData.editVehicles}
              toggleIsInclusions={nonAdvertisedData.toggleIsInclusions}
              vehiclesAccordion={nonAdvertisedData.fields.vehiclesAccordion}
              setEditVehicles={nonAdvertisedData.setEditVehicles}
            />

            {/* Notes */}
            <BlockInputLabel label="Note" className={cx(styles.fullWidthInput, styles.inputLabel)}>
              <Input value={nonAdvertisedData.fields.note} onChange={e => nonAdvertisedData.updateField('note', e.currentTarget.value)} />
            </BlockInputLabel>
            <BlockInputLabel label="TDA Note" className={cx(styles.fullWidthInput, styles.inputLabel)}>
              <Input value={nonAdvertisedData.fields.tdaNote} onChange={e => nonAdvertisedData.updateField('tdaNote', e.currentTarget.value)} />
            </BlockInputLabel>

            {/* CTAs */}
            <div className={styles.footer}>
              {/* Enhanced Cost Share Tooltip */}
              {showEnhCostShareTooltip && <EnhCostShareTooltip offerId={offerId} optionTypeName={OPTION_TYPE_NAMES.LEASE} className={styles.leaseTooltip} />}

              <Button
                className={commonStyles.offerHeaderLink}
                onClick={() => {
                  nonAdvertisedData.fields.vehiclesAccordion.toggleAll(false);
                }}
              >{`Remove ${nonAdvertisedData.fields.isInclusions ? 'Inclusions' : 'Exclusions'}`}</Button>
              <CreateOfferCta className={styles.createOfferCta} label="Create Offer" onClick={handleSaveOffer} disabled={nonAdvertisedData.setAdvGstDisabled()} />
            </div>
          </div>
        </OfferBodyRight>
      </OfferBody>
      {FEATURE_OR_2477 && showEnhCostShareModal && (
        <EnhCostShareModal
          onButtonPress={isApplied => {
            setIsEnhCostShareAccepted(isApplied);
            handleSaveOffer(isApplied, isEnhTfsCostShareAccepted);
          }}
        />
      )}
      {FEATURE_OR_4154 && showEnhTfsCostShareModal && (
        <EnhCostShareModal
          title={`Enhanced ${tfsLabel} Cost Share Available`}
          text={`Offer is eligible for National Enhanced ${tfsLabel} Cost Share. Do you want to apply?`}
          onButtonPress={isApplied => {
            setIsEnhTfsCostShareAccepted(isApplied);
            handleSaveOffer(isEnhCostShareAccepted, isApplied);
          }}
        />
      )}
    </>
  );
};

export default observer(CreateAdGstLease);
