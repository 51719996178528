import { AdminConstants } from 'oat-admin-common';
import { dateFormatISOString, dateStringToDate } from 'oat-common-ui';
import { useState } from 'react';
import { CreateAprOfferInput, Offer, Offering, SaveAprOfferInput } from '../gql/generated';
import { UserInfo } from '../pages/ProgramDetails/components/AprCard';
import AprCardModel from '../pages/ProgramDetails/models/AprCardModel';
import useStores from '../stores/useStores';
import { EnhTfsCostShareOfferInput, HandleEnhTfsCostShareResponse, OfferEligibilityFields } from './useOfferEligibility';
import useUrlParams from './useUrlParams';

const { OPTION_TYPE_NAMES } = AdminConstants;

const useAprEnhTfsCostShareUtils = (
  aprCard: AprCardModel,
  handleEnhTfsCostShare: (offerInput: EnhTfsCostShareOfferInput, offer?: OfferEligibilityFields | undefined) => HandleEnhTfsCostShareResponse,
  offering: Offering,
) => {
  const {
    userInfoStore: { isGst, isSETUser, isLexus },
    rcfsResidualsStore: { stdAprRates: standardRates },
    programDetailsStore: {
      seriesProfile: { ncsRate },
    },
  } = useStores();
  const { profile } = useUrlParams();
  const userInfo: UserInfo = { isGst: isGst(), isSet: isSETUser(), isLexus: isLexus() };

  const [showEnhTfsCostShareModal, setShowEnhTfsCostShareModal] = useState(false);
  const [showRemoveTfsCostShareModal, setShowRemoveTfsCostShareModal] = useState(false);
  const [removeEnhTfsReason, setEnhTfsRemoveReason] = useState('');
  const [replaceExistingTfs, setReplaceExistingTfs] = useState(false);

  const handleCheckForEnhTfsCostShares = () => {
    const aprFirstTerm = aprCard.selectedTierModel?.terms[0];

    const offerPayload: EnhTfsCostShareOfferInput = {
      startDate: dateFormatISOString(aprFirstTerm?.fields.startDate),
      endDate: dateFormatISOString(aprFirstTerm?.fields.endDate),
      optionTypeName: OPTION_TYPE_NAMES.APR,
      seriesProfileId: profile,
    };
    const existingOfferPayload: OfferEligibilityFields = {
      isEligibleForEnhTfsCostShare: aprCard.isEligibleForEnhTfsCostShare,
      enhTfsCostShareId: aprCard.enhTfsCostShareId,
      isEnhTfsCostShareAccepted: aprCard.isEnhTfsCostShareAccepted,
    };

    const { showModal, eligibleOfferId, enhancedTfsCostShare, reason, isRemoving, isReplacing } = handleEnhTfsCostShare(offerPayload, existingOfferPayload);

    if (isReplacing) {
      setReplaceExistingTfs(isReplacing);
    }

    if (showModal && eligibleOfferId) {
      aprCard.setEnhTfsCostShareId(eligibleOfferId);
      aprCard.setIsEligibleForEnhTfsCostShare(!!eligibleOfferId);
      aprCard.setEnhTfsCostShare(enhancedTfsCostShare);

      if (aprCard.isEnhTfsCostShareAccepted === undefined || isReplacing) {
        setShowEnhTfsCostShareModal(true);
      }
    }

    if (isRemoving && reason) {
      setEnhTfsRemoveReason(reason);
      setShowRemoveTfsCostShareModal(true);
    }

    return { showModal, isRemoving, isReplacing };
  };

  const handleCancelOnAddEnhTfsCostShare = (isReplacing = false) => {
    if (isReplacing) {
      aprCard.setIsEnhCostShareAccepted(false);
      return;
    }

    aprCard.setIsEnhTfsCostShareAccepted(false);
    aprCard.setEnhTfsCostShareId(undefined);
    aprCard.setIsEligibleForEnhTfsCostShare(false);
    aprCard.setEnhTfsCostShare(undefined);

    setShowEnhTfsCostShareModal(false);
  };

  const handleUpdateTierTermsWithEnhTfsCostShare = (isEnhTfsCostShareAccepted: boolean | undefined, skipRecalc: boolean) => {
    const { enhTfsCostShare, tiersList, tierMap } = aprCard;

    for (const tier of tiersList) {
      if (!isEnhTfsCostShareAccepted || skipRecalc || isSETUser()) {
        break;
      }

      const tierTerm = tierMap.get(tier);

      if (tierTerm) {
        for (const term of tierTerm.terms) {
          if (enhTfsCostShare && !removeEnhTfsReason) {
            const newBuyRate = term.fields.buyRate - enhTfsCostShare.value;
            term.updateField('buyRate', newBuyRate);

            if (term.fields.rate > newBuyRate) {
              term.updateField('rate', newBuyRate);
            }
          }
        }

        if (removeEnhTfsReason && isEnhTfsCostShareAccepted) {
          aprCard.setDefaultRatesToTerms(standardRates, ncsRate, userInfo.isSet);
        }

        tierTerm.updateEstCosts();
      }
    }
  };

  const handleResetDates = (onCreate = false) => {
    const firstTerm = aprCard.selectedTierModel?.terms[0];

    if (firstTerm) {
      if (onCreate) {
        firstTerm.updateField('startDate', dateStringToDate(offering.startDate));
        firstTerm.updateField('endDate', dateStringToDate(offering.endDate));
        return;
      }

      firstTerm.updateField('startDate', aprCard.initialDates.startDate);
      firstTerm.updateField('endDate', aprCard.initialDates.endDate);
    }
  };

  const handleUpdateTfsAttributesOnUpdate = (isEnhTfsCostShareAccepted: boolean | undefined, skipRecalc: boolean) => {
    // for buy rate recalculations
    handleUpdateTierTermsWithEnhTfsCostShare(isEnhTfsCostShareAccepted, skipRecalc);
  };

  const buildEnhTfsCostSharePayload = (enhTfsCostShareParams: {
    removeEnhTfsReason: string;
    replaceExistingTfs: boolean;
    isEnhTfsCostShareAccepted: boolean | undefined;
  }): SaveAprOfferInput => {
    const { removeEnhTfsReason, replaceExistingTfs, isEnhTfsCostShareAccepted } = enhTfsCostShareParams;

    if (
      (removeEnhTfsReason && isEnhTfsCostShareAccepted) ||
      (!isEnhTfsCostShareAccepted && !aprCard.enhTfsCostShareId && !removeEnhTfsReason)
      // if isEnhTfsCostShareAccepted is already set to false or user clicks cancel & isnt trying to remove or replace existing enhanced cost share
    ) {
      return {
        isEligibleForEnhTfsCostShare: false,
        isEnhTfsCostShareAccepted:
          (aprCard.isEnhTfsCostShareAccepted === undefined && !aprCard.isEligibleForEnhTfsCostShare) || (removeEnhTfsReason && isEnhTfsCostShareAccepted) ? undefined : false,
        enhTfsCostShareId: '',
      };
    }

    // For replacing an existing enhanced cost share but user clicks cancel on modal, and then from that point forward
    if ((replaceExistingTfs || aprCard.isEligibleForEnhTfsCostShare) && aprCard.isEnhTfsCostShareAccepted === false) {
      return {
        isEligibleForEnhTfsCostShare: true,
        isEnhTfsCostShareAccepted: false,
        enhTfsCostShareId: aprCard.enhTfsCostShareId,
      };
    }

    return {
      isEligibleForEnhTfsCostShare: aprCard.isEligibleForEnhTfsCostShare,
      isEnhTfsCostShareAccepted: true,
      enhTfsCostShareId: aprCard.enhTfsCostShareId,
    };
  };

  const buildEnhTfsCostSharePayloadOnUpdate = (enhCostSharePayload: SaveAprOfferInput, isEnhTfsCostShareAccepted: boolean | undefined): SaveAprOfferInput => {
    const enhTfsCostSharePayload = {
      ...enhCostSharePayload,
      ...buildEnhTfsCostSharePayload({ removeEnhTfsReason, replaceExistingTfs, isEnhTfsCostShareAccepted }),
    };

    return enhTfsCostSharePayload;
  };

  const buildEnhTfsCostSharePayloadOnCreate = (enhCostSharePayload: SaveAprOfferInput, isEnhTfsCostShareAccepted: boolean, onSecondModal: boolean): CreateAprOfferInput => {
    let createEnhTfsPayload: CreateAprOfferInput = {
      ...enhCostSharePayload,
      isEligibleForEnhTfsCostShare: isEnhTfsCostShareAccepted,
      isEnhTfsCostShareAccepted: showEnhTfsCostShareModal ? isEnhTfsCostShareAccepted : undefined,
      enhTfsCostShareId: isEnhTfsCostShareAccepted && aprCard.enhTfsCostShareId ? aprCard.enhTfsCostShareId : '',
    };

    if (onSecondModal && (!enhCostSharePayload.isEnhCostShareAccepted || !isEnhTfsCostShareAccepted)) {
      createEnhTfsPayload = {
        ...enhCostSharePayload,
        isEligibleForEnhCostShare: false,
        enhCostShareOfferId: '',
        isEnhCostShareAccepted: undefined,
        isEligibleForEnhTfsCostShare: false,
        enhTfsCostShareId: '',
        isEnhTfsCostShareAccepted: undefined,
      };
    }

    return createEnhTfsPayload;
  };

  const setNewAprEnhTfsFields = (aprOffer: Offer) => {
    aprCard.updateEnhTfsCostShareFields(aprOffer);

    // setting new initial dates & model codes after save, to revert data if user hits cancel on new modal
    if (aprOffer.aprDetails?.length) {
      aprCard.setInitialDates(dateStringToDate(aprOffer.aprDetails[0].startDate), dateStringToDate(aprOffer.aprDetails[0].endDate));
    }
  };

  const handleResetRemoveTfsParams = () => {
    setEnhTfsRemoveReason('');
    setShowRemoveTfsCostShareModal(false);
  };

  const handleResetExistingTfsParams = () => {
    setReplaceExistingTfs(false);
  };

  const handleResetAfterTfsSave = () => {
    setShowEnhTfsCostShareModal(false);

    if (removeEnhTfsReason) {
      handleResetRemoveTfsParams();
    }

    if (replaceExistingTfs) {
      handleResetExistingTfsParams();
    }
  };

  return {
    handleCheckForEnhTfsCostShares,
    showEnhTfsCostShareModal,
    setShowEnhTfsCostShareModal,
    showRemoveTfsCostShareModal,
    setShowRemoveTfsCostShareModal,
    removeEnhTfsReason,
    replaceExistingTfs,
    handleUpdateTierTermsWithEnhTfsCostShare,
    handleCancelOnAddEnhTfsCostShare,
    handleResetDates,
    handleUpdateTfsAttributesOnUpdate,
    handleResetAfterTfsSave,
    handleResetRemoveTfsParams,
    buildEnhTfsCostSharePayloadOnUpdate,
    buildEnhTfsCostSharePayloadOnCreate,
    setNewAprEnhTfsFields,
  };
};

export default useAprEnhTfsCostShareUtils;
