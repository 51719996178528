import clsx from 'clsx';
import { formatDollars, OATIcon } from 'oat-common-ui';
import { ReactNode } from 'react';
import useGetTfsLabel from '../../../../../../../utils/useGetTfsLabel';
import PenRateModel from '../../../../../models/PenRateModel';
import { returnLowestTier } from '../../Expanded/Lease/utils';
import styles from '../styles.module.scss';
import { formatValue } from '../../../../../../../components/NumberInput/utils';

interface Props {
  leaseModel: PenRateModel;
  showDates: (model: PenRateModel) => ReactNode | undefined;
}

const LeaseTableValues = ({ leaseModel, showDates }: Props) => {
  const tfsLabel = useGetTfsLabel();
  const { isAdvertised, name, tiers, isEnhanced, rcf, term, subCash, optionTypeName, isSubCashEnhanced } = leaseModel;

  return (
    <td className={styles.rate}>
      <span className={clsx(!isAdvertised && styles.paddingLeft, styles.leaseName)}>
        {isAdvertised && (
          <span className={clsx(styles.icon, styles.marginRight)}>
            <OATIcon icon="active_offer" colorTheme="orange" />
          </span>
        )}
        {name.split(' ')[0]} {term} mo
      </span>
      <span className={styles.marginLeft}>{`Tier ${returnLowestTier(tiers)},`}</span>
      <span className={clsx(styles.marginLeft, isEnhanced && styles.addBold)}>{formatValue(rcf, true)}</span>
      {isEnhanced && (
        <span className={clsx(styles.rate, styles.icon, styles.marginLeft)}>
          <OATIcon icon="enhancement" colorTheme="orange" />
        </span>
      )}
      {subCash > 0 && (
        <span className={styles.marginLeft}>
          <span className={styles.addBold}>{`+ ${tfsLabel} ${optionTypeName} Subvention Cash `}</span>
          <span className={isSubCashEnhanced ? styles.addBold : ''}>${formatDollars(subCash)}</span>
        </span>
      )}
      {isSubCashEnhanced && (
        <span className={clsx(styles.rate, styles.icon, styles.marginLeft)}>
          <OATIcon icon="enhancement" colorTheme="orange" />
        </span>
      )}
      {showDates(leaseModel)}
    </td>
  );
};

export default LeaseTableValues;
