import { makeAutoObservable } from 'mobx';
import AccordionModel from '../../../../components/Accordion/models/AccordionModel';
import createAccordionFromModelCodes from '../../../../components/Accordion/utils/createAccordionFromModelCodes';
import { ModelCode, ModelCodes, SeriesMapping, VehicleInput } from '../../../../gql/generated';
import { hasValidIndex, nameOrYearIsIncluded } from './utils';
import mapVehicleModelCodeFromAccordion from '../../../../components/Accordion/utils/mapVehicleModelCodeFromAccordion';

export class AddSeriesProfileModel {
  loaded = false;
  filter = '';
  name = '';
  searchInput = '';
  states: string[] = [];
  vehicles: VehicleInput[] = [];
  modelCodes: ModelCodes[] = [];
  seriesMapping: SeriesMapping[] = [];
  accordion: AccordionModel = new AccordionModel();
  existingProfileNames: string[] = [];
  previousSelectedVehicles: VehicleInput[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setData = (modelCodes: ModelCodes[], seriesMapping: SeriesMapping[], states: string[], existingProfileNames: string[], includedVehicles?: VehicleInput[]) => {
    this.modelCodes = modelCodes;
    this.seriesMapping = seriesMapping;
    this.states = states;
    this.vehicles = includedVehicles || [];
    this.existingProfileNames = existingProfileNames;

    this.accordion = new AccordionModel(createAccordionFromModelCodes(modelCodes, seriesMapping, includedVehicles));

    this.loaded = true;
  };

  setName = (value: string) => {
    this.name = value;
  };

  setSearchInput = (value: string) => {
    this.searchInput = value;
    this.filter = value;

    const filteredModelCodes = this.modelCodes
      .filter(modelCodesItem => {
        if (this.filter) {
          return hasValidIndex(modelCodesItem.models, this.filter) || nameOrYearIsIncluded(modelCodesItem, this.filter);
        } else {
          return true;
        }
      })
      .map(modelCodesItem => {
        return {
          ...modelCodesItem,
          models:
            hasValidIndex(modelCodesItem.models, this.filter) && !nameOrYearIsIncluded(modelCodesItem, this.filter)
              ? modelCodesItem?.models.filter(
                  (model: ModelCode) => model.modelCode.includes(this.filter) || model.vehicleDescription.toLowerCase().includes(this.filter.toLowerCase()),
                )
              : modelCodesItem.models,
        };
      });

    const currentSelectedVehicles = mapVehicleModelCodeFromAccordion(this.accordion.items, this.modelCodes); 
    const mergedSelectedVehicles = Array.from(new Set([...this.previousSelectedVehicles, ...currentSelectedVehicles].map(vehicule => JSON.stringify(vehicule)))).map(vehicule => JSON.parse(vehicule));
    const accordionModel = new AccordionModel(createAccordionFromModelCodes(filteredModelCodes, this.seriesMapping, mergedSelectedVehicles));
    accordionModel.toggleExpandAll(Boolean(this.filter));
    this.accordion = accordionModel;
    this.previousSelectedVehicles = !value ? [] : mergedSelectedVehicles;
  };

  setStateCheck = (value: string) => {
    if (this.states.includes(value)) {
      const index = this.states.findIndex(state => state === value);
      this.states.splice(index, 1);
    } else {
      this.states = [...this.states, value];
    }
  };

  get hasErrors() {
    return this.nameError || this.vehiclesError || this.statesMissingError || this.existingProfileError;
  }

  get nameError() {
    return !this.name;
  }

  get vehiclesError() {
    return this.accordion.getChecked(this.accordion.items).length === 0;
  }

  get statesMissingError() {
    return this.states.length === 0;
  }

  get existingProfileError() {
    return this.existingProfileNames.findIndex(name => this.name === name) !== -1;
  }
}

export default AddSeriesProfileModel;
