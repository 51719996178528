import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { formatDollars, OATIcon } from 'oat-common-ui';
import { ReactNode } from 'react';
import SlideToggle from '../../../../../../../components/SlideToggle';
import { Status } from '../../../../../../../constants/global';
import useStores from '../../../../../../../stores/useStores';
import PenRateModel from '../../../../../models/PenRateModel';
import styles from '../styles.module.scss';

interface Props {
  miscModel: PenRateModel;
  showDates: (model: PenRateModel) => ReactNode | undefined;
  onToggle?: (model: PenRateModel) => void;
}

const MiscTableValues = ({ miscModel, showDates, onToggle }: Props) => {
  const { summaryStore } = useStores();
  const { isAdvertised, name, cash, isEnhanced } = miscModel;

  const handleOnToggle = () => {
    if (onToggle) {
      onToggle(miscModel);
    }
  };

  return (
    <td className={styles.rate}>
      <span className={clsx(!isAdvertised && styles.paddingLeft, styles.miscName)}>
        {isAdvertised && (
          <span className={clsx(styles.icon, styles.marginRight)}>
            <OATIcon icon="active_offer" colorTheme="orange" />
          </span>
        )}
        {name}
      </span>
      <span className={clsx(styles.marginLeft, isEnhanced && styles.addBold)}>${formatDollars(cash)}</span>
      {isEnhanced && (
        <span className={clsx(styles.rate, styles.icon, styles.marginLeft)}>
          <OATIcon icon="enhancement" colorTheme="orange" />
        </span>
      )}
      {showDates(miscModel)}
      {miscModel.showToggle && (
        <span className={styles.marginLeft}>
          <SlideToggle
            disabled={summaryStore.offering.status === Status.MEETS_GUIDELINES}
            selected={miscModel.isFourthOption}
            toggleSelected={() => handleOnToggle()}
            containerClass={styles.slider}
          />
        </span>
      )}
    </td>
  );
};

export default observer(MiscTableValues);
