import { AdminConstants } from 'oat-admin-common';
import { MILEAGES } from '../constants/global';

const { BRAND_LEXUS, BRAND_TOYOTA, RegionCodes } = AdminConstants;

const getAnnualMileagesList = (brand: string, region: string, isTermHigherThen30: boolean, isSupra = false) => {
  if (brand === BRAND_LEXUS || (brand === BRAND_TOYOTA && isTermHigherThen30 && isSupra)) {
    if (region === RegionCodes.SET) {
      return {
        mileages: [...MILEAGES, { value: '18000', label: '18,000' }],
      };
    }

    return {
      mileages: MILEAGES,
    };
  } else if (brand === BRAND_TOYOTA && !isTermHigherThen30) {
    return {
      mileages: MILEAGES.filter(mileage => mileage.value === '12000' || mileage.value === '15000'),
    };
  }
  return {
    mileages: MILEAGES.filter(mileage => mileage.value === '10000' || mileage.value === '12000' || mileage.value === '15000'),
  };
};

export default getAnnualMileagesList;
