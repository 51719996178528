import { faAngleLeft, faCheckCircle, faCompressAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { BlockInputLabel, Button, Input, OATIcon, useInputDelay, useToast } from 'oat-common-ui';
import { ChangeEvent, FC, KeyboardEvent, useMemo, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import ProfileTitle from '../../../../components/ProfileTitle';
import SettingsMenu from '../../../../components/SettingsMenu';
import { FEATURE_OR_4154, FEATURE_OR_4619, Status } from '../../../../constants/global';
import { OfferingCosts, VehicleInput, useDeleteSeriesProfileMutation, useSaveRgnlAltNationalNoteMutation, useSaveSeriesProfileMutation } from '../../../../gql/generated';
import useOfferingStatus from '../../../../hooks/useOfferingStatus';
import useStores from '../../../../stores/useStores';
import useGetTfsLabel from '../../../../utils/useGetTfsLabel';
import { ItemEditModels, ItemRemoveSeriesProfile } from '../../../ProgramDetails/components/contextMenuItems';
import ProfileModel from '../../models/ProfileModel';
import RgnlAltModel from '../../models/RgnlAltModel';
import EditModelsModal from './EditModelsModal';
import ResetToNationalModal from './ResetToNationalModal';
import ReviewModelsModal from './ReviewModelsModal';
import styles from './styles.module.scss';

type Props = {
  profile: ProfileModel;
  onSelect: (template: RgnlAltModel) => void;
  setTitle?: (title: string) => void;
};

const ProfileHeader: FC<Props> = ({ profile, onSelect, setTitle }) => {
  const {
    summaryStore: { removeSeriesProfile, offering },
    offeringCostsStore,
    userInfoStore,
  } = useStores();

  const [deleteSeriesProfile] = useDeleteSeriesProfileMutation();
  const [saveRgnlAltNationalNote] = useSaveRgnlAltNationalNoteMutation();
  const [saveSeriesProfile] = useSaveSeriesProfileMutation();

  const { error } = useToast();
  const { setDelay } = useInputDelay();
  const tfsLabel = useGetTfsLabel();

  const [titleInput, setTitleInput] = useState(profile.name);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showResetToNationaldModal, setShowResetToNationaldModal] = useState(false);
  const [showEditModelsModal, setShowEditModelsModal] = useState(false);
  const [showReviewModelsModal, setShowReviewModelsModal] = useState(false);
  const [includedVehicles, setIncludedVehicles] = useState<VehicleInput[]>([]);

  const { offeringStatus } = useOfferingStatus(offering);

  const selectedRa = profile.getSelectedRgnAlt();
  const titleError = !titleInput;

  const hasUpdatedEnhTfsCostShare = useMemo(() => {
    if (!selectedRa) return false;

    const rgnlAltOffers = profile.rgnAltOffersMap.get(selectedRa.id);

    return rgnlAltOffers?.some(offer => offer.regional.isEnhTfsCostShareUpdated);
  }, [profile.rgnAltOffersMap, selectedRa]);

  const changeRgnlAlt = () => {
    if (selectedRa) {
      onSelect(selectedRa);
    }
  };

  const handleSaveInput = () => {
    const trimTitle = titleInput.trim();

    if (setTitle && profile.name !== trimTitle) {
      setTitle(trimTitle);
    }

    setIsEditMode(false);
  };

  const handleCancelInput = () => {
    setTitleInput(profile.name);
    setIsEditMode(false);
  };

  const closeOnEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !titleError) {
      event.preventDefault();
      handleSaveInput();
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitleInput(e.target.value);
  };

  const handleSaveModelCodes = async () => {
    try {
      const res = await trackPromise(saveSeriesProfile({ variables: { id: profile.id, rev: profile.rev, vehicles: includedVehicles } }));
      profile.updateRev(res.data?.saveSeriesProfile.seriesProfile.rev);
      profile.updateVehicles(res.data?.saveSeriesProfile.seriesProfile.vehicles as VehicleInput[]);
    } catch (e) {
      error((e as Error).message);
    } finally {
      setShowReviewModelsModal(false);
      setIncludedVehicles([]);
    }
  };

  const handleRemove = async () => {
    try {
      const res = await trackPromise(deleteSeriesProfile({ variables: { id: profile.id, rev: profile.rev } }));

      if (res.data?.deleteSeriesProfile.success) {
        removeSeriesProfile(profile.id);
        offeringCostsStore.setData(res.data.deleteSeriesProfile.offeringCosts as OfferingCosts);
      }
      window.location.reload();
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleSaveRgnlAltNationalNote = (nationalNote: string) => {
    profile.updateNote(nationalNote);

    if (nationalNote) {
      setDelay(async () => {
        try {
          const res = await trackPromise(
            saveRgnlAltNationalNote({
              variables: {
                input: {
                  id: selectedRa?.id || '',
                  rev: selectedRa?.rev || '',
                  nationalNote: profile.note,
                },
              },
            }),
          );
          selectedRa?.updateRev(res.data?.saveRgnlAltNationalNote.rgnlAlt.rev || '');
        } catch (e) {
          error((e as Error).message);
        }
      });
    }
  };

  return (
    <header className={styles.profileHeader}>
      <div className={styles.profileHeaderContent}>
        <div className={styles.profileHeaderDetails}>
          {isEditMode ? (
            <>
              <Input autoFocus onKeyPress={closeOnEnterPress} type="text" value={titleInput} onChange={handleOnChange} error={titleError} />
              <OATIcon icon="close" size={16} className={styles.icon} onClick={handleCancelInput} />
              <OATIcon icon="check-mark" size={16} className={styles.icon} onClick={handleSaveInput} />
            </>
          ) : (
            <>
              <h3 className={styles.title}>{titleInput}</h3>
              {profile.isCustomSeries && <OATIcon icon="edit" size={16} className={clsx(styles.icon, styles.iconEdit)} onClick={() => setIsEditMode(true)} />}
            </>
          )}

          {profile.isConfirmedSeries && !profile.isEarningsOnly && <ProfileTitle selectedRa={selectedRa} />}
        </div>
        {FEATURE_OR_4154 && !profile.isConfirmedSeries && hasUpdatedEnhTfsCostShare && (
          <div className={styles.profileHeaderWarning}>
            <p>
              <OATIcon icon="warning" colorTheme="orange" />
              {`Enhanced ${tfsLabel} Cost Share has been updated. Please verify offers.`}
            </p>
          </div>
        )}
        {selectedRa && (
          <div className={clsx(styles.profileHeaderActions, profile.isConfirmedSeries && styles.marginBottom)}>
            {profile.isConfirmedSeries ? (
              <>
                <FontAwesomeIcon className={styles.checkIcon} icon={faCheckCircle} />
                <span className={styles.seriesConfirmed}>Series Confirmed</span>
                <span className={styles.separator} />
                <div className={styles.iconWrapper}>
                  {offeringStatus === Status.MEETS_GUIDELINES ? (
                    <>
                      {profile.isConfirmedView ? (
                        <Button variant="text" onClick={() => profile.setIsConfirmedView(false)} className={styles.iconBtn}>
                          <OATIcon icon={'expand'} size={16} colorTheme="blue" />
                        </Button>
                      ) : (
                        <Button variant="text" className={styles.iconBtn} onClick={() => profile.setIsConfirmedView(true)}>
                          <FontAwesomeIcon icon={faCompressAlt} className={styles.compressIcon} />
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button
                      variant="text"
                      onClick={() => {
                        profile.setIsConfirmedView(false);
                        profile.setIsConfirmedSeries(false);
                      }}
                      className={styles.iconBtn}
                    >
                      <OATIcon icon={'edit'} size={16} colorTheme="blue" />
                    </Button>
                  )}
                </div>
              </>
            ) : (
              <>
                {offeringStatus !== Status.MEETS_GUIDELINES && (
                  <>
                    {!offering.isUpdatedToMG && (
                      <>
                        <FontAwesomeIcon icon={faAngleLeft} />
                        <Button id="change-ra-cta" className={styles.blueText} onClick={changeRgnlAlt}>
                          Change Selection
                        </Button>
                        <span className={styles.separator} />
                      </>
                    )}
                    <div className={styles.iconWrapper}>
                      {!profile.isCustomSeries ? (
                        <SettingsMenu iconSize={16}>
                          <li
                            onClick={() => {
                              setShowResetToNationaldModal(true);
                            }}
                          >
                            <OATIcon icon="reset" />
                            Reset to National
                          </li>
                        </SettingsMenu>
                      ) : (
                        <SettingsMenu iconSize={16}>
                          <ItemEditModels
                            onClick={() => {
                              FEATURE_OR_4619 && setShowEditModelsModal(true);
                            }}
                          />
                          <ItemRemoveSeriesProfile onClick={handleRemove} />
                        </SettingsMenu>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>

      <p className={styles.notes}>{profile.note}</p>
      {selectedRa?.note && <p className={styles.notes}>{selectedRa.note}</p>}

      {selectedRa?.status === Status.NEEDS_REVISIONS && userInfoStore.isNational() && (
        <div className={styles.nationalNoteContainer}>
          <BlockInputLabel labelComponent={<span className={styles.inputLabel}>National Note</span>}>
            <Input
              type="text"
              id={`${selectedRa?.id}-${Status.NEEDS_REVISIONS}-national-note-input`}
              className={styles.noteInput}
              defaultValue={profile.note}
              onChange={e => handleSaveRgnlAltNationalNote(e.target.value)}
              error={!profile.note}
            />
          </BlockInputLabel>
        </div>
      )}

      {showResetToNationaldModal && selectedRa && (
        <ResetToNationalModal
          onClose={() => setShowResetToNationaldModal(false)}
          onSubmit={rgnAlt => {
            profile.resetRgnAltToNational(rgnAlt);
          }}
          rgnlAltId={selectedRa?.id}
        />
      )}

      {showEditModelsModal && (
        <EditModelsModal
          seriesProfile={profile}
          offering={offering}
          onClose={() => setShowEditModelsModal(false)}
          onSubmit={included => {
            setIncludedVehicles(included);
            setShowEditModelsModal(false);
            setShowReviewModelsModal(true);
          }}
        />
      )}

      {showReviewModelsModal && (
        <ReviewModelsModal
          onClose={() => setShowReviewModelsModal(false)}
          onNo={() => {
            setShowReviewModelsModal(false);
            setShowEditModelsModal(true);
          }}
          onYes={() => handleSaveModelCodes()}
        />
      )}
    </header>
  );
};

export default observer(ProfileHeader);
