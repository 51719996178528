import { uuidv4 } from 'oat-common-ui';
import AprCardModel from '../pages/ProgramDetails/models/AprCardModel';
import CashCardModel from '../pages/ProgramDetails/models/CashCardModel';
import LeaseCardModel from '../pages/ProgramDetails/models/LeaseCardModel';
import MiscCardModel from '../pages/ProgramDetails/models/MiscCardModel';

// For sonarqube
const createCashBreakdown = (offer: CashCardModel | MiscCardModel) => ({
  uid: uuidv4(),
  name: offer.fields.name,
  earnings: offer.fields.offerEarnings,
  cost: offer.fields.estimatedCost < 0 ? 0 : offer.fields.offerCost,
  balance: offer.fields.offerBalance,
  tfsCost: offer.fields.offerTfsCost,
  enhTfsCost: offer.fields.offerEnhTfsCost,
  subCashCost: 0,
  subCashTfsCost: 0,
  subCashEnhTfsCost: 0,
});

export type OfferCostBreakdown = {
  uid: string;
  name: string;
  earnings: number | string;
  cost: number | string;
  balance: number | string;
  tfsCost: number;
  enhTfsCost: number;
  subCashName?: string;
  subCashCost: number;
  subCashTfsCost: number;
  subCashEnhTfsCost: number;
};

const getOfferCostBreakdown = (cashModels: CashCardModel[], aprModels: AprCardModel[], leaseModels: LeaseCardModel[], miscModels: MiscCardModel[]) => {
  const data: OfferCostBreakdown[] = [];

  cashModels.forEach(offer => {
    data.push(createCashBreakdown(offer));
  });

  aprModels.forEach(offer => {
    Array.from(offer.tierMap).forEach(([, tier]) => {
      data.push({
        uid: uuidv4(),
        name: `${offer.fields.name} Tier ${tier.tier}`,
        earnings: tier.fields.offerEarnings,
        cost: tier.fields.offerCost,
        balance: tier.fields.offerBalance,
        tfsCost: tier.fields.offerTfsCost,
        enhTfsCost: tier.fields.offerEnhTfsCost,
        subCashName: 'TFS APR Subvention Cash',
        subCashCost: tier.fields.subCashOfferCost,
        subCashTfsCost: tier.fields.subCashOfferTfsCost,
        subCashEnhTfsCost: tier.fields.subCashOfferEnhTfsCost,
      });
    });
  });

  leaseModels.forEach(offer => {
    offer.leaseTerms.forEach(leaseTerm => {
      const masterLease = leaseTerm.getMaster();
      if (masterLease) {
        data.push({
          uid: uuidv4(),
          name: `${offer.name} ${masterLease.inputs.term} mos`,
          earnings: masterLease.offerEarnings,
          cost: masterLease.offerCost,
          balance: masterLease.offerBalance,
          tfsCost: masterLease.offerTfsCost,
          enhTfsCost: masterLease.offerEnhTfsCost,
          subCashName: 'TFS Lease Subvention Cash',
          subCashCost: masterLease.subCashOfferCost,
          subCashTfsCost: masterLease.subCashOfferTfsCost,
          subCashEnhTfsCost: masterLease.subCashOfferEnhTfsCost,
        });
      }
    });
  });

  miscModels.forEach(offer => {
    data.push(createCashBreakdown(offer));
  });

  return data;
};

export default getOfferCostBreakdown;
