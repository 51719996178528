import clsx from 'clsx';
import { formatDollars, OATIcon } from 'oat-common-ui';
import { ReactNode } from 'react';
import useGetTfsLabel from '../../../../../../../utils/useGetTfsLabel';
import PenRateModel from '../../../../../models/PenRateModel';
import styles from '../styles.module.scss';

interface Props {
  aprModel: PenRateModel;
  showDates: (model: PenRateModel) => ReactNode | undefined;
}

const AprTableValues = ({ aprModel, showDates }: Props) => {
  const tfsLabel = useGetTfsLabel();
  const { isAdvertised, name, tier, aprDetails, isEnhanced, subCash, isSubCashEnhanced, optionTypeName } = aprModel;

  return (
    <td className={styles.rate}>
      <span className={clsx(!isAdvertised && styles.paddingLeft, styles.aprName)}>
        {isAdvertised && (
          <span className={clsx(styles.icon, styles.marginRight)}>
            <OATIcon icon="active_offer" colorTheme="orange" />
          </span>
        )}
        {name} {tier}
      </span>
      <span className={styles.marginLeft}>
        {aprDetails.map((aprDetail, i) => {
          return (
            <span key={`APR-${aprDetail.rate}-${aprDetail.term}`} className={styles.rate}>
              <span className={aprDetail.isEnhanced ? styles.addBold : ''}>{aprDetail.rate}</span>
              {i < aprDetails.length - 1 ? <span className={styles.aprRate}>/</span> : ''}
            </span>
          );
        })}
      </span>
      {isEnhanced && (
        <span className={clsx(styles.rate, styles.icon, styles.marginLeft)}>
          <OATIcon icon="enhancement" colorTheme="orange" />
        </span>
      )}
      {subCash > 0 && (
        <span className={clsx(styles.marginLeft, styles.rate)}>
          <span className={styles.addBold}>{`+ ${tfsLabel} ${optionTypeName} Subvention Cash `}</span>
          {aprDetails.map((aprDetail, i) => {
            return (
              <span key={`APR-${aprDetail.subCash}-${aprDetail.term}`}>
                <span className={aprDetail.isSubCashEnhanced && aprDetail.subCash > 0 ? styles.addBold : ''}>{`$${formatDollars(aprDetail.subCash)}`}</span>
                {i < aprDetails.length - 1 ? <span className={styles.aprRate}>/</span> : ''}
              </span>
            );
          })}
        </span>
      )}
      {isSubCashEnhanced && (
        <span className={clsx(styles.rate, styles.icon, styles.marginLeft)}>
          <OATIcon icon="enhancement" colorTheme="orange" />
        </span>
      )}
      {showDates(aprModel)}
    </td>
  );
};

export default AprTableValues;
