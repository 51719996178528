import { makeAutoObservable } from 'mobx';
import AprCardModel from '../../models/AprCardModel';

type TierTermData = { tier?: string; term?: number };
type LowHighTerm = { lowTerm: number; highTerm: number };

export type SubventionCashInput = {
  subCashTfsCostShareCap: number;
  subCashTfsShare: number;
  term: number;
};

type SubventionCashTableCell = {
  checked: boolean;
  term: number;
  tier: string;
};

export class SubventionCashModelFields {
  subventionCashInputs: SubventionCashInput[] = [];
  subventionCashTableCells: SubventionCashTableCell[] = [];
  subventionCash = 0;

  constructor() {
    makeAutoObservable(this);
  }
}

class SubventionCashModel {
  offerId = '';
  rev = '';

  fields = new SubventionCashModelFields();
  tiers: string[] = [];
  terms: number[] = [];
  lowHighTerms: LowHighTerm[] = [];
  defaultNationalSubventionCash = 0;

  constructor(aprCard: AprCardModel) {
    makeAutoObservable(this);
    this.initData(aprCard);
  }

  initData(aprCard: AprCardModel) {
    this.rev = aprCard.rev;
    this.offerId = aprCard.id;
    this.defaultNationalSubventionCash = aprCard.nationalOffer?.aprDetails?.[0]?.subventionCash || 0;
    // Initialize subventionCashInputs from termModel for each tier
    for (const tier of aprCard.tierMap.values()) {
      this.tiers.push(tier.tier);

      tier.terms.forEach(term => {
        if (this.fields.subventionCashInputs.findIndex(input => input.term === term.fields.term) === -1) {
          this.fields.subventionCashInputs.push({
            subCashTfsCostShareCap: term.fields.subCashTfsCostShareCap,
            subCashTfsShare: term.fields.subCashTfsShare,
            term: term.fields.term,
          });
        }

        // Set all cells to default/unchecked state
        this.fields.subventionCashTableCells.push({
          checked: false,
          term: term.fields.term,
          tier: tier.tier,
        });

        // We can't use AprTerms from global.ts because SET can exclude terms on create standalone APR
        if (!this.terms.includes(term.fields.term)) {
          this.terms.push(term.fields.term);
        }

        if (this.lowHighTerms.findIndex(termData => termData.highTerm === term.fields.term) === -1) {
          this.lowHighTerms.push({
            lowTerm: term.fields.lowTerm,
            highTerm: term.fields.term,
          });
        }
      });
    }
  }

  getSubventionCashByHighTerm = (highTerm: number) => {
    return this.fields.subventionCashInputs.find(subventionInput => subventionInput.term === highTerm);
  };

  updateSubventionCashInputField = <T extends keyof SubventionCashInput, V extends SubventionCashInput[T]>(field: T, value: V, term: number) => {
    this.fields.subventionCashInputs.forEach(input => {
      if (input.term === term) {
        input[field] = value;
      }
    });
  };

  setSubventionCash = (amount: number) => {
    this.fields.subventionCash = amount;
  };

  getSubventionCashTableCell = (tier: string, term: number) => {
    return this.fields.subventionCashTableCells.find(cell => cell.term === term && cell.tier === tier);
  };

  toggleSubventionCashTableCell = (tier: string, term: number) => {
    this.fields.subventionCashTableCells.forEach(cell => {
      if (cell.term === term && cell.tier === tier) {
        cell.checked = !cell.checked;
      }
    });
  };

  copyDown = ({ tier, term }: TierTermData) => {
    if (!term) {
      // Called from <th> e.g. Tier 2
      const firstCell = this.fields.subventionCashTableCells.filter(cell => cell.tier === tier)[0];

      this.fields.subventionCashTableCells.forEach(cell => {
        if (cell.tier === tier) {
          cell.checked = firstCell.checked;
        }
      });
    } else {
      // Called from <td> e.g. 36 mo
      this.fields.subventionCashTableCells.forEach(cell => {
        if (cell.term > term) {
          cell.checked = Boolean(this.getSubventionCashTableCell(cell.tier, term)?.checked);
        }
      });
    }
  };

  copyAcross = ({ tier, term }: TierTermData) => {
    if (!term) {
      // Called from <th> e.g. Tier 2
      this.fields.subventionCashTableCells.forEach(cell => {
        if ((Number(cell.tier) || 0) > (Number(tier) || 0)) {
          cell.checked = Boolean(tier && this.getSubventionCashTableCell(tier, cell.term)?.checked);
        }
      });
    } else {
      // Called from <td> e.g. 36 mo
      const firstCell = this.fields.subventionCashTableCells.filter(cell => cell.term === term)[0];

      this.fields.subventionCashTableCells.forEach(cell => {
        if (cell.term === term) {
          cell.checked = firstCell.checked;
        }
      });
    }
  };

  removeAll = ({ tier, term }: TierTermData) => {
    this.fields.subventionCashTableCells.forEach(cell => {
      if (cell.tier === tier || cell.term === term) {
        cell.checked = false;
      }
    });
  };

  subCashInputHasError = (term = 0, field: string) => {
    const subCashByTerm = this.getSubventionCashByHighTerm(term);

    if (field === 'subCashTfsShare') {
      return subCashByTerm?.subCashTfsShare !== 0 && !subCashByTerm?.subCashTfsShare;
    }

    if (field === 'subCashTfsCostShareCap' && !subCashByTerm?.subCashTfsCostShareCap) {
      return subCashByTerm?.subCashTfsCostShareCap !== 0 && !subCashByTerm?.subCashTfsCostShareCap;
    }

    if (field === 'subventionCash' && this.defaultNationalSubventionCash) {
      return this.fields.subventionCash < this.defaultNationalSubventionCash
    }

    return !this.fields.subventionCash && this.fields.subventionCash !== 0;
  };
}

export default SubventionCashModel;
