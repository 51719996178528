import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { blankCarImg, CarJellyImage } from 'oat-common-ui';
import { useMemo } from 'react';
import { FEATURE_OR_4154 } from '../../../../../../constants/global';
import { EnhTfsCostShare, VehicleInput } from '../../../../../../gql/generated';
import useUrlParams from '../../../../../../hooks/useUrlParams';
import useStores from '../../../../../../stores/useStores';
import { getEfcCarJelly, getEfcImageFullPath } from '../../../../../../utils/efc3dImageUtils';
import getDisabledVehicles from '../../../../../../utils/getDisabledVehicles';
import useUserInfo from '../../../../../../utils/useUserInfo';
import LeaseCardModel from '../../../../models/LeaseCardModel';
import LeaseFormModel from '../../../../models/LeaseFormModel';
import BlockText from '../../../BlockText';
import ContestNumber from '../../../ContestNumber';
import EnhCostShareAvailabilities from '../../../EnhCostShareAvailabilities';
import OfferCompatibleOffers from '../../../OfferCompatibleOffers';
import OfferExclusions from '../../../OfferExclusions';
import VehicleInfoRight from './components/VehicleInfoRight';
import styles from './styles.module.scss';
import { returnStatesList } from './utils';

type Props = {
  leaseCard: LeaseCardModel;
  leaseForm: LeaseFormModel;
  isDisabled: boolean;
  toggleIsInclusions?: () => void;
  isInclusions?: boolean;
  isAdvertised?: boolean;
  onSaveCompatibility: () => void;
  onChange?: (skipDelay: boolean, isVehicleChange?: boolean) => void;
};

const VehicleInfo = ({ leaseCard, leaseForm, isAdvertised, onSaveCompatibility, onChange, isDisabled }: Props) => {
  const { profile } = useUrlParams();
  const {
    programDetailsStore: { seriesProfile },
    efcStore: { seriesImages },
    regionalCostShareStore: { costShareDatesMap },
  } = useStores();
  const { region } = useUrlParams();
  const { isSETUser, isGSTUser } = useUserInfo();
  const disabledVehicles = getDisabledVehicles(leaseCard.nationalVehicles || seriesProfile.vehicles);

  const handleExclusions = (excluded: VehicleInput[], included: VehicleInput[]) => {
    leaseCard.updateExcIncVehicles(excluded, included);
    onChange && onChange(true, true);
  };

  const handleCompatibleOffers = (compList: string[]) => {
    leaseCard.updateCompatibilityList(compList);
    onSaveCompatibility();
  };

  const handleOnUpdate = (contestNumberId: string, contestNumber: string, isUpdate = false) => {
    leaseCard.updateContestNumberDetails(contestNumber, contestNumberId);
    if (isUpdate && onChange) {
      onChange(false);
    }
  };

  const carJellyString = useMemo(() => {
    let carJelly = '';
    if (seriesProfile.series.length) {
      carJelly = getEfcImageFullPath(getEfcCarJelly(seriesImages, seriesProfile.series[0].seriesYear, seriesProfile.series[0].series) || '');
    }
    return carJelly || blankCarImg;
  }, [seriesProfile, seriesImages]);

  const leaseCostShareDates = costShareDatesMap.get(profile)?.leaseCostShareDates;
  const hasLeaseCostShareDates = FEATURE_OR_4154 && leaseForm.inputs.isStandalone && leaseCostShareDates && leaseCostShareDates.length > 0;

  return (
    <div className={styles.vehicleInfo}>
      <div className={styles.vehicleInfoLeft}>
        <CarJellyImage source={carJellyString ?? leaseForm.carJelly} />
      </div>
      <div className={styles.vehicleInfoRight}>
        {isAdvertised && <VehicleInfoRight leaseCard={leaseCard} leaseForm={leaseForm} isDisabled={isDisabled} />}
        {isSETUser() && <ContestNumber optionTypeName={AdminConstants.OfferTypes.LEASE} contestNumber={leaseCard.contestNumber} id={leaseCard.uid} handleUpdate={handleOnUpdate} />}
        <OfferExclusions
          disabled={leaseForm.isExample || isDisabled}
          id={leaseCard.uid}
          disabledVehicles={disabledVehicles}
          excludedModels={leaseCard.excludedVehicles}
          includedModels={leaseCard.includedVehicles}
          isEditEnabled={leaseForm.inputs.isStandalone || leaseCard.isDuplicate}
          onSubmit={handleExclusions}
          modelCode={isAdvertised && !isGSTUser() ? leaseForm.inputs.modelCode.toString() : ''}
        />
        <BlockText topText="Compatible States" bottomText={returnStatesList(region, leaseCard.includedStates)} />
        <OfferCompatibleOffers
          compatibileOffers={leaseCard.compatabilityList}
          compatibility={leaseCard.compatibility}
          toggleCompatibileOffer={leaseCard.toggleCompatibileOffer}
          optionType={AdminConstants.OPTION_TYPE_NAMES.LEASE}
          onSubmit={handleCompatibleOffers}
          disabled={leaseForm.isExample || isDisabled}
          isEditable={leaseForm.inputs.isStandalone || leaseCard.isDuplicate}
          id={leaseCard.id}
        />
        {hasLeaseCostShareDates && <EnhCostShareAvailabilities id={leaseCard.id} costShareDates={costShareDatesMap.get(profile)?.leaseCostShareDates as EnhTfsCostShare[]} />}
      </div>
    </div>
  );
};

export default observer(VehicleInfo);
