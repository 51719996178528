import { makeAutoObservable } from 'mobx';
import { AdminConstants, AdminModels, CashCalcFns, FeatureFlags, getDefaultTfsShare, isFlatCostOfferType } from 'oat-admin-common';
import { assignStringValue, dateFormatISOString, dateStringToDate, OfferTypes, uuidv4 } from 'oat-common-ui';
import AccordionModel from '../../../components/Accordion/models/AccordionModel';
import { FEATURE_OR_5454_PHASE_2 } from '../../../constants/global';
import { EnhancedCostShare, Offer, Offering, SeriesMapping, SeriesProfile, TfsContract, VehicleInput } from '../../../gql/generated';
import { assignNumberValue, defaultBooleanToUndefined } from '../../../utils/assignValue';
import { getIncludedExcludedVehicles } from '../../../utils/getIncludedExcludedVehicles';
import { getDefaultPaymentMethod, getDefaultPaymentTo, getDefaultSystemToPay, tfsContractDefaultChecked } from '../../../utils/getMiscOfferFieldsDefaultValue';
import { calculateOfferBalance, calculateOfferCost, calculateOfferEarning } from '../../../utils/offerCalculations';
import validator from '../../../utils/validator';
import { createMiscCard } from '../../createOffer/utils/createMiscCard';
import { getCompatibilityListByOfferType } from '../../createOffer/utils/getCompatibilityListByOfferType';
import { calculatePnvAmountValues } from '../components/MiscCard/utils/calculatePnvAmountValues';

type VehicleMake = {
  make: string;
  model: string;
  years: string;
};

interface EnhCostShareFields {
  eligibleOfferId?: string;
  isModalConfirmed?: boolean;
  isNotificationConfirmed: boolean;
  isReplacing: boolean;
  removingReason?: string;
  showEnhCostShareModal: boolean;
}

export class MiscCardFields {
  type: string = FEATURE_OR_5454_PHASE_2 ? AdminConstants.CASH_TYPES.COLLEGE : AdminConstants.CASH_TYPES.COLLEGE_GRADUATE;
  defaultPerUnitCost: number | undefined = 0;
  combinedPerUnitCost = '';
  tfsContractRequired? = false;
  name = '';
  paymentTo = '';
  paymentMethod = '';
  systemToPay = '';
  tfsCostShare = '0';
  defaultTfsCostShare = '0';
  tfsCostShareCap = '0';
  defaultTfsCostShareCap = '0';
  note = '';
  tdaNote = '';
  modelNote = '';
  startDate: Date | undefined;
  endDate: Date | undefined;
  defaultStartDate: Date | undefined;
  defaultEndDate: Date | undefined;
  compatibileOffers: string[] = [];
  compatibility: AdminModels.Compatibility = {
    defaultTypes: [],
    incompatibleTypes: [],
    optionalTypes: [],
  };
  vehicles: VehicleInput[] = [];
  includedVehicles: VehicleInput[] = [];
  excludedVehicles: VehicleInput[] = [];
  includedStates: string[] = [];
  excludedStates: string[] = [];
  maxNoOfCertificates? = 0;
  maxDownPaymentAmount? = 0;
  transferable? = false;
  targetedAudience? = '';
  impactedBrand? = '';
  impactedModel? = '';
  vendorCodes? = '';
  description? = '';
  noOfMonths? = 0;
  maxDollarAmountPerMonth? = 0;
  returningBrands? = '';
  returningSeries? = '';
  salesCode: string[] = [];
  modelRestrictions: VehicleMake[] | undefined = [];
  competitorDetails: VehicleMake[] | undefined = [
    {
      make: '',
      model: '',
      years: '',
    },
  ];
  pnv? = '';
  giftDelivery? = '';
  maxNoOfDays? = '';
  accessoryCodes?: string;
  isSpecialEdition = false;
  specialEditionPackage?: string;
  tfsContracts?: TfsContract[];
  isEnhanced?: boolean;
  forecastedSales = 0;
  estimatedCost = 0;
  tfsCost = 0;
  enhTfsCost = 0;
  offerEarnings = 0;
  offerCost = 0;
  offerBalance = 0;
  offerTfsCost = 0;
  offerEnhTfsCost = 0;
  contestNumber = '';
  contestNumberOfferId = '';
  vehiclesAccordion = new AccordionModel();
  isInclusions = false;
  isValidEnR = true;

  constructor() {
    makeAutoObservable(this);
  }
}

class MiscCardModel {
  uid = uuidv4();
  fields = new MiscCardFields();
  id = '';
  rev = '';
  parentId = '';
  optionTypeName = `${OfferTypes.MISCELLANEOUS}`;
  isAdvertised = true;
  isStandAlone = true;
  isMultiSeries = false;
  showTfsModal = false;
  isDuplicate = false;
  isGst = false;
  regionCode = '';
  isNationalAndIsAtc = false;

  isEligibleForEnhCostShare?: boolean = undefined;
  enhCostShareOfferId?: string = undefined;
  isEnhCostShareAccepted?: boolean = undefined;
  isEnhCostShareUpdated?: boolean = undefined;
  isEnhCostShareRemoved?: boolean = undefined;
  enhancedCostShare?: EnhancedCostShare = undefined;

  enhCostShareFields: EnhCostShareFields = {
    eligibleOfferId: undefined,
    isModalConfirmed: undefined,
    isNotificationConfirmed: false,
    isReplacing: false,
    removingReason: undefined,
    showEnhCostShareModal: false,
  };

  initialData: { startDate: string; endDate: string; vehicles: VehicleInput[]; contracts: TfsContract[] } = {
    startDate: '',
    endDate: '',
    vehicles: [],
    contracts: [],
  };

  seriesProfileNationalId = '';

  nationalOffer: Offer | undefined; // Used when recreating the misc this

  constructor() {
    makeAutoObservable(this);
  }

  // To set data within the store, not the component level to satisfy strict mode
  setCardData = (offer: Offer, seriesMapping: SeriesMapping[], seriesProfile: SeriesProfile, offering?: Offering) => {
    createMiscCard(this, seriesMapping, { regional: offer, national: this.nationalOffer }, seriesProfile, offering?.startDate, offering?.endDate);

    this.regionCode = offer.regionCode;
    this.updateInitialData();
  };

  updateField = <T extends keyof MiscCardFields, V extends MiscCardFields[T]>(field: T, value: V, recalcEstCosts = false) => {
    this.fields[field] = value;
    if (recalcEstCosts) {
      this.updateEstCosts();
    }
  };

  updateRev = (rev = '') => {
    this.rev = rev;
  };

  updateAdvertised = (advertised: boolean) => {
    this.isAdvertised = advertised;
  };

  updateTfsModal = (change: boolean) => {
    this.showTfsModal = change;
  };

  updateFieldsOnChangeType = (value: string) => {
    this.fields['name'] = value;
    this.fields['type'] = value;
    this.fields['tfsContractRequired'] = tfsContractDefaultChecked(value);
    this.fields['paymentTo'] = getDefaultPaymentTo(value);
    this.fields['paymentMethod'] = getDefaultPaymentMethod(this.fields.paymentTo);
    this.fields['systemToPay'] = getDefaultSystemToPay(value);
    this.setCompatibileOffers(getCompatibilityListByOfferType(value as AdminConstants.OPTION_TYPE_NAMES));

    if (value === AdminConstants.CASH_TYPES.NON_CASH_CERTIFICATE) {
      this.fields['pnv'] = '0';
    }
  };

  updateSalesCode = (code: string, remove = false) => {
    if (this.fields['salesCode'].includes(code) && !remove) {
      return;
    }

    if (remove) {
      this.fields['salesCode'] = this.fields['salesCode'].filter(_code => _code !== code);
    } else {
      if (code === 'All') {
        this.fields['salesCode'] = ['All'];
      } else {
        this.fields['salesCode'] = this.fields['salesCode'].filter(_code => _code !== 'All');
        this.fields['salesCode'].push(code);
      }
    }

    this.fields['salesCode'].sort();
  };

  updateVehicles = (excludedList: VehicleInput[], includedList: VehicleInput[]) => {
    this.fields.includedVehicles = includedList;
    this.fields.excludedVehicles = excludedList;
  };

  updateArray = (field: 'modelRestrictions' | 'competitorDetails', action?: string, index?: number, value?: VehicleMake) => {
    if (action === 'add') {
      this.fields[field]?.push({ make: '', model: '', years: '' });
    } else if (action === 'remove') {
      this.fields[field]?.splice(Number(index), 1);
    } else {
      if (value) {
        this.fields[field]?.splice(Number(index), 1, value);
      }
    }
  };

  toggleIsInclusions = () => {
    this.fields.isInclusions = !this.fields.isInclusions;
  };

  updateAmountOrPnv = (type: string, pnvVal: string) => {
    const { combinedPerUnitCost, pnv } = calculatePnvAmountValues(type, pnvVal, this.fields.defaultPerUnitCost);
    this.fields.combinedPerUnitCost = combinedPerUnitCost;
    this.fields.pnv = pnv;
    this.updateEstCosts();
  };

  updateEstCosts = () => {
    const { estCost, tfsEstCost, enhTfsEstCost } = CashCalcFns.calculateEstimatedBuyDownCost(
      assignNumberValue(this.fields.combinedPerUnitCost),
      assignNumberValue(this.fields.defaultPerUnitCost),
      assignNumberValue(this.fields.tfsCostShare) / 100.0,
      0,
      assignNumberValue(this.fields.tfsCostShareCap),
    );

    this.fields.estimatedCost = estCost;
    this.fields.tfsCost = tfsEstCost;
    this.fields.enhTfsCost = enhTfsEstCost;
  };

  updateOfferCosts = (ryoEarnings: number) => {
    if (this.fields.estimatedCost < 0) {
      this.fields.offerEarnings = calculateOfferEarning(this.fields.forecastedSales, ryoEarnings, Math.abs(this.fields.estimatedCost));
      this.fields.offerCost = 0;
    } else if (isFlatCostOfferType(this.fields.type)) {
      this.fields.offerEarnings = 0;
      this.fields.offerCost = Math.round(Number(this.fields.combinedPerUnitCost));
    } else {
      this.fields.offerEarnings = calculateOfferEarning(this.fields.forecastedSales, ryoEarnings);
      this.fields.offerCost = calculateOfferCost(this.fields.forecastedSales, this.fields.estimatedCost);
    }

    this.fields.offerTfsCost = calculateOfferCost(this.fields.forecastedSales, this.fields.tfsCost);
    this.fields.offerEnhTfsCost = calculateOfferCost(this.fields.forecastedSales, this.fields.enhTfsCost);
    this.fields.offerBalance = calculateOfferBalance(this.fields.offerEarnings, this.fields.offerCost);
  };

  canSave = () => {
    return !this.hasError && !this.hasPNVError && !this.hasDateError;
  };

  setVehicles = (vehicles: VehicleInput[]) => {
    const { excludedVehicles, includedVehicles } = getIncludedExcludedVehicles(vehicles);
    this.fields.includedVehicles = includedVehicles;
    this.fields.excludedVehicles = excludedVehicles;
  };

  setEnhanceCostShareValues = (offer: Offer | undefined) => {
    this.enhCostShareOfferId = assignStringValue(offer?.enhCostShareOfferId, undefined);
    this.isEnhCostShareAccepted = defaultBooleanToUndefined(offer?.isEnhCostShareAccepted);
    this.isEligibleForEnhCostShare = defaultBooleanToUndefined(offer?.isEligibleForEnhCostShare);
    this.isEnhCostShareUpdated = defaultBooleanToUndefined(offer?.isEnhCostShareUpdated);
    this.isEnhCostShareRemoved = defaultBooleanToUndefined(offer?.isEnhCostShareRemoved);
  };

  setIsEnhCostShareUpdated = (isUpdated: boolean) => {
    this.isEnhCostShareUpdated = isUpdated;
  };

  setEnhancedCostShare = (enhCostShare?: EnhancedCostShare) => {
    this.enhancedCostShare = enhCostShare;
  };

  updateEnhCostShareField = <T extends keyof EnhCostShareFields, V extends EnhCostShareFields[T]>(field: T, value: V) => {
    this.enhCostShareFields[field] = value;
  };

  resetEnhCostShareFields = () => {
    this.enhCostShareFields = {
      eligibleOfferId: undefined,
      isModalConfirmed: undefined,
      isNotificationConfirmed: false,
      isReplacing: false,
      removingReason: undefined,
      showEnhCostShareModal: false,
    };
  };

  applyEnhCostShare = () => {
    this.updateField('tfsCostShare', assignStringValue(this.enhancedCostShare?.tfsCostShare));
    this.updateField('tfsCostShareCap', assignStringValue(this.enhancedCostShare?.tfsCostShareCap));

    this.isEnhCostShareAccepted = true;
    this.isEligibleForEnhCostShare = true;
    this.enhCostShareOfferId = this.enhancedCostShare?.offerId;

    this.updateEstCosts();
  };

  removeEnhCostShare = () => {
    this.updateField('tfsCostShare', getDefaultTfsShare(this.optionTypeName, this.regionCode).toString());
    this.updateField('tfsCostShareCap', '0');

    this.updateEstCosts();

    this.enhCostShareOfferId = '';
    this.isEligibleForEnhCostShare = false;
    this.isEnhCostShareAccepted = undefined;
    this.enhancedCostShare = undefined;
  };

  cancelEnhCostShareAction = () => {
    // Cancel remove
    if (this.enhCostShareFields.removingReason) {
      this.handleResetToInitialData();
      this.resetEnhCostShareFields();
    }

    // Cancel apply
    else if (this.enhCostShareFields.eligibleOfferId || this.enhCostShareFields.isReplacing) {
      this.isEnhCostShareAccepted = false;
      this.isEligibleForEnhCostShare = this.enhCostShareFields.isReplacing;
      this.enhCostShareOfferId = this.enhCostShareFields.isReplacing ? this.enhCostShareFields.eligibleOfferId : '';
      this.enhancedCostShare = undefined;
    }
  };

  updateInitialData = () => {
    this.initialData = {
      startDate: dateFormatISOString(this.fields.startDate),
      endDate: dateFormatISOString(this.fields.endDate),
      vehicles: this.fields.vehicles,
      contracts: this.fields.tfsContracts ?? [],
    };
  };

  handleResetToInitialData = () => {
    this.setVehicles(this.initialData.vehicles);
    this.updateField('tfsContracts', this.initialData.contracts);
    this.updateField('tfsContractRequired', Boolean(this.initialData.contracts?.length));
    this.updateField('startDate', dateStringToDate(this.initialData.startDate));
    this.updateField('endDate', dateStringToDate(this.initialData.endDate));
  };

  // combined per unit cost error
  get hasError() {
    const validatorConfig: { required: boolean; min?: number } = {
      required: true,
    };

    if (!this.isStandAlone && this.fields.type !== AdminConstants.CASH_TYPES.FINAL_PAY) {
      validatorConfig.min = this.fields.defaultPerUnitCost;
    }

    return validator(this.fields.combinedPerUnitCost, validatorConfig) !== undefined;
  }

  get hasPNVError() {
    return validator(this.fields.pnv, { required: true, min: this.fields.type === AdminConstants.CASH_TYPES.FINAL_PAY ? undefined : 0 }) !== undefined;
  }

  get hasDateError() {
    if (!this.fields.endDate || !this.fields.startDate) {
      return true;
    }

    const endDate = dateStringToDate(this.fields.endDate);
    const startDate = dateStringToDate(this.fields.startDate);

    return endDate.getTime() < startDate.getTime();
  }

  get hasNoteError() {
    return validator(this.fields.note, { required: true }) !== undefined && this.fields.type === AdminConstants.CASH_TYPES.FINAL_PAY;
  }

  get hasTargetedAudienceError() {
    return (
      validator(this.fields.targetedAudience, {
        required:
          FeatureFlags.FEATURE_OR_3782 &&
          this.isStandAlone &&
          (this.fields.type === (FEATURE_OR_5454_PHASE_2 ? AdminConstants.CASH_TYPES.COLLEGE : AdminConstants.CASH_TYPES.COLLEGE_GRADUATE) ||
            this.fields.type === AdminConstants.CASH_TYPES.MILITARY),
      }) !== undefined
    );
  }

  get hasConquestCompetitorDetailsError() {
    return AdminConstants.CASH_TYPES.CONQUEST === this.fields.type &&
      (this.fields.competitorDetails?.length === 0 ||
        this.fields.competitorDetails?.some(model => !model.make || !model.model || !model.years));
  }

  get hasTradeInAssitanceModelRestrictionsError() {
    return AdminConstants.CASH_TYPES.TRADE_IN_ASSISTANCE === this.fields.type &&
      (this.fields.modelRestrictions?.length === 0 ||
        this.fields.modelRestrictions?.some(model => !model.make || !model.model || !model.years));
  }

  get hasCampaignError() {
    return AdminConstants.CASH_TYPES.CAMPAIGN === this.fields.type &&
      (validator(this.fields.impactedBrand, { required: true }) !== undefined || validator(this.fields.impactedModel, { required: true }) !== undefined)
  }

  get isDisabled() {
    return Boolean(this.isEnhCostShareUpdated) || this.isNationalAndIsAtc || Boolean(this.isEnhCostShareRemoved);
  }

  setIsNationalAndIsAtc = (isNationalAndIsAtc: boolean) => {
    this.isNationalAndIsAtc = isNationalAndIsAtc;
  };

  toggleIsSpecialEdition = () => {
    this.fields.isSpecialEdition = !this.fields.isSpecialEdition;
    if (!this.fields.isSpecialEdition) {
      this.fields.specialEditionPackage = '';
      this.fields.accessoryCodes = '';
    }
  };

  get isSpecialEditionValid() {
    if (!this.fields.isSpecialEdition) {
      return true;
    } else {
      return this.isGst && Boolean(this.fields.accessoryCodes);
    }
  }

  setCompatibileOffers = (compatibileOffers?: AdminModels.Compatibility) => {
    this.fields.compatibileOffers = compatibileOffers?.defaultTypes || [];
  };

  setCompatibility = (compatability: AdminModels.Compatibility) => {
    this.fields.compatibility = compatability;
  };

  toggleCompatibileOffer = (offerName: string) => {
    const compOfferIndex = this.fields.compatibileOffers.findIndex(offer => offer === offerName);

    if (compOfferIndex === -1) {
      this.fields.compatibileOffers.push(offerName);
    } else {
      this.fields.compatibileOffers.splice(compOfferIndex, 1);
    }
  };
}

export default MiscCardModel;
