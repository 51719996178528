import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { Checkbox } from 'oat-common-ui';
import { ReactNode } from 'react';
import EnhCostShareNotification from '../../../../../components/EnhCostNotification/EnhCosShareNotification';
import EnhTfsCostShareNotification from '../../../../../components/EnhCostNotification/EnhTfsCostShareNotification';
import Note from '../../../../../components/Note';
import { FEATURE_OR_2477, FEATURE_OR_4154, Status } from '../../../../../constants/global';
import { LeaseOfferDetails } from '../../../../../gql/generated';
import useStores from '../../../../../stores/useStores';
import useUserInfo from '../../../../../utils/useUserInfo';
import LeaseCardModel from '../../../models/LeaseCardModel';
import LeaseFormModel from '../../../models/LeaseFormModel';
import LeaseTermModel from '../../../models/LeaseTermModel';
import OfferBody, { OfferBodyLeft, OfferBodyRight } from '../../OfferBody';
import LeaseErrorMessages from '../LeaseAdForm/LeaseErrorMessages';
import styles from '../LeaseAdForm/styles.module.scss';
import transformLeaseDetails from '../LeaseAdForm/transformLeaseDetails';
import useSaveLeaseData from '../LeaseAdForm/useSaveLeaseData';
import ExampleTabs from '../components/ExampleTabs';
import LeaseDefaultTfsCostShare from '../components/LeaseDefaultTfsCostShare';
import { LeaseFormLayout, LeaseFormLayoutBody, LeaseFormLayoutContent, LeaseFormLayoutHeader } from '../components/LeaseFormLayout';
import LeaseTitle from '../components/LeaseTitle';

interface Props {
  leaseCard: LeaseCardModel;
  leaseTerm: LeaseTermModel;
  leaseForm: LeaseFormModel;
  leftContent: ReactNode;
  rightHeaderContent: ReactNode;
  rightBodyContent: ReactNode;
  onInputChange: (skipDelay?: boolean, leaseDetails?: LeaseOfferDetails[] | undefined) => void;
  onIsUpToApplied: (isUpToApplied: boolean) => void;
  areTfsEnhRatesUsed?: boolean;
}

const { OPTION_TYPE_NAMES } = AdminConstants;

/**
 * All lease cards essentially share the same format and util functions
 */
const LeaseCardBase = ({ leaseCard, leaseTerm, leaseForm, leftContent, rightHeaderContent, rightBodyContent, onInputChange, onIsUpToApplied, areTfsEnhRatesUsed }: Props) => {
  const { programDetailsStore } = useStores();
  const { isGSTUser } = useUserInfo();
  const { id } = leaseCard;
  const { inputs, updateInput, toggleUpTo } = leaseForm;
  const { handleRemoveEnhCostShareNotification, handleEnhCostData, handleEnhTfsCostData, handleInputChange } = useSaveLeaseData(leaseCard, leaseTerm, leaseForm);

  const leaseId = `lease-${id}`;

  // Up to checkbox on change
  const handleToggleUpTo = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleUpTo();
    onIsUpToApplied(event.target.checked);
  };

  return (
    <LeaseDefaultTfsCostShare leaseCard={leaseCard} leaseForm={leaseForm}>
      <section id={leaseId}>
        <LeaseTitle leaseCard={leaseCard} leaseTerm={leaseTerm} leaseForm={leaseForm} onSave={(skip = true, ld) => onInputChange(skip, ld)} />
        <OfferBody>
          <OfferBodyLeft small>{leftContent}</OfferBodyLeft>
          <OfferBodyRight>
            <LeaseFormLayout>
              {leaseForm.inputs.isAdvertised && <ExampleTabs leaseTerm={leaseTerm} />}
              <LeaseFormLayoutHeader>{rightHeaderContent}</LeaseFormLayoutHeader>
              <LeaseFormLayoutBody>
                <LeaseFormLayoutContent>{rightBodyContent}</LeaseFormLayoutContent>

                {/* Security deposit waiver */}
                <div className={styles.security}>
                  <input type="checkbox" name="deposit" disabled checked />
                  <label htmlFor="deposit" className={styles.allOtherLabels}>
                    Security deposit waived
                  </label>
                </div>

                {/* Up to chbox. Show if there are multiple terms and show in highest term only */}
                {!leaseForm.isExample && leaseForm.inputs.term !== leaseCard.minTerm && leaseForm.inputs.term === leaseCard.maxTerm && (
                  <div className={styles.uptoContainer}>
                    <Checkbox
                      id={`upto-${id}`}
                      isChecked={inputs.isUpToApplied}
                      onChange={handleToggleUpTo}
                      isDisabled={programDetailsStore.offering.status === Status.MEETS_GUIDELINES || leaseCard.isDisabled}
                    />
                    <span className={styles.uptoLabel}>Apply this RCF, Subvention cash, and TFS Cost Share to all lower terms</span>
                  </div>
                )}
              </LeaseFormLayoutBody>

              {/* Errors */}
              <LeaseErrorMessages leaseCard={leaseCard} leaseForm={leaseForm} />

              {/* Notes */}
              <div>
                <Note
                  key={`note-${id}`}
                  label="Note"
                  value={inputs.note}
                  disabled={leaseCard.isDisabled}
                  isRequired={areTfsEnhRatesUsed}
                  onChange={note => {
                    updateInput('note', note);
                    onInputChange();
                  }}
                />
                <Note
                  key={`tdaNote-${id}`}
                  label="TDA Note"
                  value={inputs.tdaNote}
                  disabled={leaseCard.isDisabled}
                  onChange={note => {
                    updateInput('tdaNote', note);
                    onInputChange();
                  }}
                />
              </div>

              {FEATURE_OR_4154 && !programDetailsStore.rgnlAlt.isSeriesConfirmed && leaseForm.inputs.isStandalone && (
                <EnhTfsCostShareNotification
                  enhTfsCostShareOfferId={leaseCard.enhTfsCostShareId}
                  isEnhTfsCostShareUpdated={leaseCard.isEnhTfsCostShareUpdated}
                  id={leaseCard.id}
                  onConfirm={() => {
                    leaseCard.setIsEnhTfsCostShareUpdated(false);
                    handleInputChange(false, [transformLeaseDetails(leaseTerm, leaseForm)], false, true);
                  }}
                  onApply={() => {
                    leaseCard.setIsEnhTfsCostShareUpdated(false);
                    leaseCard.updateEnhancedTfsCostShareField('isNotificationConfirmed', true);
                    leaseCard.updateEnhancedTfsCostShareField('offerId', leaseCard.enhTfsCostShareId);

                    handleEnhTfsCostData();
                    leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);

                    handleInputChange(false, [transformLeaseDetails(leaseTerm, leaseForm)], false, true);
                  }}
                  onCancel={() => {
                    leaseCard.setIsEnhTfsCostShareUpdated(false);
                    leaseCard.updateEnhancedTfsCostShareField('isNotificationConfirmed', false);
                    handleInputChange(false, [transformLeaseDetails(leaseTerm, leaseForm)], false, false);
                  }}
                />
              )}

              {FEATURE_OR_2477 && !programDetailsStore.rgnlAlt.isSeriesConfirmed && (
                <EnhCostShareNotification
                  enhCostShareOfferId={leaseCard.enhCostShareOfferId}
                  id={leaseCard.id}
                  isEnhCostShareUpdated={leaseCard.isEnhCostShareUpdated}
                  isEnhCostShareRemoved={leaseCard.isEnhCostShareRemoved}
                  offerType={OPTION_TYPE_NAMES.LEASE}
                  onApply={() => {
                    leaseCard.setIsEnhCostShareUpdated(false);
                    leaseCard.updateEnhancedCostShareField('isNotificationConfirmed', true);
                    leaseCard.updateEnhancedCostShareField('offerId', leaseCard.enhCostShareOfferId);

                    handleEnhCostData();

                    if (leaseForm.inputs.isAdvertised && !isGSTUser()) {
                      leaseForm.recalculate(undefined, 'tfsShare');
                      leaseTerm.syncMasterAndExamples();
                    }

                    leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);
                    handleInputChange(false, [transformLeaseDetails(leaseTerm, leaseForm)], false, true);
                  }}
                  onHide={handleRemoveEnhCostShareNotification}
                  onCancel={() => handleRemoveEnhCostShareNotification(undefined, true)}
                />
              )}
            </LeaseFormLayout>
          </OfferBodyRight>
        </OfferBody>
      </section>
    </LeaseDefaultTfsCostShare>
  );
};

export default observer(LeaseCardBase);
