import { ModelCodes, VehicleInput } from '../../../gql/generated';
import AccordionItemModel from '../models/AccordionItemModel';

const mapVehicleModelCodeFromAccordion = (accItems: AccordionItemModel[], vehicleInputs: ModelCodes[]): VehicleInput[] => {
  const vehicles: VehicleInput[] = [];

  vehicleInputs.forEach(v => {
    const yearItem = accItems.find(i => i.value === v.year);
    if (yearItem) {
      const seriesItem = yearItem.items.find(si => si.value === v.series);
      if (seriesItem) {
        v.models.forEach(vModel => {
          const modelItem = seriesItem.items.find(mi => mi.value === vModel.modelCode);
          if (modelItem && modelItem.checked) {
            vehicles.push({
              isInclusion: true,
              modelCode: modelItem.value,
              series: seriesItem.value,
              seriesYear: yearItem.value,
              vehicleDescription: modelItem.description,
            });
          }
        });
      }
    }
  });

  return vehicles;
};

export default mapVehicleModelCodeFromAccordion;
