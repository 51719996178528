import clsx from 'clsx';
import { Button, IconNames, OATIcon, Tooltip } from 'oat-common-ui';
import styles from './styles.module.scss';

interface Props {
  id: string;
  message: string;
  iconName: IconNames;
  onClickHandler: () => void;
  buttonStyle?: string;
}

const TooltipButton = ({ id, message, iconName, onClickHandler, buttonStyle }: Props) => {
  return (
    <Tooltip id={id} message={message}>
      <Button onClick={onClickHandler} className={clsx(styles.iconBtn, buttonStyle)}>
        <OATIcon icon={iconName} size={14} />
      </Button>
    </Tooltip>
  );
};

export default TooltipButton;
