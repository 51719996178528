import { observer } from 'mobx-react-lite';
import { BlockUi, DefaultTable, OfferingsLayout, sortFieldsHelper } from 'oat-common-ui';
import { useState } from 'react';
import MainFooter from '../../components/MainFooter';
import MainHeader from '../../components/MainHeader';
import FYBalance from '../../components/MainHeader/FYBalance';
import MainSideNav from '../../components/MainSideNav';
import { Offering } from '../../gql/generated';
import useStores from '../../stores/useStores';
import DashboardRow from './DashboardRow';
import { processDashboardColumns } from './dashboardColumns';

const DashboardComponent = () => {
  const {
    dashboardStore,
    userInfoStore: { isNational, getRegionNameFull },
  } = useStores();

  const [sortFieldActive, setSortFieldActive] = useState('startDate');
  const [sortAscActive, setSortAscActive] = useState(true);
  const [sortFieldArchived, setSortFieldArchived] = useState('startDate');
  const [sortAscArchived, setSortAscArchived] = useState(false);
  const [isCreatingAtc, setIsCreatingAtc] = useState(false);

  const renderRow = (offerings: Offering[], isActive: boolean) => {
    return (
      <>
        {offerings.map(item => {
          return <DashboardRow key={item.id} item={item} isActive={isActive} showCreatingAtcMsg={setIsCreatingAtc} />;
        })}
      </>
    );
  };

  const onSortActive = (field: string, asc: boolean) => {
    setSortFieldActive(field);
    setSortAscActive(asc);
  };

  const onSortArchived = (field: string, asc: boolean) => {
    setSortFieldArchived(field);
    setSortAscArchived(asc);
  };

  const activeOfferings = dashboardStore.active.slice().sort(sortFieldsHelper(sortFieldActive, sortAscActive));
  const archivedOfferings = dashboardStore.archived.slice().sort(sortFieldsHelper(sortFieldArchived, sortAscArchived));

  const content = () => {
    return (
      <>
        <BlockUi blocking={isCreatingAtc} title="Creating ATC…" />
        <OfferingsLayout
          renderHeader={null}
          renderActiveTable={
            <DefaultTable
              id="activeTbl"
              title="Active Incentive Periods"
              columns={processDashboardColumns(isNational(), true)}
              renderRows={renderRow(activeOfferings, true)}
              onSort={onSortActive}
              defaultSortField={'startDate'}
              defaultSortAscending={false}
            />
          }
          renderArchiveTable={
            <DefaultTable
              id="archiveTbl"
              title="Previous Incentive Periods"
              collapsable
              columns={processDashboardColumns(isNational(), false)}
              renderRows={renderRow(archivedOfferings, false)}
              onSort={onSortArchived}
              defaultSortField={'startDate'}
              defaultSortAscending={false}
            />
          }
        >
          <MainFooter secondaryTheme />
        </OfferingsLayout>
      </>
    );
  };

  const rightContent = () => {
    return <FYBalance balances={dashboardStore.processedOfferings} fyBalance={dashboardStore.fyBalance} />;
  };

  if (isNational()) {
    return <MainSideNav>{content()}</MainSideNav>;
  }

  return (
    <>
      <MainHeader breadCrumbs={[{ name: getRegionNameFull() }]} showRightContent={!isNational()} rightContent={rightContent()} />
      {content()}
    </>
  );
};

export default observer(DashboardComponent);
