import { AdminConstants, isFlatCostOfferType } from 'oat-admin-common';
import { dateFormatISOString } from 'oat-common-ui';
import { FEATURE_OR_5454_PHASE_2 } from '../../../constants/global';
import { CreateMiscOfferInput, SaveMiscOfferInput } from '../../../gql/generated';
import validator from '../../../utils/validator';
import MiscCardModel from '../../ProgramDetails/models/MiscCardModel';
import { getTfsContractsWithTierTerms } from '../utils/getTfsContractsWithTierTerms';

const groupOne = [
  AdminConstants.CASH_TYPES.DEALER_POINTS.valueOf(),
  AdminConstants.CASH_TYPES.DEALER_PERCENT_STAIR_STEP.valueOf(),
  AdminConstants.CASH_TYPES.DEALER_HIT_AND_WIN.valueOf(),
  AdminConstants.CASH_TYPES.DEALER_POT_OF_GOLD.valueOf(),
  AdminConstants.CASH_TYPES.DEALER_TRIP_INCENTIVE.valueOf(),
  AdminConstants.CASH_TYPES.DEALERSHIP_PERSONNEL_VARIABLE_PAYMENT.valueOf(),
  AdminConstants.CASH_TYPES.DEALERSHIP_PERSONNEL_POINTS.valueOf(),
  AdminConstants.CASH_TYPES.DEALERSHIP_PERSONNEL_NON_VIN.valueOf(),
  AdminConstants.CASH_TYPES.DEALERSHIP_PERSONNEL_CASH_BASH.valueOf(),
  AdminConstants.CASH_TYPES.DEALERSHIP_PERSONNEL_HIT_AND_WIN.valueOf(),
  AdminConstants.CASH_TYPES.DEALERSHIP_PERSONNEL_POT_OF_GOLD.valueOf(),
  AdminConstants.CASH_TYPES.DEALERSHIP_PERSONNEL_TRIP.valueOf(),
  AdminConstants.CASH_TYPES.DEALER_WHOLESALE_PROGRAM.valueOf(),
  AdminConstants.CASH_TYPES.DEALER_UNIT_STAIR_STEP.valueOf(),
  AdminConstants.CASH_TYPES.DEALER_SALES_TRACKING.valueOf(),
  AdminConstants.CASH_TYPES.OTHER_DEALERSHIP_PERSONNEL_FLAT_CASH.valueOf(),
  AdminConstants.CASH_TYPES.DEALERSHIP_PERSONNEL_STAIR_STEP.valueOf(),
  AdminConstants.CASH_TYPES.DEALERSHIP_PRESONNEL_SALES_TRACKING.valueOf(),
  AdminConstants.CASH_TYPES.DEALERSHIP_PERSONNEL_OVERRIDE.valueOf(),
  AdminConstants.CASH_TYPES.DEALERSHIP_PERSONNEL_TFS_CONTRACT_REWARD.valueOf(),
  AdminConstants.CASH_TYPES.DEALERSHIP_PERSONNEL_PERCENT_STAIR_STEP_PERSONNEL_SALES.valueOf(),
  AdminConstants.CASH_TYPES.DEALERSHIP_PERSONNEL_PERCENT_STAIR_STEP_SALES.valueOf(),
  AdminConstants.CASH_TYPES.RETENTION_PROGRAM.valueOf(),
  AdminConstants.CASH_TYPES.SALES_MANAGER_FLAT_CASH.valueOf(),
  AdminConstants.CASH_TYPES.SALES_PERSON_FLAT_CASH.valueOf(),
];

const groupTwo = [
  AdminConstants.CASH_TYPES.BONUS.valueOf(),
  AdminConstants.CASH_TYPES.EVENT.valueOf(),
  AdminConstants.CASH_TYPES.VSPP.valueOf(),
  AdminConstants.CASH_TYPES.DEALER_TFS_SUBVENTION_CASH.valueOf(),
  AdminConstants.CASH_TYPES.DEALER_TFS_CASH.valueOf(),
  AdminConstants.CASH_TYPES.DEALER_TFS_APR_SUBVENTION_CASH.valueOf(),
  AdminConstants.CASH_TYPES.DEALER_TFS_LEASE_SUBVENTION_CASH.valueOf(),
  AdminConstants.CASH_TYPES.TFS_APR_CASH.valueOf(),
  AdminConstants.CASH_TYPES.TFS_LEASE_CASH.valueOf(),
  AdminConstants.CASH_TYPES.DEALER_CASH.valueOf(),
];

const groupThree = [AdminConstants.CASH_TYPES.LOYALTY.valueOf(), AdminConstants.CASH_TYPES.LEASE_LOYALTY.valueOf(), AdminConstants.CASH_TYPES.TARGETED_CERTIFICATE.valueOf()];

const validateInputs = (inputs: any[]) => {
  let hasError = false;

  inputs.forEach(input => {
    if (validator(input, { required: true, min: 1 }) !== undefined) {
      hasError = true;
    }
  });

  return hasError;
};

export const getMiscOfferInputByOptionTypeName = (miscCard: MiscCardModel, forCreate = false) => {
  let hasRequiredFieldError = false;
  let miscProps: CreateMiscOfferInput | SaveMiscOfferInput = {
    startDate: dateFormatISOString(miscCard.fields.startDate),
    endDate: dateFormatISOString(miscCard.fields.endDate),
    note: miscCard.fields.note,
    tdaNote: miscCard.fields.tdaNote,
    compatibilityList: miscCard.fields.compatibileOffers,
    excludedStates: miscCard.fields.excludedStates,
    includedStates: miscCard.fields.includedStates,
    name: miscCard.fields.name,
    combinedPerUnitCost: Number(miscCard.fields.combinedPerUnitCost),
    vehicles: [...miscCard.fields.excludedVehicles, ...miscCard.fields.includedVehicles],
    optionTypeName: miscCard.fields.type,
    isSpecialEdition: miscCard.fields.isSpecialEdition,
    specialEditionPackage: forCreate ? undefined : miscCard.fields.specialEditionPackage,
    accessoryCodes: forCreate ? undefined : miscCard.fields.accessoryCodes,
    contestNumber: miscCard.fields.contestNumber,
    estimatedCost: isFlatCostOfferType(miscCard.fields.type) ? 0 : miscCard.fields.estimatedCost,
    tfsCost: miscCard.fields.tfsCost,
    enhTfsCost: miscCard.fields.enhTfsCost,
    offerCost: forCreate ? undefined : miscCard.fields.offerCost,
    offerEarnings: forCreate ? undefined : miscCard.fields.offerEarnings,
    offerTfsCost: forCreate ? undefined : miscCard.fields.offerTfsCost,
    offerEnhTfsCost: forCreate ? undefined : miscCard.fields.offerEnhTfsCost,
    systemToPay: miscCard.fields.systemToPay,
  };

  if (groupOne.includes(miscCard.fields.type)) {
    miscProps = {
      ...miscProps,
      vendorDescription: miscCard.fields.description,
    };

    hasRequiredFieldError = validator(miscCard.fields.description, { required: true }) !== undefined;
  } else if (miscCard.fields.type === AdminConstants.CASH_TYPES.FINAL_PAY || miscCard.fields.type === AdminConstants.CASH_TYPES.OTHER) {
    miscProps = {
      ...miscProps,
      tfsContracts: getTfsContractsWithTierTerms(miscCard.fields.tfsContracts, miscCard.fields.tfsContractRequired),
      vendorDescription: miscCard.fields.description,
    };

    hasRequiredFieldError = validator(miscCard.fields.description, { required: true }) !== undefined;
  } else if (groupTwo.includes(miscCard.fields.type)) {
    miscProps = {
      ...miscProps,
      paymentTo: miscCard.fields.paymentTo,
      paymentMethod: miscCard.fields.paymentMethod,
      tfsShare: Number(miscCard.fields.tfsCostShare),
      tfsCostShareCap: Number(miscCard.fields.tfsCostShareCap),
      typeSalesCodes: miscCard.fields.salesCode.toString(),
      tfsContracts: getTfsContractsWithTierTerms(miscCard.fields.tfsContracts, miscCard.fields.tfsContractRequired),
    };

    hasRequiredFieldError = validator(miscCard.fields.tfsCostShare, { required: true }) !== undefined;
  } else if (
    miscCard.fields.type === AdminConstants.CASH_TYPES.MILITARY ||
    miscCard.fields.type === (FEATURE_OR_5454_PHASE_2 ? AdminConstants.CASH_TYPES.COLLEGE : AdminConstants.CASH_TYPES.COLLEGE_GRADUATE)
  ) {
    miscProps = {
      ...miscProps,
      isTransferable: miscCard.fields.transferable,
      paymentMethod: miscCard.fields.paymentMethod,
      paymentTo: miscCard.fields.paymentTo,
      targetedAudience: miscCard.fields.targetedAudience,
      tfsContracts: getTfsContractsWithTierTerms(miscCard.fields.tfsContracts, miscCard.fields.tfsContractRequired),
      tfsCostShareCap: Number(miscCard.fields.tfsCostShareCap),
      tfsShare: Number(miscCard.fields.tfsCostShare),
      typeSalesCodes: miscCard.fields.salesCode.toString(),
    };

    hasRequiredFieldError = miscCard.hasTargetedAudienceError;
  } else if (miscCard.fields.type === AdminConstants.CASH_TYPES.CLOSING_CASH) {
    miscProps = {
      ...miscProps,
      paymentTo: miscCard.fields.paymentTo,
      paymentMethod: miscCard.fields.paymentMethod,
      tfsShare: Number(miscCard.fields.tfsCostShare),
      tfsCostShareCap: Number(miscCard.fields.tfsCostShareCap),
      typeSalesCodes: miscCard.fields.salesCode.toString(),
      maxCertificates: miscCard.fields.maxNoOfCertificates,
      tfsContracts: getTfsContractsWithTierTerms(miscCard.fields.tfsContracts, miscCard.fields.tfsContractRequired),
    };

    hasRequiredFieldError = validator(miscCard.fields.maxNoOfCertificates, { required: true, min: 1 }) !== undefined;
  } else if (miscCard.fields.type === AdminConstants.CASH_TYPES.DOWN_PAYMENT_ASSISTANCE) {
    miscProps = {
      ...miscProps,
      paymentTo: miscCard.fields.paymentTo,
      paymentMethod: miscCard.fields.paymentMethod,
      tfsShare: Number(miscCard.fields.tfsCostShare),
      tfsCostShareCap: Number(miscCard.fields.tfsCostShareCap),
      typeSalesCodes: miscCard.fields.salesCode.toString(),
      maxDownPayment: miscCard.fields.maxDownPaymentAmount,
      tfsContracts: getTfsContractsWithTierTerms(miscCard.fields.tfsContracts, miscCard.fields.tfsContractRequired),
    };

    hasRequiredFieldError = validator(miscCard.fields.maxDownPaymentAmount, { required: true, min: 1 }) !== undefined;
  } else if (miscCard.fields.type === AdminConstants.CASH_TYPES.TRADE_IN_ASSISTANCE) {
    miscProps = {
      ...miscProps,
      paymentTo: miscCard.fields.paymentTo,
      paymentMethod: miscCard.fields.paymentMethod,
      tfsShare: Number(miscCard.fields.tfsCostShare),
      tfsCostShareCap: Number(miscCard.fields.tfsCostShareCap),
      typeSalesCodes: miscCard.fields.salesCode.toString(),
      competitorDetails: miscCard.fields.modelRestrictions,
      tfsContracts: getTfsContractsWithTierTerms(miscCard.fields.tfsContracts, miscCard.fields.tfsContractRequired),
    };

    hasRequiredFieldError = validator(miscCard.fields.modelRestrictions, { required: true }) !== undefined;
  } else if (miscCard.fields.type === AdminConstants.CASH_TYPES.PAYMENT_WAIVER) {
    miscProps = {
      ...miscProps,
      paymentTo: miscCard.fields.paymentTo,
      paymentMethod: miscCard.fields.paymentMethod,
      tfsShare: Number(miscCard.fields.tfsCostShare),
      tfsCostShareCap: Number(miscCard.fields.tfsCostShareCap),
      typeSalesCodes: miscCard.fields.salesCode.toString(),
      noOfMonths: miscCard.fields.noOfMonths,
      maxAmountPerMonth: miscCard.fields.maxDollarAmountPerMonth,
      returningBrands: miscCard.fields.returningBrands,
      returningSeries: miscCard.fields.returningSeries,
      tfsContracts: getTfsContractsWithTierTerms(miscCard.fields.tfsContracts, miscCard.fields.tfsContractRequired),
    };

    hasRequiredFieldError =
      validateInputs([miscCard.fields.noOfMonths, miscCard.fields.maxDollarAmountPerMonth, miscCard.fields.returningSeries]) ||
      validator(miscCard.fields.tfsCostShare, { required: true }) !== undefined;
  } else if (miscCard.fields.type === AdminConstants.CASH_TYPES.COMPLIMENTARY_FIRST_PAYMENT) {
    miscProps = {
      ...miscProps,
      paymentTo: miscCard.fields.paymentTo,
      paymentMethod: miscCard.fields.paymentMethod,
      tfsShare: Number(miscCard.fields.tfsCostShare),
      tfsCostShareCap: Number(miscCard.fields.tfsCostShareCap),
      typeSalesCodes: miscCard.fields.salesCode.toString(),
      noOfMonths: miscCard.fields.noOfMonths,
      maxAmountPerMonth: miscCard.fields.maxDollarAmountPerMonth,
      tfsContracts: getTfsContractsWithTierTerms(miscCard.fields.tfsContracts, miscCard.fields.tfsContractRequired),
    };

    hasRequiredFieldError = validateInputs([miscCard.fields.noOfMonths, miscCard.fields.maxDollarAmountPerMonth]);
  } else if (groupThree.includes(miscCard.fields.type)) {
    miscProps = {
      ...miscProps,
      paymentTo: miscCard.fields.paymentTo,
      paymentMethod: miscCard.fields.paymentMethod,
      tfsShare: Number(miscCard.fields.tfsCostShare),
      tfsCostShareCap: Number(miscCard.fields.tfsCostShareCap),
      typeSalesCodes: miscCard.fields.salesCode.toString(),
      targetedAudience: miscCard.fields.targetedAudience,
      isTransferable: miscCard.fields.transferable,
      tfsContracts: getTfsContractsWithTierTerms(miscCard.fields.tfsContracts, miscCard.fields.tfsContractRequired),
    };

    hasRequiredFieldError = validator(miscCard.fields.tfsCostShare, { required: true }) !== undefined;
  } else if (miscCard.fields.type === AdminConstants.CASH_TYPES.CONQUEST) {
    miscProps = {
      ...miscProps,
      paymentTo: miscCard.fields.paymentTo,
      paymentMethod: miscCard.fields.paymentMethod,
      tfsShare: Number(miscCard.fields.tfsCostShare),
      tfsCostShareCap: Number(miscCard.fields.tfsCostShareCap),
      typeSalesCodes: miscCard.fields.salesCode.toString(),
      targetedAudience: miscCard.fields.targetedAudience,
      competitorDetails: miscCard.fields.competitorDetails,
      isTransferable: miscCard.fields.transferable,
      tfsContracts: getTfsContractsWithTierTerms(miscCard.fields.tfsContracts, miscCard.fields.tfsContractRequired),
    };

    hasRequiredFieldError = validator(miscCard.fields.competitorDetails, { required: true }) !== undefined;
  } else if (miscCard.fields.type === AdminConstants.CASH_TYPES.CAMPAIGN) {
    miscProps = {
      ...miscProps,
      paymentTo: miscCard.fields.paymentTo,
      paymentMethod: miscCard.fields.paymentMethod,
      tfsShare: Number(miscCard.fields.tfsCostShare),
      tfsCostShareCap: Number(miscCard.fields.tfsCostShareCap),
      typeSalesCodes: miscCard.fields.salesCode.toString(),
      targetedAudience: miscCard.fields.targetedAudience,
      impactedBrands: miscCard.fields.impactedBrand,
      impactedSeries: miscCard.fields.impactedModel,
      isTransferable: miscCard.fields.transferable,
      tfsContracts: getTfsContractsWithTierTerms(miscCard.fields.tfsContracts, miscCard.fields.tfsContractRequired),
    };

    hasRequiredFieldError = validateInputs([miscCard.fields.impactedBrand, miscCard.fields.impactedModel]);
  } else if (miscCard.fields.type === AdminConstants.CASH_TYPES.NON_CASH_CERTIFICATE) {
    miscProps = {
      ...miscProps,
      vendorDescription: miscCard.fields.description,
      giftDeliveryOption: miscCard.fields.giftDelivery,
    };

    hasRequiredFieldError = validateInputs([miscCard.fields.description, miscCard.fields.pnv, miscCard.fields.giftDelivery]);
  } else if (miscCard.fields.type === AdminConstants.CASH_TYPES.VENDOR_PURCHASE_PROGRAM) {
    miscProps = {
      ...miscProps,
      paymentTo: miscCard.fields.paymentTo,
      paymentMethod: miscCard.fields.paymentMethod,
      tfsShare: Number(miscCard.fields.tfsCostShare),
      tfsCostShareCap: Number(miscCard.fields.tfsCostShareCap),
      typeSalesCodes: miscCard.fields.salesCode.toString(),
      vendorCodes: miscCard.fields.vendorCodes,
      vendorDescription: miscCard.fields.description,
      tfsContracts: getTfsContractsWithTierTerms(miscCard.fields.tfsContracts || [], miscCard.fields.tfsContractRequired),
    };

    hasRequiredFieldError = validateInputs([miscCard.fields.vendorCodes, miscCard.fields.description]);
  } else if (miscCard.fields.type === AdminConstants.CASH_TYPES.DEFERRED_PAYMENT) {
    miscProps = {
      ...miscProps,
      maxDaysNo: Number(miscCard.fields.maxNoOfDays),
      tfsShare: Number(miscCard.fields.tfsCostShare),
      tfsCostShareCap: Number(miscCard.fields.tfsCostShareCap),
    };

    hasRequiredFieldError = validateInputs([miscCard.fields.combinedPerUnitCost, miscCard.fields.maxNoOfDays]);
  }

  return { miscProps, hasRequiredFieldError };
};
