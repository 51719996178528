import { Button, ButtonGroup, OATIcon } from 'oat-common-ui';
import useGetTfsLabel from '../../utils/useGetTfsLabel';
import styles from './styles.module.scss';

interface Props {
  enhTfsCostShareOfferId?: string;
  id: string;
  isEnhTfsCostShareUpdated?: boolean;
  onConfirm: () => void;
  onApply?: () => void;
  onCancel?: () => void;
}

const EnhTfsCostShareNotification = ({ enhTfsCostShareOfferId, id, isEnhTfsCostShareUpdated, onConfirm, onApply, onCancel }: Props) => {
  const tfsLabel = useGetTfsLabel();
  const isEnhTfsCostShareRemoved = isEnhTfsCostShareUpdated && !enhTfsCostShareOfferId;
  const enhTfsCostShareIsUpdated = isEnhTfsCostShareUpdated && enhTfsCostShareOfferId;

  if (enhTfsCostShareIsUpdated || isEnhTfsCostShareRemoved) {
    const message = enhTfsCostShareIsUpdated
      ? `Offer is eligible for Enhanced ${tfsLabel} Cost Share. Do you want to apply?`
      : `Enhanced ${tfsLabel} Cost Share has been removed. Please verify offers.`;

    const buttons = enhTfsCostShareIsUpdated ? (
      <ButtonGroup>
        <Button variant="primary" id={`${id}-enh-tfs-cost-notification-cancel`} onClick={() => onCancel && onCancel()}>
          Cancel
        </Button>
        <Button variant="primary" id={`${id}-enh-tfs-cost-notification-apply`} onClick={() => onApply && onApply()}>
          Apply
        </Button>
      </ButtonGroup>
    ) : (
      <Button variant="primary" id={`${id}-enh-tfs-cost-notification-ok`} onClick={() => onConfirm && onConfirm()}>
        OK
      </Button>
    );

    return (
      <div className={styles.enhCostShareNotification}>
        <div className={styles.message}>
          <OATIcon icon="warning" colorTheme="red" />
          <span>{message}</span>
        </div>

        {buttons}
      </div>
    );
  }

  return <></>;
};

export default EnhTfsCostShareNotification;
